import request from "api/request";

// 搜索查询商品文件
export function searchFile(data) {
  return request({
    url: "/product/product-file/search",
    method: "post",
    data,
  });
}

// 根据ID查询商品
export function searchID(params) {
  return request({
    url: "/product/one",
    method: "get",
    params,
  });
}

// 获取版权实务规格
export function FindProcess(data) {
  return request({
    url: "/project/findProcessAndArea",
    method: "post",
    data,
  });
}

// 获取版权实务承办人
export function FindPromoter(data) {
  return request({
    url: "/project/findPromoter",
    method: "post",
    data,
  });
}

// 获取评论列表
export function commentList(params) {
  return request({
    url: "/productevaluate/list",
    method: "get",
    params,
  });
}

// 增删收藏商品
export function addOrDel(data) {
  return request({
    url: "/productcollection/addOrDel",
    method: "post",
    data,
  });
}

// 新增版权实务订单
export function AddCopyrightPractice(data) {
  return request({
    url: "/product/order/addCopyrightPractice",
    method: "post",
    data,
  });
}

// 授权生成支付二维码
export function payOtherOrderPay(data) {
  return request({
    url: "/pay/orderPay",
    method: "post",
    data,
  });
}

// 调用商品微信支付的查询订单接口
export function PaywechatOrderQueryQrcode(data) {
  return request({
    url: "/pay/wechatOrderQueryQrcode",
    method: "post",
    data,
  });
}
