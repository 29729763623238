<template>
  <div class="location">
    <van-nav-bar
      class="van-bar"
      @click-right="onArrow"
      right-text="返回"
      title="软件定位"
    >
    </van-nav-bar>
    <div class="header-hds">
      <div class="header">
        <img src="../../assets/images/home/16.png" alt="" />
      </div>
      <div class="banner">
        <div>
          <div><img src="../../assets/images/home/17.png" alt="" /></div>
          <h3>
            <div class="box1"></div>
            <div>原创保护</div>
            <div class="box2"></div>
          </h3>
          <p>
            致力于为原创文字作品、图片、视频、文件等提供电子数据指纹，适配国家授时中心时间戳，形成周密稳固的知产权证据链，以更好保护原创作者。
          </p>
        </div>
        <div>
          <div><img src="../../assets/images/home/18.png" alt="" /></div>
          <h3>
            <div class="box1"></div>
            <div>版权收益</div>
            <div class="box2"></div>
          </h3>
          <p>
            原创作者可以通过作品授权，自主设定授权价格，获得现金等收益。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。
          </p>
        </div>
        <div>
          <div><img src="../../assets/images/home/19.png" alt="" /></div>
          <h3>
            <div class="box1"></div>
            <div>版权维护</div>
            <div class="box2"></div>
          </h3>
          <p>
            提供智能网盘、版权收益、版权服务等一站式知识产权服务，由执业律师提供标准化的流程公开、价格透明的咨询与实务服务。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onArrow() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.location {
  width: 100%;
  .header-hds {
    max-height: calc(100vh - 123px);
    overflow-y: auto;
  }
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  .header {
    width: 100%;
    height: 467px;
    margin-top: 29px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.banner {
  width: 100%;
  padding: 0 30px 40px;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 92px;
    height: 92px;
    margin-top: 95px;
  }
  h3 {
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    margin-top: 18px;
    .box1 {
      width: 142px;
      height: 2px;
      background: #4587ff;
      border-radius: 1px;
      margin: 13px 0 53px;
      margin-right: 24px;
    }
    .box2 {
      width: 142px;
      height: 2px;
      background: #4587ff;
      border-radius: 1px;
      margin-top: 13px;
      margin-left: 24px;
    }
  }
  p {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 50px;
    text-align: left;
  }
}
::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text {
  color: #333333;
  font-size: 28px;
}
::v-deep .van-swipe__indicators .van-swipe__indicator {
  width: 38px;
  height: 7px;
  border-radius: 3.5px;
}
</style>
