<template>
  <div class="hoome">
    <div class="my">你好</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.hoome {
  width: 100%;
  height: 100vh;
}
.my {
  width: 100 / 1920 * 375vw;
  height: 100 / 1080 * 300vh;
  background: red;
}
</style>
