<template>
  <div class="location">
    <van-nav-bar
      class="van-bar"
      @click-right="onArrow"
      right-text="返回"
      title="隐私政策"
    >
    </van-nav-bar>
    <div class="header-hds">
      <div>
        <p class="Header"></p>
      </div>
      <div
        style="
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
          margin-left: 1rem;
          margin-right: 1rem;
        "
        class="Section0"
      >
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >感谢您选择使用由</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >深圳写手智能科技有限公司</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（以下简称“我们”或“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >”）及</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或其关联公司开发、运营的授权软件（即“</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >”）及通过</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务账号使用的在线服务（即“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”，为表述方便，以下将“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件”、“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写作</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”统称为“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”）。</span
          >
        </p>

        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们设立了专门的个人信息保护团队和负责人，如果您对本政策或个人信息保护有任何疑问、意见或建议，可通过以下方式与我们联系，我们会在十五个工作日内受理并处理：</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >l</span
          ><span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.525rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >&nbsp;&nbsp;</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >联系地址：深圳市宝安区新安三路嘉盛智丰荟1栋212</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >l</span
          ><span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.525rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >&nbsp;&nbsp;</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >邮编：</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >518101</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >l</span
          ><span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.525rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >&nbsp;&nbsp;</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电子邮件：</span
          ><a href="mailto:416179686@qq.com" style="text-decoration: none"
            ><span
              class="X1 X6"
              style="
                font-family: 微软雅黑;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >416179686@qq.com</span
            ></a
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >l</span
          ><span
            class="X1 X6"
            style="
              font-family: Wingdings;
              font-size: 0.525rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >&nbsp;&nbsp;</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电话：</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >0755-23345494 13828584514</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >特别提示：</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在您使用“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”前，请您务必仔细阅读本政策，</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >办公将根据中华人民共和国（仅为本政策之目的，指中华人民共和国范围内香港、澳门特别行政区及台湾地区以外的其他地区，以下称为“中国大陆”）相关法律法规保护您使用“</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”的任何部分或全部的过程中</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >办公收集的个人信息。</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >本政策中与您的权益（可能）密切相关的重要条款，已采用加粗字体来特别提醒您，请您重点查阅。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <br />
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >本政策将帮助您了解以下内容：</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >一、本政策的适用范围</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >二、定义</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >三、我们如何收集和使用您的个人信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >四、我们如何使用</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >和同类技术</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >五、我们如何共享、转让、公开披露您的个人信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >六、我们如何保护和保存您的个人信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >七、您的权利</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >八、未成年人个人信息的保护</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >九、本政策如何更新</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >十、本次更新</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >附件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >：个人信息收集清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >附件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >：个人信息共享清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >附件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >：应用权限清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <br />
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >一、本政策的适用范围</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、适用本政策的、由</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或其关联公司开发、运营的</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >WPS</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件包括但不限于：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端（包括运行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Linux</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端）；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)以插件形式（包括但不限于软件开发工具包</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >SDK</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >API</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >接口等）接入</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端）、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >文档（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >客户端）中的诸如</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的相关插件 </span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >等插件；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >微信小程序、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >审核</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >微信</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/QQ</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >小程序、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >微信小程序、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >阅读</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >微信小程序、文件助手小程序等小程序。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、适用本政策的</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >办公在线服务包括但不限于通过</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >办公在线服务账号使用的如下在线服务：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >官方网站（网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.antiplagiarize.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）所提供的在线服务；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员服务，包括网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.antiplagiarize.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的网站提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员服务（包括面向注册用户、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员的全部或任何部分服务）、以内嵌页方式接入</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端）的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员服务等；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >平台服务，包括网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的网站提供的稻壳服务、以内嵌页方式接入</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端）的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >模板</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >+</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >微信小程序、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员微信小程序等；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >4)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >反抄袭写作服务</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >，包括网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >的网站提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >推荐</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务、前述网址所对应的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >个人版）客户端）、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（包括运行</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >客户端、网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >网站）的内嵌页所提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务（为表述方便，前述“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >授权软件”中提及的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >插件、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >微信</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >/QQ</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >小程序与</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务统称为“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >”）；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >5)</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >审核</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务，包括网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >的网站提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >智能核校</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >溯源服务</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >查重服务</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >前述网址所对应的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）客户端）、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（包括运行</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >客户端、网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >网站）的内嵌页所提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >智能核校</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >溯源服务</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >查重服务</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（为表述方便，前述“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >授权软件”中提及的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >智能核校、溯源、查重</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >插件、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >审核</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >微信小程序与</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >审核</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务统称为“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >审核</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >”）；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >6)</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务，包括网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >的网站提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务、前述网址所对应的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）客户端）、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（包括运行</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >客户端、网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >网站）的内嵌页所提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >查重</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务（为表述方便，前述“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >授权软件”中提及的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >插件、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >微信小程序与</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务统称为“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >”）；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >7)反抄袭阅读服务，包括网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >的网站提供的反抄袭信息库服务、前述网址所对应的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）（仅限于行在</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="font-family: Arial; font-size: 0.75rem; color: rgb(0, 0, 0)"
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >（个人版）客户端）、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >信息库（包括运行</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Windows</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >系统中的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >客户端、网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >网站）的内嵌页所提供的信息库服务（为表述方便，前述“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >授权软件”中提及的反抄袭信息库插件、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >信息库微信小程序与</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >信息库服务统称为“</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >阅读”）</span
          >
        </p>
        <p style="white-space: pre-wrap" class="Normal--Web-">
          <span
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >8）</span
          ><span
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >存储服务，包括</span
          ><span
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >客户端提供的智能网盘、数据采集智能存储服务。（后续需要拓展）</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >9）</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >网址为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >https://www.</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >.com/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >网站提供的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >+</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >服务、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >邮箱、公众号文章转存</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              background-color: rgb(255, 255, 255);
            "
            >云文档等其他服务。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、如您通过登录您的</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号在</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >WEB</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端首次使用如下由</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >办公及</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或其关联公司开发</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >运营在线服务的，您在同意本政策的同时，还需同意如下在线服务所对应的隐私政策，方可使用所对应的在线服务：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写作</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务：</span
          ><a
            href="https://www.wps.cn/privacy/kdocs"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: Arial;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《</span
            ></a
          ><a
            href="https://www.wps.cn/privacy/kdocs"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: 微软雅黑;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >反抄袭</span
            ></a
          ><a
            href="https://www.wps.cn/privacy/kdocs"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: Arial;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >写作隐私政策》</span
            ></a
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >审核</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务：</span
          ><a
            href="https://www.wps.cn/privacy/Conference"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: Arial;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《</span
            ></a
          ><a
            href="https://www.wps.cn/privacy/Conference"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: 微软雅黑;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >反抄袭</span
            ></a
          ><a
            href="https://www.wps.cn/privacy/Conference"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: Arial;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >审核隐私政策》</span
            ></a
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭合规</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务：</span
          ><a
            href="https://www.wps.cn/privacy/wpsedu_privacyprotect"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: Arial;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《反抄袭合规隐私政策》</span
            ></a
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭阅读</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务：</span
          ><a
            href="https://www.wps.cn/privacy/wpsnote"
            style="text-decoration: none"
            ><span
              class="X1 X4"
              style="
                font-family: Arial;
                font-size: 0.75rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《反抄袭阅读隐私政策》</span
            ></a
          >
        </p>
        <p style="white-space: pre-wrap" class="Normal--Web-">
          <span
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5）</span
          ><span
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭存储</span
          ><span
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务：</span
          ><span
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >《反抄袭存储隐私政策》</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、请您注意，本政策不适用于第三方提供的产品和服务。“</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，强烈建议您花时间阅读该第三方的隐私政策，就像阅读本政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。本政策不适用通过“</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”链接的其他网站。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >二、定义</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、关联公司：指在控制</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >深圳写手智能科技有限公司</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、受</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >深圳写手智能科技有限公司</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >控制或与</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >深圳写手智能科技有限公司</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >处于共同控制下的公司、机构。控制指通过所有权、有投票权的股份、合同、实际运营关联或其他被依法认定的方式直接或间接地拥有影响被控制对象管理</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >经营的能力。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、个人信息：指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，我们将在本政策中对具体个人敏感信息以</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >粗体</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >进行显著标识。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5、个人信息匿名化：通过对个人信息的技术处理，使得您无法被识别，且处理后的信息不能被复原的过程。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >6、设备信息：通常包括设备名称、设备型号、硬件序列号、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >MAC</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >地址、设备标识信息（</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >IMEI/MEID/AndroidID/OpenUDID/IMSI/IDFA/OAID</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及其他综合设备参数形成的设备标识符）、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ID</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >PLMN</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >IP</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >地址。以产品实际采集情况为准。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >7、网络位置信息：通过网络位置信息（例如基站、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >IP</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >WLAN</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）获取的大致地理位置信息，仅收集至国家</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >省</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >市。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >8、</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >精确地理位置信息：通过</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >GPS</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >信息、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >WLAN</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >接入点、蓝牙和基站等传感器信息获取到的精确地理位置信息，为收集上述信息，我们需要申请您授权我们使用设备的位置权限。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >9、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端：指运行在</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >HarmonyOS</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >操作系统中的</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >10、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端：指运行在</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Windows</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Linux</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >操作系统中的</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人版客户端。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >三、我们如何收集和使用您的个人信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为了向您提供服务，</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件可能需要在必要范围内向您申请获取下列权限。请您放心，</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件不会默认开启这些权限，只有经过您单独同意后，才会在为实现特定服务</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >功能时访问。即使经过您的授权，可以访问您的相应权限，我们仅会在您的授权范围内进行访问，并获取相应信息（如涉及）；且您可随时撤回授权，在您撤回授权后，我们将停止访问相应权限，也将无法再为您提供与之对应的某些服务，但不影响您享受我们提供的其他服务。查看</span
          ><a
            href="https://www.wps.cn/privacy/privacyprotect_permissions"
            style="text-decoration: none"
            ><span
              class="X1 X6"
              style="
                font-family: 微软雅黑;
                font-size: 0.675rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《应用权限清单》</span
            ></a
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们出于本政策所述的以下目的，收集和使用您的个人信息：</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、帮助您成为我们的用户及账户管理</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)注册成为我们的用户</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为帮助您成为我们的用户，即完成账号的创建，以便我们为您提供注册我们用户服务，我们为您提供了多种注册渠道供您自行选择。当您选择使用手机号码或电子邮箱注册时，您需要向我们提供您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >手机号码、电子邮箱地址及密码</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，我们使用这类信息发送验证码信息以供您提交验证身份是否有效。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >基于我们与通信运营商的合作，当您使用一键登录功能时，经过您的同意，运营商会将您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >手机号码</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >发送给我们，便于我们为您提供快捷的登录服务。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >当您选择使用第三方账号（如微信、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >QQ</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、钉钉、微博等）更便捷地注册并登录金山文档时，我们将通过间接获得的方式访问、收集您明示授权后的个人信息（</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >昵称、头像、性别、地区（具体以您授权的内容为准）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）并将您的第三方账户与您的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >WPS</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号绑定。</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >若您拒绝提供第三方账号下的信息，则您无法选择使用第三方账号的方式注册并登录。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您知悉，我们收集</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >手机号码、电子邮箱地址</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >是为了满足相关法律法规的网络实名制要求，若您不提供这类信息，将可能无法正常使用我们的注册用户服务。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)帮助您找回您的账号</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >当您的账号无法登录或忘记密码、无法接收短信验证码时，您可以通过账号申诉进行找回账号，为确保您所申请找回的账号是归您所有，您需提供</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >真实姓名、身份证号、手机号码及手持身份证照片</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，我们将通过第三方身份验证机构对您的身份信息进行验证，同时亦通过</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭智能科技</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及或关联公司的客服人员对手持身份证照片进行核对。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、向您提供在线服务</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们目前已经及后续推出的文档协作、分享、实时保存、同步等云文档服务、智能美化等</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >AI</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭写作、反抄袭推荐、反抄袭审核、反抄袭合规、反抄袭阅读、反抄袭存储以及反抄袭会员服务</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >平台服务中的诸如文档</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >核校</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >查重</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务、全文翻译服务、文件转换服务（包括但不限于</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >PDF</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >转换、图片转换</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >OCR转换</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭写作服务、反抄袭审核、反抄袭合规、反抄袭阅读</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >等需在服务端交互进行的功能服务均以您在联网状态下、登录账号后将相关文件、图片、照片、语音、发票、填写</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >记录的内容等上传（包括手动上传和自动漫游）至服务器为前提，我们收集您上传至服务器端的内容及文件信息（包括文件类型、大小、页数、修改时间、文档权限、存放位置，下同）是为您提供前述金山办公在线服务所必需的。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)在线</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写作</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭写作</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >目前所提供的在线预览、编辑、多人在线协同编辑、文档分享、文档实时保存至云端等需要在云端实现的功能及后续推出依托于云的服务均以您在联网状态下、登录账号后将相关文档材料上传（包括手动上传和自动漫游）至云端为前提的功能</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务，</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >收集您上传至云端的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >文档及文档信息（包括文档类型、大小、文档权限、存放位置，下同）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >是为您提供相关云服务所必需的。您所上传的相关文档材料在脱敏后会作为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >AI</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >训练的基础材料读取、使用，但请您放心，我们会采取安全措施和技术手段保护您的个人信息，并将通过技术手段对该等资料进行去标识化处理，以降低其他组织或个人通过去标识化处理后的个人信息识别到您的风险。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在进行在线文档协作时，为使参与协作编辑的各方知晓被编辑、改动的信息，</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会对展示您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >头像、昵称和修改痕迹</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，并供各方查看文档的历史版本。此外，您可以根据需求设置文档权限。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭审核</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以使用</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >中</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭审核</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >提供的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >智能核校、查重功能</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，为使用</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >智能核校、查重功能</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您需要授权我们获取设备的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >阅读</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >权限</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，如果您拒绝授权的话将无法使用本功能。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭合规</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以使用反抄袭中合规功能修改自己的文章格式，接受或修改，订阅第三方提供的文档。您可以对自己的文章作品进行权限设置。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭阅读</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭阅读（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）为您提供选中、编辑、插入和搜索文章功能。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >a.选中、编辑、插入文章</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以使用反抄袭中信息库功能创建、编辑、发布文章时，我们会审查文章内容是否涉嫌侵害国家安全与利益、社会公众利益或涉及其他违法违规活动。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >b.搜索文章</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >文章内容由文章搜索发起方自行编辑，文章搜索发起方有权决定文章的形式、内容以及是否公开及公开的范围，文章搜索发起方对其创建的文章题目及相关分析结果享有使用权和所有权，反抄袭仅作为技术提供方，在未经您许可的情况下不会以任何方式自行使用您提交的任何信息。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5)复制与分享</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在您分享、复制信息时，我们需要访问您的剪切板，读取其中包含的链接及其他内容信息，以实现跳转、复制、分享等功能或服务。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >6)公众号文章转存云文档</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >当您使用公众号文章转存云文档服务时，我们将会收集您的账号信息及微信公众号文章链接，用以识别您的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >用户身份，并将转存成功的云文档保存至您的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号中。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、订单与支付</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)下单与订单管理</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >当您在购买</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的商品</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务时，我们需要根据商品</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务类型收集如下部分或全部的个人信息，包括您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >交易商品</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务信息、订单编号、下单时间、订单金额、您的备注信息、发票的相关发票信息（发票类型、发票抬头、税号、注册地址、注册电话、开户银行、银行账号、发票内容、发票金额、发票备注、发票接受电话或电子邮箱地址）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，我们收集这些信息是为了帮助您顺利完成商品</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务购买、接收、查询订单信息、提供客户服务等。同时，为便于您了解、查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)支付</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为完成订单支付、保障交易安全，我们需要收集您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >交易金额、下单时间、订单商户、订单编号、支付方式、支付账号、支付状态信息</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，并通过安全方式与支付机构（如微信、支付宝等金融机构）进行前述部分或全部信息的共享。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、改善我们的产品或服务</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们会通过您的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件信息（包括版本号、语言类型、渠道号、渠道类型）、设备信息（包括</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >唯一设备识别码（</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >UUID</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >GUID</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、品牌、型号、系统类型、系统版本、分辨率、</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设备地址</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >MD5</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >值（基于网络设备硬件地址</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >MAC</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的加密、不可逆的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >MD5</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >值）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）、设备连接信息（包括浏览器的类型、电信运营商、网络类型）、账号信息（</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ID</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、账号类型、会员</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ID</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）、</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >网络位置信息（基于</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >IP</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >地址，仅收集至国家</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >省</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >市，下同）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的操作行为数据、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务使用情况（包括文档的上传频率、使用时长）进行数据分析，了解</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的使用情况、适配情况，为新产品、新服务的研发、已有功能、服务的完善提供数据支撑。对于您在</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务中的个人信息，我们可能会将它们与您使用</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及其关联公司开发、运营的其他产品</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务收集的个人信息进行关联，以便能为您提供一致的服务。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5、为您提供安全保障</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为提高您使用我们服务的安全性，保护您、其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或我们相关协议规则的情况，</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们可能使用或整合您的账号信息</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（邮箱地址、手机号、绑定登录、使用的第三账号、账号</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ID</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、设备信息、网络日志，以及您安装的应用信息、正在运行的进程信息、使用应用程序的频率、崩溃数据以及应用程序的来源信息，来综合判断您账户风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >如我们会综合分析用户账号活动异常、多端登录、流量异常等，以保护您的账号安全。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >6、客服与售后</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >如您在使用</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务寻求服务支持时，我们会根据您的账号信息核对您的身份及购买的相关产品或服务（如有），并根据您的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务应用信息、</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设备信息</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、设备连接信息、</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >位置信息</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、日志信息、与服务器通讯信息以及您发送的邮件内容、截图、文档或与金山办公客服人员的电话对话内容帮您查找原因、解决问题。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们还会收集您通过拨打客服热线、发送电子邮件、论坛发帖、公众号留言等各种方式提交咨询时所提供的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >联系人姓名、公司名称、联系电话、电子邮箱地址</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及您的咨询内容。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >7、其他用途</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)为了向您推荐更优质、您可能更感兴趣的产品功能、服务内容、让您有更好的产品与</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或服务体验或您同意的其他用途，</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >可能会将您的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件信息、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设备信息</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、设备连接信息、账号信息、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >位置信息</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的行为数据、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务使用情况、文件信息以综合统计、分析的方式单独或与来自</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或其关联公司开发、运营的其他产品</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务的某些信息结合进行推荐算法建模、用户行为分析及用户画像，为</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或其关联公司开发、运营的、其他（不含</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（个人版）</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >for Mac/Linux</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）含有个性化服务</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >定向推送的产品</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务所用。请您放心，前述个性化推荐</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >定向推送仅是依据信息系统、算法等在内的非人工自动决策机制做出，并不涉及任何人工读取信息、人工干预的情形。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)消息推送、软文与参与资格的判断：</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会不定期地推出</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >抽奖、赠送、福利领取、比赛、促销、使用积分兑换商品等活动</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，亦或不定期的推出有关新的产品、服务、功能，为了精准推送活动信息、相关软文或相关产品、服务，减少对非活动对象的打扰，</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会使用您的</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件信息、</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设备信息</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、账号信息、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的行为数据、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务使用情况，判断您是否为相关对象进而决定是否向您推送、展示活动信息、相关软文，并使用前述收集的信息作为认定您具备参与活动资格的判断依据。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)如您从</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的经销商、代理商渠道购买</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务，则</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >将从该等第三方渠道获取您的订单信息，包括您的姓名</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >昵称、</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号信息（</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >手机号、邮箱地址</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）所购买的“</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”的具体内容信息。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >还可能使用您的个人信息（</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >手机号、邮箱地址</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >），通过电子邮件、手机短信或其他方式向您发送您可能感兴趣的广告。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5)如您参与了</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或关联公司的抽奖、赠送、福利领取、比赛、促销、使用积分兑换商品等活动，</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >需要向您发放相关奖品、礼品、商品时，您需要提供您或您指定的收件人的</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息（姓名、电话号码（包括手机号）、联系地址）</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >6)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会通过技术手段对收集的您的个人信息进行去标识化处理，去标识化处理的信息将无法识别主体，您知悉并同意，</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >有权使用已经去标识化的信息。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >7)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会使用您对</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的操作信息对</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的用户整体使用情况进行总体性及匿名的数据统计及分析。同时，</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >可能会与公众共享这些统计信息，以展示“</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务”的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >8)当</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >展示您的个人信息时，会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >8、征得授权同意的例外</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您充分知悉，以下情形中，我们处理您的个人信息无需征得您的授权同意：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)与履行法律法规规定的义务相关的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)与国家安全、国防安全直接相关的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)与公共安全、公共卫生、重大公共利益直接有关的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4)与刑事侦查、起诉、审判和判决执行等直接相关的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >6)所涉及的个人信息是您自行向社会公众公开的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >7)根据您的要求签订和履行合同所必需的；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >8)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >9)维护所提供的产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >10)法律、行政法规规定的其他情形。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >四、我们如何使用</span
          ><span
            class="X1 X2"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >和同类技术</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为确保</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >授权软件</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务的正常运转，我们会使用相关技术向您的设备存储名为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的小数据文件。</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >通常包含标识符、站点名称以及一些号码和字符。我们可能会设置认证与保障安全性的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，以确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。使用</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >还可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程。我们不会将</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >用于本政策所述目的之外的任何用途。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Do Not Track</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（请勿追踪）</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可根据自己的偏好管理或删除</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >。您可以清除您的设备上保存的所有</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Cookie</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，很多网络浏览器均设有</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Do Not Track</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >功能，该功能可向网站发布</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Do Not Track</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Do Not Track</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >，那么我们的所有网站都会尊重您的选择。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >五、我们如何共享、转让、公开披露您的个人信息</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、共享</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们在遵守“合法正当、最小必要、目的明确原则”的前提下共享您的个人信息。同时，我们将对个人信息的共享活动事先进行个人信息保护影响评估，对输出形式、流转、使用等采取有效的技术保护措施。在合作协议层面，严格要求合作伙伴的信息保护义务与责任，与业务合作伙伴在合作前签署关于数据安全的保护协议。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们制定了</span
          ><a
            href="https://www.wps.cn/privacy/privacyprotect_sdk"
            style="text-decoration: none"
            ><span
              class="X1 X6"
              style="
                font-family: 微软雅黑;
                font-size: 0.675rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《个人信息共享清单》</span
            ></a
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >向您清晰、简洁地介绍我们主要的有关您个人信息共享及接入的软件工具开发包（</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >SDK</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）的情况。查看</span
          ><a
            href="https://www.wps.cn/privacy/privacyprotect_sdk"
            style="text-decoration: none"
            ><span
              class="X1 X6"
              style="
                font-family: 微软雅黑;
                font-size: 0.675rem;
                color: rgb(0, 0, 0);
                white-space: pre-wrap;
              "
              >《个人信息共享清单》</span
            ></a
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、转让</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们不会将您的个人信息转让给关联公司外的任何公司、组织和个人，但以下情形除外：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)事先获得您的明确授权或同意；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、公开披露</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们仅会在以下情形下，公开披露您的个人信息：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)获得您的明确同意；</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)在公布相关中奖活动名单时会脱敏展示您作为中奖者的手机号或账户登录名。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、共享、转让、披露个人信息时事先征得授权同意的例外。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >六、我们如何保护和保存您的个人信息</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、技术保护：</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们会严格遵守中国大陆关于处理个人信息的相关法律法规，努力使用符合业界标准的安全防护措施保护您的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们采取的安全防护措施如下，但也请您理解，任何安全措施都无法做到无懈可击：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)当您向我们的服务器发送或收取信息时，我们确保使用传输层安全协议（</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >TLS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）和其他适当的算法对其进行加密。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)您的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对您的信息进行分类，并且保证您的个人信息具有相应的安全等级。同样，我们对以云为基础的数据存储设有专门的访问控制措施，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)我们将对第三方合作伙伴进行尽职调查以确保他们有能力保护您的个人信息。我们还将通过实施适当的合同限制，并在必要时进行审计及评估，来检查第三方是否采用了适当的安全标准。此外，访问您的个人信息的员工、第三方合作伙伴都遵守严格的合同保密义务。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4)我们会开展信息安全和隐私保护的培训和考试，以加强员工对于保护个人信息重要性的认识。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们会不时对上述措施和管理方式加以修订完善以提升整体的安全性。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、安全体系保证</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们围绕数据生命周期建立数据安全管理体系，从组织建设、制度设计、人员管理、产品技术、个人信息安全影响评估等方面多维度提升整个系统的安全性。目前，我们已经通过了信息安全等级保护三级认证、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ISO/IEC 27001:2013</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >信息安全国际标准认证、</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ISO/IEC 27701:2019</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息保护国际标准认证。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、信息安全事件处理</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >当我们获悉发生了个人信息泄露、非法提供或滥用的安全事件后，我们将按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况将以推送通知的方式告知您，难以逐一告知您时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、我们如何保存您的个人信息：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)存储地点</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们依照法律法规的规定，将在中国大陆运营过程中收集和产生的您的个人信息存储于中国大陆。目前，我们不会将您的个人信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)存储期限</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们会采取合理可行的措施避免收集无关的个人信息。我们只会在达成本政策所述目的所需的最短期限内保留您的个人信息，除非您同意延长保留期或受到法律的允许。超出保存期限后，我们将删除您的个人信息或采取技术措施进行匿名化处理，但法律法规另有规定的除外。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >七、您的权利</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们非常重视您对个人信息的管理，并尽全力保障您对于个人信息的查阅、复制、更正、补充、删除、撤回同意授权等权利的行使。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、改变或撤回授权范围</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以通过以下方式改变或者撤回您授权我们处理您的个人信息的范围：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)系统权限设置</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以在设备本身的操作系统设置中关闭相关权限，改变同意范围或撤回您的授权。撤回授权后我们将不再收集与该权限相关信息。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以在</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端中通过【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设置</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >系统权限管理】中管理我们产品使用的系统权限授权（包括相机、相册</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >存储、麦克风等）。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)内容推荐管理</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以自主选择您喜欢的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写作</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >模板、素材、文库等。例如：在</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设置</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >智能推荐】中设置是否允许我们根据历史记录推荐</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写作</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >内容及调整您的个性化标签等。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >请您理解，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息，但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、您的个人信息权利</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)查询、更正您的个人信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以通过网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）【个人信息查询】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号与安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息查询】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息查询】查询您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号信息（如头像、用户</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >ID</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、手机号码、绑定的第三方账号、登录设备信息）</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以通过网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >帐号与安全】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号安全】设置您的</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号信息（头像、手机号、电子邮箱）及密码、第三方账号</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为了帮助您实现账号安全管理，您也可以进入网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）【当前在线设备】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >帐号与安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >登录设备管理】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线设备】或【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >登录设备管理】管理您的登录设备。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)查询、更正您的其他信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以进入网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【文档</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >云文档】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【文档】查看、修改、删除和分享您的文档。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以进入网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >钱包</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >订单中心】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >钱包</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >订单</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >发票】查看您的订单或订单管理。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以进入网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >会员】查看您的会员情况、会员权益及会员到期日期或购买会员。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、注销您的账号</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以通过网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）【永久删除账号】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >帐号与安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >永久删除账号】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >帐号安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >永久删除账号】，根据提示在线申请注销您的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务账号。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您退出所在企业或您所在企业被解散，我们将会删除或匿名化处理您在该企业的相关个人信息，但您作为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务账号用户的个人信息将会保留。在您主动注销个人账号后，我们将停止为您提供服务并根据适用法律的要求删除您的个人信息或做匿名化处理。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在您的账号被注销前，我们将验证您的个人身份、安全状态、设备信息等信息，并在确认您的账号无任何公开和私密文档、已删除自主创建的团队、已不是所在企业的超级管理员后帮助您完成注销。您知悉并理解，注销账号的行为是不可逆的行为，在您的账号被注销后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定或客户要求另行处理的个人信息除外。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、投诉举报及响应</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >如果您有任何意见或您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过本政策公布的联系方式与我们联系。我们会在十五个工作日内受理并处理。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5、访问隐私政策</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >您可以在网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >设置</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >隐私设置及法律条款</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >隐私协议】查看本政策的全部内容。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >请您了解，本政策中所述的</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的相关功能可能会根据您所使用的设备型号、系统版本、软件应用程序版本等因素而有所不同。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >八</span
          ><span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、本政策如何更新</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、为给您提供更好的服务以及随着我们业务的发展，本政策之规定可能会不定期地修改，但未经您明确同意，我们不会削减您依据本政策所应享有的权利。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、本政策更新后，我们会在您登录及版本更新时以推送通知、弹窗的形式向您展示变更后的本政策，以便您及时了解本政策的最新版本。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >九</span
          ><span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、本次更新</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >本次更新的内容主要包括：</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、简化文档内容，让文档更适于阅读和理解；</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、在第三章节“我们如何收集和使用您的个人信息”部分，增加《个人信息收集清单》，并在本政策正文更清晰和详细地披露了我们的在线服务；</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、在第五章节“我们如何共享、转让、公开披露您的个人信息”部分，在原有“第三方服务和网站”的基础上，增加有关个人信息共享的情况说明，形成《个人信息共享清单》；</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、在第六章节“我们如何保护和保存您的个人信息”部分，增加技术保护、安全体系认证、信息安全事件处理；</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5、在第七章节“您的权利”部分，补充了部分功能描述，增加了个人信息副本查询入口。</span
          >
        </p>
        <p style="text-align: left; white-space: pre-wrap" class="Normal--Web-">
          <br />
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <br />
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >附件</span
          ><span
            class="X1 X2"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1</span
          ><span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >：个人信息收集清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为了帮助您更好地了解我们在主要业务场景下，对您的个人信息收集、使用情况，我们特整理了《个人信息收集清单》。</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >我们会收集您主动提供的、通过自动化采集的您在使用</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >反抄袭</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >过程中产生的下述信息。您可通过网页端（网址为</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >https://www.</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >.com/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）【个人信息查询】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >移动客户端【我</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号与安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息查询】或</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >antiplagiarize</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >电脑客户端【个人中心</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >账号安全</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >-</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息查询】或通过本政策公布的联系方式与联系我们对下列部分信息的具体内容进行查询和管理。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、账号注册、登录与管理</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用场景</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息范围</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >账号注册、登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、电子邮箱地址及密码</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="3"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于注册创建账号和登录账号</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方授权登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方平台昵称、头像、性别、地区（具体以您授权的内容为准）</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、设备标识信息（IMSI、IMEI、ICCID、IDFV）、网络信息（IP地址）、设备参数及系统信息（设备类型、设备型号、设备品牌、操作系统）、应用特征信息（应用程序版本、应用程序名称）</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >资料维护</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >头像、昵称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于完善网络资料</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >账号找回</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >真实姓名、身份证号、手机号码及手持身份证照片</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于找回您的账号</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >实名认证</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行实名认证</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、软件</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >在线服务</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用场景</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息范围</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >在线服务</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >文档、文档信息（文档类型、大小、文档权限、存放位置）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于在线文档完成协作</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >头像、昵称和修改痕迹</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭推荐</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >自动推荐、主动推荐</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于推荐写作内容素材</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭审核</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >智能核校、溯源、查重</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于核校文章是否出现错别字、病句、敏感词汇等；溯源文本内容靠前及权威作者；迅速查找文章内容是否重复并给出重复率</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭合规</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >段落、全文格式调整</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于迅速调整段落或全文格式</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭阅读</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >信息库数据查阅</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于查阅写作需要涉及的文本数据</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 9.98%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭存储</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >数据存储与数据采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于数据存储与采集数据的存放</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息（如姓名、身份证号码、手机号码、地址、微信号、QQ号、银行账号、支付宝账号、地理位置等）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于填写个人账户信息与订阅服务</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、订单与支付</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用场景</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息范围</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >下单</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >交易商品/服务信息、订单编号、下单时间、订单金额、您的备注信息、发票的相关发票信息（发票类型、发票抬头、税号、注册地址、注册电话、开户银行、银行账号、发票内容、发票金额、发票备注、发票接受电话或电子邮箱地址）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于完成下单与订单管理</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >交易金额、下单时间、订单商户、订单编号、支付方式、支付账号、支付状态信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于完成交易支付</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、运营与安全保障</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用场景</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息范围</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >运营</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >应用信息（包括版本号、语言类型、渠道号、渠道类型，下同）、设备信息（包括唯一设备识别码（UUID、GUID，下同）、品牌、型号、系统类型、系统版本、分辨率、设备地址MD5值（基于网络设备硬件地址MAC的加密、不可逆的MD5值），下同）、设备连接信息（包括浏览器的类型、电信运营商、网络类型，下同）、账号信息（账号ID、账号类型、会员ID）、位置信息（基于IP地址，仅收集至国家/省/市，下同）、操作行为数据、金山文档使用情况（包括文档的上传频率、使用时长）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于改善产品或服务</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用过程中</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >账号信息（邮箱地址、手机号、绑定登录、使用的第三账号、账号ID）、设备信息、网络日志，以及您安装的应用信息、正在运行的进程信息、使用应用程序的频率、崩溃数据以及应用程序的来源信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于安全保障</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >消息推送</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（IMEI号、Serial Number、IMSI、User ID、Android
                    ID、手机Region设置、设备型号、手机电量、手机操作系统版本及语言）、应用信息、网络信息、日志信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的机型，为您提供通知信息推送功能。</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >5、客服与售后</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用场景</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息范围</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >客服与售后</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >应用信息、设备信息、设备连接信息、位置信息、日志信息、与服务器通讯信息以及您发送的邮件内容、截图、文档或与金山办公客服人员的电话对话内容</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于客服调查事实、解决问题</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >联系人姓名、公司名称、联系电话、电子邮箱地址及您的咨询内容</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >6、广告服务</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0.225rem 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用场景</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0.225rem 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个人信息范围</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0.225rem 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0.225rem 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广告服务</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 25%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0.225rem 0rem;
                    text-align: left;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备标识信息（IMEI、AndroidID、OAID、IDFV、IDFA）、网络信息（IP地址、SSID、运营商类型、Mac地址）、应用特征信息（应用包名、版本号、运行状态）、设备信息（设备制造商、设备名称、设备型号、操作系统版本、屏幕信息）、广告数据（如曝光、点击数据等）、粗略位置信息、浏览器类型、网络环境、设备安装列表。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0.225rem 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >7、请您注意，您作为</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >机构/团体/企业</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
            "
            >成员</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用本服务，下述个人信息将由您的企业委托</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >进行处理。对于这些数据的收集目的和使用场景将由您所在</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >机构/团体/企业</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >向您告知。如您对相关内容有疑问或主张相关的个人信息权益，可以联系您的</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >机构/团体/企业</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >进行处理。</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >场景/业务功能</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >可能会收集的个人信息</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="5"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用产品产生的内容类信息</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭写作、审核、合规、存储</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >文档、文档信息（文档类型、大小、文档权限、存放位置）</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >文档评论、修改痕迹</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >偏好信息库</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >信息库类型设置</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 10%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >阅读信息</span
                  >
                </p>
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                ></p>
              </td>
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >基础个人信息（如姓名、联系电话、电子邮箱、头像）</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 40%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: Arial;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >浏览历史</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <br />
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >附件</span
          ><span
            class="X1 X2"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2</span
          ><span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >：个人信息共享清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >本《个人信息共享清单》列明我们主要对外提供个人信息的情况及接入的由第三方提供的软件工具开发包（</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >SDK</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、为实现我们的服务</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >功能所必需进行的共享</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)支付服务</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用场景：订单支付等</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用目的：保障交易安全、完成商品</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >服务的支付</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息类型：订单支付相关信息</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >接收方类型：提供支付服务的合作方，如财付通支付科技有限公司、支付宝（中国）网络技术有限公司</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >、</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >银联在线、PayPal</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >处理方式：我们采用加密算法对数据做加密处理，并将加密后的数据采用专用传输通道提供给合作方，合作方根据算法进行解密后在您的授权范围内使用您的个人信息。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、关联公司统一账号体系</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)注册</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >登录您的账号</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用场景：注册</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >登录</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用目的：向您提供一致化服务、完善个性化体验</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息类型：设备信息、基本资料信息（包括昵称、头像、手机号码、电子邮件地址、会员状态）、使用记录</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >接收方类型：关联公司</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >处理方式：适用统一账号体系的关联公司产品与</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或服务，以去标识化处理的统一字符串为用户标记，以实现相关功能。</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)帮助您找回您的账号</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用场景：账号申诉（找回账号）</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >使用目的：确保您所申请找回的账号是归您所有</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >个人信息类型：真实姓名、身份证号码、手机号码</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >接收方类型：</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >短信，邮件</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >）</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >处理方式：我们采用加密算法对数据做加密处理，并将加密后的数据采用专用传输通道提供给合作方，合作方根据算法进行解密后在您的授权范围内使用您的个人信息。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、第三方</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >SDK</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >第三方</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >SDK</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >可能因为其版本升级、策略调整等原因导致其处理的信息类型发生一定变化，请以其公示的官方说明为准。以下是我们接入的主要的第三方</span
          ><span
            class="X1 X6"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >SDK</span
          ><span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >的信息：</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >功能类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及个人信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方主体</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >共享方式</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方链接</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="6"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方账号登录</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="6"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，为您提供第三方账号登录/分享功能。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >微信SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备型号、操作系统、唯一设备标识符（指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳市腾讯计算机系统有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >小米SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >小米公司及其关联公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://privacy.mi.com/miaccount/zh_CN/"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://privacy.mi.com/miaccount/zh_CN/</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >QQ SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >腾讯科技（深圳）有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://wiki.connect.qq.com/qq%E4%BA%92%E8%81%94sdk%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://wiki.connect.qq.com/qq互联sdk隐私保护声明</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >微博SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京微梦创科网络技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://m.weibo.cn/c/privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://m.weibo.cn/c/privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >钉钉SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备型号、操作系统版本、设备设置，国际移动设备识别码（IMEI）、设备型号、设备平台</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >钉钉（中国）信息技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_dingtalk/suit_bu1_dingtalk202010070946_49604.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_dingtalk/suit_bu1_dingtalk202010070946_49604.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >华为登录SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息、网络信息（网络连接状态、接入网络的方式和类型）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >华为终端有限公司及其关联公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://consumer.huawei.com/minisite/cloudservice/id/privacy-statement.htm?country=CN&amp;language=zh_Hans_CN"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://consumer.huawei.com/minisite/cloudservice/id/privacy-statement.htm?country=CN&amp;language=zh_Hans_CN</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="4"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码一键登录</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="4"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，为您提供手机号码一键登录功能。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭云号码认证SDK</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码（运营商提供，已脱敏）、应用特征信息（应用程序版本、应用程序名称）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳写手智能科技有限公司</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >官网链接http://antiplagiarize.com</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >移动一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、设备标识信息（IMSI、IMEI、ICCID、IDFV）、网络信息（IP地址）、设备参数及系统信息（设备类型、设备型号、设备品牌、操作系统）、应用特征信息（应用程序版本、应用程序名称）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >中移互联网有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策</span
                  ><a
                    href="https://wap.cmpassport.com/resources/html/contract.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://wap.cmpassport.com/resources/html/contract.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >联通一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、网络信息（蜂窝数据网络私网、IP地址）、设备参数及系统信息（设备类型、设备品牌、设备型号）、应用特征信息（应用包名和签名）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >中国联合网络通信有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >电信一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、设备标识信息（AndroidID）、移动应用列表信息、设备参数及系统信息（设备型号、设备品牌、操作系统版本）、网络信息（IP地址、网络类型）、应用特征信息（应用包名和签名）、运营商类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >世纪龙信息网络有限责任公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://e.189.cn/sdk/agreement/detail.do?hidetop=true"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://e.189.cn/sdk/agreement/detail.do?hidetop=true</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="5"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >推送通知消息</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="5"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的机型，为您提供通知信息推送功能。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >MobpushSDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >系统运行信息、应用列表信息、网络状态信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >上海游昆信息技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.mob.com/about/policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.mob.com/about/policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >华为推送SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备及应用信息、移动网络信息、日志信息、国际移动设备识别码（IMEI）、设备型号、设备平台</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >华为终端有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >小米推送SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备厂商、设备型号、设备内存、操作系统版本、设备归属地（国家或地区）、设备识别信息（包括：OAID、加密的Android
                    ID、应用信息）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京小米科技有限责任公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://dev.mi.com/console/doc/detail?pId=1822"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://dev.mi.com/console/doc/detail?pId=1822</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >OPPO推送SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（IMEI号、Serial Number、IMSI、User ID、Android
                    ID、手机Region设置、设备型号、手机电量、手机操作系统版本及语言）、应用信息、网络信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广东欢太科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://open.oppomobile.com/wiki/doc"
                    id='10288"'
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://open.oppomobile.com/wiki/doc#id=10288</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >VIVO推送SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备平台、设备厂商、设备品牌信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广东天宸网络科技有限公司及将来受让运营vivo开放平台的公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.vivo.com.cn/about-vivo/privacy-policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >订单支付</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >帮助您在应用内完成订单支付。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >微信支付SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >财付通支付科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付宝SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息以及地理位置信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付宝（中国）网络技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://opendocs.alipay.com/open/01g6qm"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://opendocs.alipay.com/open/01g6qm</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >统计分析</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行数据分析以及上报APP崩溃信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟U-App SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备Mac地址、唯一设备识别码（IMEI/android
                    ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息）、IP地址。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.umeng.com/page/policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.umeng.com/page/policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行数据统计技术服务。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个推个像SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（如设备制造商、设备型号、操作系统版本等）、网络信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >每日互动股份有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://docs.getui.com/privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://docs.getui.com/privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="8"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广告服务</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广点通SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >粗略位置信息、设备信息（如设备制造商、设备型号、操作系统版本等）、设备标识符（如IMEI、IDFA等）、应用信息（宿主应用的包名、版本号）、广告数据（如曝光、点击数据等）。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳市腾讯计算机系统有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >OPPO广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >IP地址，软件版本号，Android版本、设备型号、操作系统版本、唯一设备标识符、设备地理位置、存储空间、国际移动设备识别码（IMEI）、网络设备硬件地址MAC、openid，电信运营商、网络环境。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广东欢太科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://u.oppomobile.com/main/privacy.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://u.oppomobile.com/main/privacy.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >VIVO广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >IP地址，设备型号、系统版本、地理位置、国际移动设备识别码（IMEI）、MAC地址，浏览器类型、网络环境，设备安装列表。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广东天宸网络科技有限公司及将来受让运营vivo开放平台的公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.vivo.com.cn/about-vivo/privacy-policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >小米MimoSDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >屏幕宽、屏幕高、屏幕密度、型号、设备、安卓版本号、制造商、语言、国家、网络类型、连接类型、服务商、
                    国际移动设备识别码（IMEI）、MAC地址、AAID、AndroidID、IP地址、UA。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳小米信息技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://dev.mi.com/console/doc/detail?pId=326"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://dev.mi.com/console/doc/detail?pId=326</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >讯飞广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >国际移动设备识别码（IMEI）、MAC地址，Androidid，操作系统、IP地址，网络环境，设备类型。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >科大讯飞股份有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.voiceads.cn/ai-help.html?id=220"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.voiceads.cn/ai-help.html?id=220</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >京东广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >国际移动设备识别码（IMEI）、openUDID、MAC地址、IP地址、手机系统信息、京东APP版本号。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京京东世纪贸易有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://jzt.jd.com/doc/privacy.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://jzt.jd.com/doc/privacy.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >搜狗网盟广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（设备制造商、设备型号、操作系统版本、屏幕分辨率、屏幕方向、屏幕DPI、IP地址）、应用信息（宿主应用的包名、版本号、安装渠道）、广告数据（如展示、点击、转化广告数据）、诊断数据（如崩溃数据、性能数据）。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京搜狗科技发展有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="http://support.union.sogou.com/union/html/help/newhand/sdkPrivacyAgreement.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >http://support.union.sogou.com/union/html/help/newhand/sdkPrivacyAgreement.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于程序化广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（IMEI/MAC/Android ID/SIM卡IMSI信息等）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟同欣（北京）科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.umeng.com/page/policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.umeng.com/page/policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >语音服务</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，帮忙您完</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音朗读服务。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >百度语音SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（操作系统版本、唯一设备标识符、国际移动设备身份码IMEI）、日志信息（如操作系统版本、访问日期、时间、时长等）。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京百度网讯科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://ai.baidu.com/ai-doc/REFERENCE/Qkdykq1r3"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://ai.baidu.com/ai-doc/REFERENCE/Qkdykq1r3</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，帮忙您完</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >反抄袭</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音朗读、语音搜索服务。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4.99%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >讯飞语音SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >您主动输入的语音内容、文本信息、图像信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >科大讯飞股份有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >后台接口传输</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.xfyun.cn/doc/policy/privacy.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.xfyun.cn/doc/policy/privacy.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >功能类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及个人信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方主体</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >共享方式</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方链接</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="5"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方账号登录</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="5"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，为您提供第三方账号登录/分享。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >微信SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备型号、操作系统、唯一设备标识符（指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳市腾讯计算机系统有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >小米SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >小米公司及其关联公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://privacy.mi.com/miaccount/zh_CN/"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://privacy.mi.com/miaccount/zh_CN/</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >QQ SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >腾讯科技（深圳）有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://wiki.connect.qq.com/qq%E4%BA%92%E8%81%94sdk%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://wiki.connect.qq.com/qq互联sdk隐私保护声明</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >微博SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京微梦创科网络技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://m.weibo.cn/c/privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://m.weibo.cn/c/privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >钉钉SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备型号、操作系统版本、设备设置，国际移动设备识别码（IMEI）、设备型号、设备平台</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >钉钉（中国）信息技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_dingtalk/suit_bu1_dingtalk202010070946_49604.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_dingtalk/suit_bu1_dingtalk202010070946_49604.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="4"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码一键登录</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="4"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，为您提供手机号码一键登录。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                    "
                    >（</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                    "
                    >反抄袭</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                    "
                    >）</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >云号码认证SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码（运营商提供，已脱敏）、应用特征信息（应用程序版本、应用程序名称）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳写手智能科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >官网链接：</span
                  ><span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.75rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >http://antiplagiarize.com</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >移动一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、设备标识信息（IMSI、IMEI、ICCID、IDFV）、网络信息（IP地址）、设备参数及系统信息（设备类型、设备型号、设备品牌、操作系统）、应用特征信息（应用程序版本、应用程序名称）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >中移互联网有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://wap.cmpassport.com/resources/html/contract.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://wap.cmpassport.com/resources/html/contract.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >联通一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、网络信息（蜂窝数据网络私网、IP地址）、设备参数及系统信息（设备类型、设备品牌、设备型号）、应用特征信息（应用包名和签名）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >中国联合网络通信有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >电信一键登录</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >手机号码、设备标识信息（AndroidID）、移动应用列表信息、设备参数及系统信息（设备型号、设备品牌、操作系统版本）、网络信息（IP地址、网络类型）、应用特征信息（应用包名和签名）、运营商类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >世纪龙信息网络有限责任公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://e.189.cn/sdk/agreement/detail.do?hidetop=true&amp;appKey="
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://e.189.cn/sdk/agreement/detail.do?hidetop=true&amp;appKey=</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >订单支付</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >帮助您在应用内完成订单支付。</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >微信支付SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >财付通支付科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付宝SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息以及地理位置信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付宝（中国）网络技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://opendocs.alipay.com/open/01g6qm"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://opendocs.alipay.com/open/01g6qm</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="4"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >统计分析</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行统计崩溃和错误分析。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >CrashEyeSDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <a
                    href="http://www.testplus.cn/terms"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >设备厂家及型号、设备磁盘空间及网络状态、设备软件版本信息及操作系统、产品崩溃信息、IDFV、日志信息。</span
                    ></a
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >成都西山居世游科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="http://docs-outside.console.testplus.cn/crasheye/privacy.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >http://docs-outside.console.testplus.cn/crasheye/privacy.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行数据分析以及上报APP崩溃信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟U-App SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备Mac地址、唯一设备识别码（IMEI/android
                    ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息）、IP地址。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.umeng.com/page/policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.umeng.com/page/policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行数据统计技术服务。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >个推个像SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（如设备制造商、设备型号、操作系统版本等）、网络信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >每日互动股份有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://docs.getui.com/privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://docs.getui.com/privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于进行性能监测及分析。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟U-APM SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（IMEI/MAC/Android
                    ID/IDFA/OpenUDID/GUID/SIM卡IMSI等）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.umeng.com/page/policy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.umeng.com/page/policy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="3"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广告服务</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >穿山甲</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备标识信息（IMEI、AndroidID、OAID、IDFV、IDFA）、网络信息（IP地址、SSID、运营商类型、Mac地址）、应用特征信息（应用包名、版本号、运行状态）、设备信息（设备制造商、设备名称、设备型号、操作系统版本）。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >北京巨量引擎网络技术有限公司及其关联方</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >后台接口传输</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.pangle.cn/privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.pangle.cn/privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >优量汇SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >粗略位置信息、设备信息（如设备制造商、设备型号、操作系统版本等）、设备标识符（如IMEI、IDFA等）、应用信息（宿主应用的包名、版本号）、广告数据（如曝光、点击数据等）。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳市腾讯计算机系统有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html</span
                    ></a
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于广告推送、广告监测与归因。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >京准通广告SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >系统版本号、设备IDFV、设备型号、屏幕信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >重庆京东海嘉电子商务有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://about.jd.com/privacy"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://about.jd.com/privacy</span
                    ></a
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >功能类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及个人信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方主体</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >共享方式</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方链接</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >投屏服务</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >根据您的选择，为您提供投屏服务。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >乐播插件</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >硬件序列号、设备唯一识别码、MAC地址、IP地址。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >深圳乐播科技有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://www.lebo.cn/privacy.jsp"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://www.lebo.cn/privacy.jsp</span
                    ></a
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4)</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >HarmonyOS</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >功能类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及个人信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方主体</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >共享方式</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #bebebe;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >第三方链接</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >订单支付</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >帮助您在应用内完成订单支付</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付宝SDK</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息以及地理位置信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 6%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >支付宝（中国）网络技术有限公司</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 4%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >SDK本机采集</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 12.5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >隐私政策：</span
                  ><a
                    href="https://opendocs.alipay.com/open/01g6qm"
                    style="text-decoration: none"
                    ><span
                      class="X1 X6"
                      style="
                        font-family: 微软雅黑;
                        font-size: 0.675rem;
                        color: rgb(0, 0, 0);
                        white-space: pre-wrap;
                      "
                      >https://opendocs.alipay.com/open/01g6qm</span
                    ></a
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、与合作伙伴共享</span
          >
        </p>
        <p
          class="X1 X4"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1)如您参与了</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >写手智能科技</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >及</span
          ><span
            class="X1 X4"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >/</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >或关联公司的抽奖、赠送、福利领取、比赛、促销、使用积分兑换商品等活动，金山办公或者相关奖品、礼品、商品的供货商需要向您寄送相关奖品、礼品、商品时，您或您指定的收件人的个人信息</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              font-weight: bold;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >（姓名、电话号码（包括手机号）、联系地址）</span
          ><span
            class="X1 X4"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >将被提供给快递服务合作伙伴顺丰、圆通（具体以网页上显示的为准）或者相关供货商。</span
          >
        </p>
        <p
          class="X1 X2"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >附件</span
          ><span
            class="X1 X2"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3</span
          ><span
            class="X1 X2"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >：应用权限清单</span
          >
        </p>
        <p
          class="X1 X6"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X6"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >为了向您提供服务，我们可能会申请或使用操作系统的相关权限，我们通过以下列表将可能申请、使用的相关操作系统权限进行说明，您可以根据实际需要对相关权限进行管理。</span
          >
        </p>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >1、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Android</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否可关闭</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >存储</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >READ_EXTERNAL_STORAGE读取SD卡中的内容（读取存储空间/照片权限）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >读取您设备中的图片、文件等信息，以实现头像上传或更换、文档查看或编辑以及上传至云。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >存储文件等信息</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >WRITE_EXTERNAL_STORAGE写入/删除SD卡中的内容（写入/删除/存储空间/照片权限）</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >将客户端内的图片、文件、缓存、崩溃日志等信息，存储至设备的空间中。</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >相机</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >CAMERA拍摄照片和视频</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取您拍摄的图片以实现头像上传或更换，以及文档中插入拍摄照片；以实现投影宝扫码连接、扫一扫、拍照扫描等功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >图片、文件等信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >麦克风</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >READ_AUDIO</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >以实现录音宝服务、会议功能、语音朗读等功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音频信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >录音</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >RECORD_AUDIO</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >以实现文档编辑时语音输入。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >语音信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >电话</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >READ_PHONE_STATE读取电话状态</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >读取设备标识等常用设备信息，根据您的设备信息，为您提供音视频通话、安全保障、风险控制等。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >设备信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >2、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >iOS</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否可关闭</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >相册</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSPhotoLibraryUsageDescription读取相册中内容</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >请您放心该权限无法监听、获取您的任何通话内容与信息。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >相册文件等信息</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSPhotoLibraryAddUsageDescription向相册中添加内容</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >将客户端内的图片、视频等信息，存储至设备的空间中。</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >相机</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSCameraUsageDescription使用摄像头</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取您拍摄的图片以实现头像上传或更换，以及文档中插入拍摄照片；以实现投影宝扫码连接、扫一扫、拍照扫描等功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >图片等信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >麦克风</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSMicrophoneUsageDescription使用麦克风</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取录音权限以实现会议、录制文档视频、语音转文字等功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音频信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >媒体与Apple Music</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSAppleMusicUsageDescription读取Apple媒体资料库中的音乐</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取音视频信息，以实现插入音频功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音频信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >语音识别</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSSpeechRecognitionUsageDescription使用系统语音转文字</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取语音识别权限，以实现语音笔记、语音转文字等功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音频信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >面容ID</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSFaceIDUsageDescription获取FaceID权限</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取Face ID权限以实现面容解锁。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >FaceID信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >本地网络</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSLocalNetworkUsageDescription访问本地网络权限</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取本地网络权限，用于选择使用局域网传输下的电脑快传服务。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >网络信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >IDFA授权</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >NSUserTrackingUsageDescription</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >iOS 14.5适配IDFA授权弹框</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >广告请求－IDFA读取授权</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >3、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >Mac</span
          ><span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >端</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否可关闭</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >存储</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >com.apple.security.temporary-exception.files.home-relative-path.read-write启用对用户主目录中指定的文件或子目录的读/写访问</span
                  >
                </p>
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >com.apple.security.temporary-exception.files.absolute-path.read-write启用对指定绝对路径上的指定文件或目录的读/写访问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >读取您设备中的图片、文件等信息，以实现头像上传或更换、文档查看或编辑以及上传至云。将客户端内的图片、文件、缓存、崩溃日志等信息，存储至设备的空间中。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >存储文件等信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >相机</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >com.apple.security.device.camera拍摄照片和视频</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于实现屏幕录制的功能</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >图片、文件等信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >麦克风</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >com.apple.security.device.microphone使用麦克风</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >获取您的音频信息用于演讲实录功能、屏幕录制功能</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >音频信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >屏幕录制</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >com.apple.security.device.camera拍摄照片和视频</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用于实现屏幕录制的功能</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >视频信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="X1 X3"
          style="
            margin: 0.225rem 0rem;
            text-indent: 0rem;
            text-align: justify;
            white-space: pre-wrap;
          "
        >
          <span
            class="X1 X3"
            style="
              font-family: 微软雅黑;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >4、</span
          ><span
            class="X1 X3"
            style="
              font-family: Arial;
              font-size: 0.75rem;
              color: rgb(0, 0, 0);
              white-space: pre-wrap;
            "
            >HarmonyOS</span
          >
        </p>
        <table
          cellspacing="1"
          border="1px solid #000"
          class="X7"
          style="width: 50%; border-collapse: collapse"
        >
          <tbody>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限类型</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >权限名称</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >使用目的</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >涉及信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否询问</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #bfbfbf;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      font-weight: bold;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是否可关闭</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >存储</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >ohos.permission.WRITE_USER_STORAGE允许应用程序向设备存储中写入文件</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >读取您设备中的图片、文件等信息，以实现头像上传或更换、文档查看或编辑以及上传至云。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >存储文件等信息</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户主动使用时弹窗询问</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
                rowspan="2"
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >是</span
                  >
                </p>
                <p style="white-space: pre-wrap" class="Normal--Web-"></p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >ohos.permission.READ_USER_STORAGE允许应用程序从设备存储中读取文件</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >将客户端内的图片、文件、缓存、崩溃日志等信息，存储至设备的空间中。</span
                  >
                </p>
              </td>
            </tr>
            <tr class="X7">
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >通话状态及移动网络信息权限</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >ohos.permission.GET_NETWORK_INFO查看网络状态</span
                  >
                </p>
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >ohos.permission.INTERNET访问本地网络权限</span
                  >
                </p>
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >ohos.permission.SET_NETWORK_INFO允许应用控制数据网络</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 15%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="margin: 0rem; text-align: left; white-space: pre-wrap"
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >用户监控网络状态切换，访问网络数据，及流转功能。</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >网络信息</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >内置申明</span
                  >
                </p>
              </td>
              <td
                class="X7"
                style="
                  width: 5%;
                  background-color: #ffffff;
                  border-top: 0.75px solid #dddddd;
                  border-bottom: 0.75px solid #dddddd;
                  border-left: 0.75px solid #dddddd;
                  border-right: 0.75px solid #dddddd;
                "
              >
                <p
                  class="X1 X6"
                  style="
                    margin: 0rem;
                    text-align: center;
                    white-space: pre-wrap;
                  "
                >
                  <span
                    class="X1 X6"
                    style="
                      font-family: 微软雅黑;
                      font-size: 0.675rem;
                      color: rgb(0, 0, 0);
                      white-space: pre-wrap;
                    "
                    >否</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style="text-align: left; white-space: pre-wrap"
          class="Normal--Web-"
        ></p>
      </div>
    </div>
    <!-- <iframe
      class="ntiplagiari"
      src="http://antiplagiarize-api.writemall.com/protocol/policy.html"
    ></iframe> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "regardsMy/policy.html",
    };
  },

  methods: {
    onArrow() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100% !important;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}
p,
span {
  white-space: normal !important;
}
.ntiplagiari {
  position: absolute;
  top: 92px;
  left: 0;
  width: 100%;
  height: calc(100vh - 92px);
}
* {
  margin: 0;
  padding: 0;
}
.location {
  width: 100%;
  .header-hds {
    height: calc(100vh - 123px);
    overflow-y: auto;
    p {
      text-indent: 2em !important;
      line-height: 36px;
      color: #333333;
    }
  }
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  .header {
    width: 100%;
    height: 467px;
    margin-top: 29px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.Privacy {
  width: 100%;
  height: calc(100vh - 94px);
  overflow-y: auto;
  padding: 0 30px 40px;
  box-sizing: border-box;
  p {
    font-size: 24px;
    line-height: 36px;
    text-indent: 2em;
  }
  h2 {
    font-size: 24px;
    margin: 30px 0;
    text-indent: 2em;
    line-height: 36px;
  }
  a {
    color: #0000f1;
  }
}

::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text {
  color: #333333;
  font-size: 28px;
}
::v-deep .van-swipe__indicators .van-swipe__indicator {
  width: 38px;
  height: 7px;
  border-radius: 3.5px;
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 1px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
