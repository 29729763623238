<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
#app {
  // 隐藏滚动条
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: hidden;
  overflow-y: scroll;
  .container {
    width: 1200px;
    margin: 30px auto;
  }
}
</style>
