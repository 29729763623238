<template>
  <div class="share">
    <van-nav-bar class="van-bar" @click-right="onArrow" title="文件分享">
    </van-nav-bar>
    <div class="head-logo">
      <img src="../../assets/images/01.png" alt="" />
    </div>
    <div class="banner">
      <span ref="qrcode_box"></span>
    </div>
    <div class="banner-bd">
      <div>{{ text }}</div>
    </div>
    <div class="fooder-hd">
      <div @click="open">打开</div>
    </div>
    <div class="fooder-bd">
      <div class="box"></div>
      <div class="box1" @click="downloadApp">下载APP客户端</div>
    </div>
  </div>
</template>

<script>
import QrCode from "qrcodejs2";
export default {
  data() {
    return {
      text: "",
      qr: "",
      url: "",
      fileName: "",
      //默认服务器路径
      basePath: "https://fafs.antiplagiarize.com/",
    };
  },
  mounted() {
    // this.url =
    //   "aHR0cDovL2ZhZnMuYW50aXBsYWdpYXJpemUuY29tL2dyb3VwMS9NMDAvMDEvMDcvTDJwVUhXSmllNnVBZnRTR0FBS1FqajJTSzBjMDI3LnBuZw%3D%3D";
    // let fileName = "01.png";
    // let a =
    //   "http://passport.writemall.com/#/share?fileName=%E6%9C%AA%E5%91%BD%E5%90%8D.docx&url=group1%2FM00%2F01%2F05%2FL2rchmJiGSaARxlNAAQGrNC-95g510.pdf";
    let a = JSON.parse(window.localStorage.getItem("url"));
    console.log(a);
    this.$nextTick(() => {
      this.text = a.split("&")[0];
      this.text = this.text.split("=")[1];
      this.text = decodeURIComponent(this.text);
      this.url = a.split("&")[1];
      this.url = this.url.split("=")[1];
      this.crateQrcode(this.url, this.text);
    });
  },
  destroy() {
    window.localStorage.removeItem("url");
  },
  methods: {
    onArrow() {
      console.log(111);
    }, // 打开文件
    downloadApp() {
      this.$router.push({
        name: "yddownload",
      });
    },
    open() {
      // console.log(this.basePath + this.url);
      // this.url = "group1/M00/01/07/L2pUHWJiXKCASa_cAU6CjZtW3S0989.rar";
      // this.text = "test1.rar";
      window.location.href = `https://fafs.antiplagiarize.com/${this.url}?attname=${this.text}`;
      // window.location.href="https://fafs.antiplagiarize.com/group1/M00/01/07/L2pUHWJiXKCASa_cAU6CjZtW3S0989.rar?attname=test1.rar"
      // this.$router.push({
      //   name: "openFile",
      //   query: {
      //     url: this.basePath + this.url,
      //     fileName: this.text,
      //   },
      // });
    },
    // 生成二维码
    crateQrcode(pdf, fileName) {
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: `http://cloud.antiplagiarize.com/share?fileName=${fileName}&url=${pdf}`,
        width: 86,
        height: 86,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  width: 100%;
  height: 100vh;
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  .head-logo {
    width: 100%;
    text-align: center;
    margin-top: 104px;
    img {
      width: 308px;
      height: 86px;
    }
  }
  .banner {
    width: 100%;
    margin-top: 67px;
    margin-bottom: 55px;
    text-align: center;
    span {
      display: inline-block;
      width: 167px;
      height: 167px;
    }
  }
  .banner-bd {
    width: 100%;

    line-height: 52px;
    padding: 0px 50px;
    box-sizing: border-box;
    div {
      width: 100%;
      height: 100%;
      background: #f9f9f9;
      border-radius: 10px;
      padding: 34px 80px;
      box-sizing: border-box;
      text-align: center;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }
  .fooder-hd {
    margin: 60px 0 51px;
    width: 100%;
    height: 98px;
    padding: 0 80px;
    box-sizing: border-box;
    div {
      width: 100%;
      height: 100%;
      background: #4588ff;
      border-radius: 49px;

      line-height: 98px;
      text-align: center;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #fefefe;
    }
  }
  .fooder-bd {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4588ff;

    .box {
      width: 28px;
      height: 28px;
      background: url("../../assets/images/03.png") no-repeat;
      background-size: 100%;
      margin-right: 20px;
    }
  }
}
::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text {
  color: #333333;
  font-size: 28px;
}
::v-deep .van-swipe__indicators .van-swipe__indicator {
  width: 38px;
  height: 7px;
  border-radius: 3.5px;
}
</style>
