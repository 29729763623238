<template>
	<!-- 用户登录 -->
	<div class="login-main">
		<div class="login-logo">
			<img src="../../assets/images/login/login-logo.png" width="70px" height="70px" />
		</div>
		<div class="login-wrap">
			<div class="login-mode">
				<input class="login-nav login-nav1" id="mode1" type="radio" name="login-nav" checked="" />
				<input class="login-nav login-nav2" id="mode2" type="radio" name="login-nav" />
				<input class="login-nav login-nav3" id="mode3" type="radio" name="login-nav" />
				<input class="login-nav login-nav4" id="mode4" type="radio" name="login-nav" />
				<ul class="login-mode-nav">
					<li>
						<label for="mode1" @click="loginType = 1">手机登录</label>
					</li>
					<li>
						<label for="mode2" @click="loginType = 2">短信登录</label>
					</li>
					<li>
						<label for="mode3" id="mob3" @click="loginType = 3">微信登录</label>
					</li>
					<li>
						<label for="mode4" class="mob4 mob" @click="loginType = 4">扫码登录</label>
					</li>
				</ul>
				<div class="login-mode-content" :style="{
	      display: loginType == 1 || loginType == 2 ? 'block' : 'none',
	    }">
					<!-- 手机登录 -->
					<div class="login-mode-content-mode" id="content-mode1"
						:style="{ display: loginType == 1 ? 'flex' : '' }">
						<form id="pwdLoginForm">
							<div class="login-content-inpwrap">
								<div class="login-content-inpwrap-icon">
									<img :src="require('assets/images/login/login-yonghu.png')" alt="" />
								</div>
								<div class="login-content-inpwrap-input">
									<input type="text" id="laccount" name="mobile" v-model="phone_number_mode1"
										placeholder="请输入手机号" />
								</div>
							</div>
							<div class="login-content-inpwrap" style="margin-top: 20px">
								<div class="login-content-inpwrap-icon">
									<img :src="require('assets/images/login/login-suo.png')" alt="" />
								</div>
								<div class="login-content-inpwrap-input">
									<input :type="show_pass ? 'text' : 'password'" id="password" name="password"
										v-model="password" placeholder="请输入密码" />
								</div>
								<div class="login-content-inpwrap-type" data-type="false">
									<img v-if="!show_pass" :src="require('assets/images/login/login-changetype.png')"
										alt="" @click="show_pass = true" />
									<img v-else :src="require('assets/images/login/login-yan-h.png')" alt=""
										@click="show_pass = false" />
								</div>
							</div>
						</form>
					</div>
					<!-- 短信登录 -->
					<div class="login-mode-content-mode" id="content-mode2"
						:style="{ display: loginType == 2 ? 'flex' : '' }">
						<form id="codeLoginForm">
							<div class="login-content-inpwrap">
								<div class="login-content-inpwrap-icon">
									<img :src="require('assets/images/login/login-yonghu.png')" alt="" />
								</div>
								<div class="login-content-inpwrap-input">
									<input type="text" id="mobile" name="mobile" v-model="phone_number_mode2"
										placeholder="请输入手机号" />
								</div>
							</div>
							<div class="login-content-inpwrap" style="margin-top: 15px">
								<div class="login-content-inpwrap-icon">
									<img :src="require('assets/images/login/login-codeico.png')" alt="" />
								</div>
								<div class="login-content-inpwrap-input">
									<input placeholder="请输入验证码" type="text" id="code" v-model="verif" name="code" />
								</div>
								<div class="login-content-inpwrap-sendcode" id="btnSendcode" @click="sendVerif"
									style="cursor: pointer">
									<span v-show="verif_seconds == 60">获取验证码</span>
									<span v-show="verif_seconds < 60">已发送({{ verif_seconds }})</span>
								</div>
								<input type="hidden" value="" id="lcodeId" name="id" />
								<!-- <div class="login-content-inpwrap-type">
					  <img src="/images/login/login-changetype.png" alt="">
					</div> -->
							</div>
						</form>
					</div>
				</div>
				<div :style="{ display: loginType == 3 ? 'block' : '' }" class="login-mode content3"
					id="content-mode3" style="margin-left: 15%; margin-top: 30px; width: 50%">
					<div class="scanContent" id="scan-login" style="height: 280px; text-align: center">
						<iframe
							src="https://open.weixin.qq.com/connect/qrconnect?appid=wxb31086d21ce9ad5c&amp;scope=snsapi_login&amp;redirect_uri=https://p.writemall.com/wechat/callBack&amp;state=51TOWIN&amp;login_type=jssdk&amp;self_redirect=default&amp;styletype=&amp;sizetype=&amp;bgcolor=&amp;rst=&amp;style=black&amp;href=https://p.writemall.com/css/wx-qrcode.css"
							frameborder="0" scrolling="no" width="300px" height="400px"></iframe>
					</div>
					<span style="display: none" class="wxPhone">微信扫码成功，请绑定手机号</span>
					<div class="login-mode-content-mode" id="wcontent-mode1"
						style="margin-top: 30px; display: none">
						<!-- display:flex -->
						<form id="wxForm">
							<div class="login-content-inpwrap">
								<div class="login-content-inpwrap-icon">
									<img :src="require('assets/images/login/login-yonghu.png')" alt="" />
								</div>
								<div class="login-content-inpwrap-input">
									<input type="text" id="waccount" name="mobile" v-model="phone_wx"
										placeholder="请输入绑定手机号" />
								</div>
							</div>
							<div class="login-content-inpwrap" style="margin-top: 20px">
								<div class="login-content-inpwrap-icon">
									<img :src="require('assets/images/login/login-codeico.png')" alt="" />
								</div>
								<div class="login-content-inpwrap-input">
									<input type="hidden" value="" id="wcodeId" />
									<input type="text" v-model="verif_wx" placeholder="请输入验证码" />
								</div>
								<div class="login-content-inpwrap-sendcode" style="cursor: pointer"
									@click="sendVerif">
									<span v-if="verif_seconds == 60">获取验证码</span>
									<span v-if="verif_seconds < 60">已发送({{ verif_seconds }})</span>
								</div>
							</div>
						</form>
					</div>
					<!-- 底部操作 -->
					<div style="display: none !important" class="login-footer content1">
						<!-- display:flex -->
						<div class="login-auto loginspwd" style="display: flex">
							<input type="checkbox" id="wauto-login" v-model="remenber_password" name="avoidLogin1"
								value="30" />
							<label for="wauto-login">自动登录</label>
						</div>
						<div class="login-footer-btn" id="wpwdBtn" 
							style="cursor: pointer; display: block">
							登录
						</div>
					</div>
				</div>
				<div :style="{ display: loginType == 4 ? 'block' : '' }" class="login-mode content2"
					id="content-mode4" style="margin-left: 90px; margin-top: 30px; width: 258px">
					<div class="scanContent" id="scan-login-mobile" style="margin-left: 50px">
						<canvas width="158" height="158"></canvas>
					</div>
					<div class="scanInfo" id="scan-info" style="margin-top: 10px; text-align: center">
						<p id="scan-msg" style="color: #333333">二维码已失效请刷新重试</p>
					</div>
					<div class="scanInfo" style="margin-top: 10px; text-align: center">
						<span style="
	          width: 252px;
	          height: 12px;
	          font-size: 12px;
	          font-family: Microsoft YaHei;
	          font-weight: 500;
	          color: #999999;
	        ">如无法正常扫码登录，请更新写手移动端版本</span>
					</div>
					<input type="hidden" name="qrCodeKey" value="ba310aa2-b50b-4d51-b4a5-01ebcb91e01e"
						id="qrCodeKey" />
				</div>
			</div>
			<!-- 底部操作 -->
			<div :style="{
	    display: loginType == 1 || loginType == 2 ? 'flex' : 'none',
	  }" class="login-tool content1 loginspwd">
				<div class="login-auto loginspwd" style="display: flex">
					<input type="checkbox" id="auto-login" v-model="remenber_password" name="avoidLogin1"
						value="30" />
					<label for="auto-login">记住密码</label>
				</div>
				<div class="login-aspwd loginspwd" style="display: block">
					<span class="login-forgetpwd" @click="toForgot">忘记密码</span>
				</div>
			</div>
			<div :style="{
	    display: loginType == 1 || loginType == 2 ? 'flex' : 'none',
	  }" class="login-footer content1">
				<div class="login-footer-tips">
					还没有注册账号，立即
					<a @click="toRegister" style="cursor: pointer">注册</a>
				</div>
				<div class="login-footer-btn" id="pwdBtn" @click="pwdLogin" style="cursor: pointer"
					:style="{ display: loginType == 1 ? 'block' : 'none' }">
					登录
				</div>
				<div class="login-footer-btn" id="codeBtn" style="cursor: pointer"
					:style="{ display: loginType == 2 ? 'block' : 'none' }" @click="verifLogin">
					登录
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getMobileAchCaptchas
	} from "api/common";
	export default {
		name: "Login",
		data() {
			return {
				//密码登录输入手机号码
				phone_number_mode1: "",
				//验证码登录输入手机号码
				phone_number_mode2: "",
				//密码
				password: "",
				//验证码
				verif: "",
				//登录类型
				loginType: 1,
				//显示密码
				show_pass: false,
				//记住密码
				remenber_password: false,
				//验证码ID
				verif_code: "",
				//发送验证码计时
				verif_seconds: 60,
				//timer
				timer: null,
				//登录弹窗
				login_flag: true,
				//微信绑定手机号
				phone_wx: "",
				//微信验证码
				verif_wx: "",
			};
		},
		methods: {
			//去注册
			toRegister() {
				this.$router.push('/register')
			},
			//忘记密码
			toForgot() {
				this.$router.push('/forgot')
			},
			//手机验证
			validatePhone(phone) {
				let reg = /^1[0-9]{10}$/;
				if (!phone) {
					this.$message({
						type: "error",
						message: "请输入手机号!",
					});
					return false;
				}
				if (!reg.test(phone)) {
					this.$message({
						type: "error",
						message: "请输入正确的手机号!",
					});
					return false;
				}
				return true;
			},
			//密码登录
			pwdLogin() {
				if (!this.validatePhone(this.phone_number_mode1)) return;
				if (!this.password) {
					return this.$message({
						type: "error",
						message: "请输入密码!",
					});
				}
				// const data = {
				// 	mobile: this.phone_number_mode1,
				// 	password: this.password,
				// };
				// passwordLogin(data)
				// 	.then((res) => {
				// 		let data = res;
				// 		data = JSON.stringify(data);
				// 		//保存token
				// 		Cookies.set("auth_token", data, {
				// 			expires: 7,
				// 			path: "/",
				// 			domain: ".writemall.com",
				// 		});
				// 		this.$message({
				// 			type: "success",
				// 			message: "登录成功!",
				// 		});
				// 		this.login_flag = false;
				// 		this.getUserinfo();
				// 	})
				// 	.catch(() => {
				// 		this.$message.error("手机号或密码错误");
				// 	});
			},
			//发送验证码
			sendVerif() {
				if (!this.validatePhone(this.phone_number_mode2)) return;
				const data = {
					type: 5,
					mobile: this.phone_number_mode2,
				};
				getMobileAchCaptchas(data).then((res) => {
					if (res.code == 200) {
						this.verif_code = res.data.codeId;
						this.setVerifTime();
					} else {
						this.$message({
							type: "error",
							message: res.message,
						});
					}
				});
			},
			//发送验证码计时
			setVerifTime() {
				if (!this.timer) {
					this.timer = setInterval(() => {
						if (this.verif_seconds > 0) {
							this.verif_seconds--;
						} else {
							clearInterval(this.timer);
							this.verif_seconds = 60;
						}
					}, 1000);
				} else {
					clearInterval(this.timer);
				}
			},
			//验证码登录
			verifLogin() {
				if (!this.validatePhone(this.phone_number_mode2)) return;
				if (this.verif_seconds == 60) {
					return this.$message({
						type: "error",
						message: "请先获取验证码!",
					});
				}
				if (this.verif_seconds < 60 && !this.verif) {
					return this.$message({
						type: "error",
						message: "请输入验证码!",
					});
				}
				// const data = {
				// 	mobile: this.phone_number_mode2,
				// 	code: this.verif,
				// 	id: this.verif_code,
				// };
				// codeLogin(data)
				// 	.then((res) => {
				// 		if (res.code == 200) {
				// 			this.$message({
				// 				type: "success",
				// 				message: "登录成功!",
				// 			});
				// 			this.login_flag = false;
				// 			this.getUserinfo();
				// 		} else {
				// 			this.$message({
				// 				type: "error",
				// 				message: res.message,
				// 			});
				// 		}
				// 	})
				// 	.catch(() => {});
			},
			handleEnter(e) {
				if (e.keyCode === 13) {
					if (this.loginType == 1) {
						this.pwdLogin();
					}
					if (this.loginType == 2) {
						this.verifLogin();
					}
				}
			},
		},
		mounted() {
			document.addEventListener("keyup", this.handleEnter);
		},
		beforeDestroy() {
			document.removeEventListener("keyup", this.handleEnter);
		},
	};
</script>

<style lang="scss" scoped>
	.login-cancel {
		margin-top: 14px;
		margin-left: 394px;
		width: 10px;
		height: 10px;
		color: #c7cbd3;
		cursor: pointer;
	}
	.wxPhone {
		width: 168px;
		height: 13px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
	}
	.login-main {
		z-index: 100;
		position: fixed;
		top: 50%;
		left: 50%;
		width: 418px;
		height: 420px;
		background-color: #fff;
		background-size: cover;
		background-repeat: no-repeat;
		font-family: Microsoft YaHei;
		transform: translate(-50%, -50%);
		border: 0px solid #818790;
		box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
		border-radius: 5px;
	}
	
	.login-wrap {
		width: 100%;
		/* height: 100%;
	  box-sizing: border-box; */
		/* height: inherit; */
	}
	
	.login-logo {
		width: 186px;
		height: 50px;
		margin-top: 15px;
		margin-left: 174px;
	}
	
	.login-mode {
		width: 100%;
	}
	
	.login-mode .login-nav {
		display: none;
	}
	
	.login-mode-nav {
		@include noselect;
		display: flex;
		justify-content: space-between;
		margin-top: 29px;
		width: 100%;
		padding: 0 50px;
		box-sizing: border-box;
	}
	
	.login-mode-nav li label {
		width: 65px;
		height: 16px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
	}
	
	.login-mode-nav .login-nav-active label {
		position: relative;
		font-weight: bold;
		color: #333333;
	}
	
	.login-mode-nav .login-nav-active label::after {
		display: block;
		content: " ";
		position: absolute;
		bottom: -9px;
		left: 50%;
		width: 11px;
		height: 3px;
		background-color: #4588ff;
		transform: translateX(-50%);
	}
	
	.login-mode-content {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 110px;
		margin-top: 20px;
	}
	
	.login-mode-content .login-mode-content-mode {
		display: none;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		height: 100%;
		transition: display 1s ease;
		/* background-color: #f14141; */
	}
	
	.login-mode-content-mode .login-content-inpwrap {
		display: flex;
		align-items: center;
		width: 298px;
		height: 43px;
		background: #f3f5f9;
		border-radius: 22px;
		overflow: hidden;
	}
	
	.login-content-inpwrap-icon {
		margin: 0 18px;
		width: 20px;
		height: 20px;
	}
	
	.login-content-inpwrap-icon img {
		width: 100%;
		height: inherit;
	}
	
	.login-content-inpwrap-input {
		flex: 1;
		height: 100%;
		padding-right: 18px;
	}
	
	.login-content-inpwrap-input input {
		border: none;
		width: 100%;
		height: 100%;
		color: #999999;
		background: #f3f5f9;
	}
	
	.login-content-inpwrap-input input::placeholder {
		color: #999999;
	}
	
	.login-content-inpwrap-type {
		margin-right: 18px;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	
	.login-content-inpwrap-type img {
		width: 100%;
		height: inherit;
	}
	
	/* 获取短信验证码 */
	.login-content-inpwrap-sendcode {
		@include noselect;
		position: relative;
		margin-right: 15px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #4588ff;
		line-height: 28px;
		cursor: pointer;
	}
	
	.login-content-inpwrap-sendcode::after {
		display: block;
		position: absolute;
		content: " ";
		left: -10px;
		top: 6px;
		width: 1px;
		height: 20px;
		background: #cccccc;
	}
	
	/* 切换登录方式 */
	/* 兄弟原生样式改变 变灰*/
	.login-nav1:checked~.login-mode-nav li label,
	.login-nav2:checked~.login-mode-nav li label,
	.login-nav3:checked~.login-mode-nav li label,
	.login-nav4:checked~.login-mode-nav li label {
		position: relative;
		font-weight: 500;
		color: #999999;
	}
	
	/*切换登录方式底部文本高亮*/
	.login-nav1:checked~.login-mode-nav li:first-child label,
	.login-nav2:checked~.login-mode-nav li:nth-child(2) label,
	.login-nav3:checked~.login-mode-nav li:nth-child(3) label,
	.login-nav4:checked~.login-mode-nav li:last-child label {
		font-weight: bold;
		color: #333333;
	}
	
	/* 切换登录方式底部伪类样式*/
	.login-nav1:checked~.login-mode-nav li:first-child label::after,
	.login-nav2:checked~.login-mode-nav li:nth-child(2) label::after,
	.login-nav3:checked~.login-mode-nav li:nth-child(3) label::after,
	.login-nav4:checked~.login-mode-nav li:last-child label::after {
		display: block;
		content: " ";
		position: absolute;
		bottom: -9px;
		left: 50%;
		width: 11px;
		height: 3px;
		background-color: #4588ff;
		transform: translateX(-50%);
	}
	
	// /* 登录模板切换 */
	
	// .login-nav1:checked ~ .login-mode-content .login-mode-content-mode,
	// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode,
	// .login-nav3:checked ~ .login-mode-content .login-mode-content-mode,
	// .login-nav4:checked ~ .login-mode-content .login-mode-content-mode {
	//   display: none;
	// }
	// /*手机登录*/
	// .login-nav1:checked ~ .login-mode-content .login-mode-content-mode:first-child ,
	// /*验证码登录*/
	// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:nth-child(2),
	// /*微信登录*/
	// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:nth-child(3),
	// /*扫码登录*/
	// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:last-child {
	//   display: flex;
	// }
	
	#content-mode3,
	#content-mode4 {
		display: none;
	}
	
	.login-tool.content1,
	.login-footer.content1 {
		display: none;
	}
	
	/*操作栏*/
	.login-tool {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 298px;
		height: 40px;
		margin: 0 auto;
		/* background-color: #44f1dd; */
	}
	
	.login-auto {
		display: flex;
		align-items: center;
		height: 12px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #999999;
	}
	
	.login-auto input {
		margin-right: 11px;
	}
	
	.login-aspwd {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #999999;
		cursor: pointer;
	
		.login-forgetpwd {
			color: #333333;
	
			&:hover {
				color: #777777;
			}
		}
	}
	
	.login-scancode {
		color: #4588ff;
		margin-right: 11px;
	}
	
	/* 底部登录按钮行 */
	
	.login-footer {
		padding-top: 21px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 298px;
		margin: 0 auto;
	}
	
	.login-footer-tips {
		/* margin-top: 21px; */
		display: flex;
		align-items: center;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #999999;
	}
	
	.login-footer-tips a {
		margin-left: 5px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		text-decoration: underline;
		color: #4588ff;
		cursor: pointer;
	}
	
	.login-footer-btn {
		@include noselect;
		width: 99px;
		height: 40px;
		line-height: 40px;
		font-size: 15px;
		text-align: center;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #ffffff;
		background: #4588ff;
		border-radius: 20px;
		cursor: pointer;
	}
	
	.login-footer-btn:hover {
		transform: translateY(-1px);
		box-shadow: 0 5px 10px #e6e4e4;
	}
	
	.login-footer-btn:active {
		box-shadow: 0 0 10px #e6e4e4;
	}
</style>
