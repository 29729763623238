<template>
  <div class="netsearch">
    <navigation-bar
      :placeholder="$route.params.val ? $route.params.val : val"
      @search="handleSearch"
    >
      <template #nleft>
        <van-icon
          name="arrow-left"
          @click="onLeft"
          :style="{ 'margin-right': '6px' }"
        />
      </template>
      <template #nclear>
        <img src="../../../assets/images/disk/close.png" alt="" />
      </template>
    </navigation-bar>
    <div class="netsearch-rlt">搜索结果&nbsp;({{ schData.length }})</div>
    <div
      :class="[
        'netsearch-item',
        !schData.length ? 'netsearch-item-default' : '',
      ]"
    >
      <div class="netsearch-item-def" v-if="!schData.length">
        <span>暂无搜索结果，换个词汇试试看吧</span>
      </div>
      <div
        v-for="(item, index) in schData"
        :key="index"
        class="netfile"
        @click="openFile(item)"
      >
        <div class="file-item">
          <div class="file-item-image">
            <img
              :src="require(`@/assets/images/disk/Icons/${item.nub}.png`)"
              alt=""
            />
          </div>
          <div class="file-item-main">
            <h1 class="tit">{{ item.filename }}</h1>
            <div class="detail">
              <span class="detail-time">{{ item.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/disk/NavigationBar.vue";
import { searchList, previewFile, getDiskList } from "api/disk";
import qs from "qs";
export default {
  name: "netfilesearch",
  data() {
    return {
      //搜索结果数据
      // schData: this.$route.params.data ? this.$route.params.data : [],
      schData: [],
      //关键字
      val: "",
    };
  },
  components: {
    NavigationBar,
  },
  created() {
    this.val = this.$route.params.val ? this.$route.params.val : "";
    this.handleSearch(this.val);
  },
  methods: {
    //返回
    onLeft() {
      this.$router.go(-1);
    },
    //搜索
    async handleSearch(val) {
      this.val = val;

      let data = await searchList({ name: val });
      if (data.code == 200) {
        data.data.records.forEach((item) => {
          switch (item.filetype) {
            case 0:
              if (item.isDeleted == -1) {
                item.nub = "01";
              } else {
                item.nub = "03";
              }
              break;
            case 1:
              item.nub = "04";
              break;
            case 2:
              item.nub = "07";
              break;
            case 3:
              item.nub = "08";
              break;
            case 4:
              item.nub = "05";
              break;
            case 5:
              item.nub = "06";
              break;
            case 6:
              item.nub = "07";
              break;
            case 7:
              item.nub = "04";
              break;
            case 8:
              item.nub = "05";
              break;
            case 9:
              item.nub = "14";
              break;
            case 10:
              item.nub = "14";
              break;
            case 11:
              item.nub = "09";
              break;
            case 12:
              item.nub = "09";
              break;
            case 13:
              item.nub = "09";
              break;
            case 14:
              item.nub = "10";
              break;
            case 15:
              item.nub = "01";
              break;
            case 16:
              item.nub = "09";
              break;
            case 17:
              item.nub = "12";
              break;
            case 18:
              item.nub = "12";
              break;
            case 19:
              item.nub = "13";
              break;
            case 20:
              item.nub = "11";
              break;
            case 21:
              item.nub = "15";
              break;
            case 22:
              item.nub = "11";
          }
        });
        this.schData = data.data.records;
      }
    },
    //   获取列表数据
    async getDiskLists(value) {
      let data = await getDiskList(value);
      data.data.forEach((item) => {
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = "01";
            } else {
              item.nub = "03";
            }
            break;
          case 1:
            item.nub = "04";
            break;
          case 2:
            item.nub = "07";
            break;
          case 3:
            item.nub = "08";
            break;
          case 4:
            item.nub = "05";
            break;
          case 5:
            item.nub = "06";
            break;
          case 6:
            item.nub = "07";
            break;
          case 7:
            item.nub = "04";
            break;
          case 8:
            item.nub = "05";
            break;
          case 9:
            item.nub = "14";
            break;
          case 10:
            item.nub = "14";
            break;
          case 11:
            item.nub = "09";
            break;
          case 12:
            item.nub = "09";
            break;
          case 13:
            item.nub = "09";
            break;
          case 14:
            item.nub = "10";
            break;
          case 15:
            item.nub = "01";
            break;
          case 16:
            item.nub = "09";
            break;
          case 17:
            item.nub = "12";
            break;
          case 18:
            item.nub = "12";
            break;
          case 19:
            item.nub = "13";
            break;
          case 20:
            item.nub = "11";
            break;
          case 21:
            item.nub = "15";
            break;
          case 22:
            item.nub = "11";
        }
      });
      this.schData = data.data;
    },
    //打开文件
    openFile(file) {
      if (file.filename.split(".").length > 1) {
        const data = {
          id: file.id,
        };
        previewFile(data).then((res) => {
          if (res.code == 200) {
            this.$router.push({
              name: "redirect",
              query: {
                data: res.data,
              },
            });
          }
        });
      } else {
        this.getDiskLists({
          id: file.id,
          pageIndex: 1,
          pageSize: 10,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-icon {
  color: #666;
  width: 60px;
}
.netsearch {
  font-family: PingFang SC;
  .NavigationBar-searchbox-code {
    display: none;
  }
  #search {
    &::placeholder {
      color: #333;
    }
  }
  .close {
    img {
      width: 32px;
      height: 32px;
    }
  }
  &-rlt {
    padding: 40px 30px;
    font-size: 24px;
    font-weight: 500;
    color: #999999;
  }
  &-item {
    height: calc(100vh - 144px);
    &-def {
      display: flex;
      flex-direction: column-reverse;
      height: 340px;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      color: #999999;
      background: url("../../../assets/images/disk/net-sch-default.png") center
        no-repeat;
      background-size: 278px 224px;
    }
    .netfile {
      padding: 20px 30px;
      .file-item {
        display: flex;
        flex-wrap: nowrap;
        // margin-top: 15px;
        &-image {
          img {
            width: 105px;
            height: 105px;
          }
        }
        &-main {
          position: relative;
          flex: 1;
          margin-left: 30px;
          padding-top: 7px;
          .tit {
            width: 460px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .detail {
            margin-top: 16px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            &-count {
              margin-right: 30px;
            }
          }
        }
      }
    }
  }
  &-item-default {
    display: flex;
    justify-content: center;
    height: calc(100vh - 192px);
    padding-top: 206px;
  }
}
</style>
