<template>
  <div class="shopIndex">
    <!-- 顶部navbar -->
    <van-nav-bar
      :title="goodsData.productName"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <!-- 轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="(item, index) in imgList"
        :key="index"
        @click="(imageShow = true), (index = index)"
      >
        <img
          class="img"
          :src="$store.state.onlineBasePath + item.fileUrl"
          alt=""
        />
      </van-swipe-item>
    </van-swipe>

    <!-- 商品信息 -->
    <div class="shop_info">
      <div class="price_box">
        <div class="price">
          <span class="price_sum">
            ¥{{ price != 0 ? price : goodsData.productPrices }}
          </span>
          <!-- <span class="text"> /月 </span> -->
          <!-- <span>
            <s>¥1999</s>
          </span>
          <van-tag plain type="warning">优惠价</van-tag> -->
        </div>

        <!-- <div class="sold">
          <span class="text"> 已售: 5000份 </span>
        </div> -->
      </div>

      <div class="title_name">{{ goodsData.productName }}</div>

      <div class="provider">
        <div class="text">深圳写手智能科技有限公司</div>
      </div>
    </div>

    <!-- Tab标签 -->
    <van-tabs class="tab" v-model="active">
      <van-tab :title="goodsData.productName + '介绍'">
        <div
          class="abstractHTML"
          @click="showImg($event)"
          v-html="goodsData.productIntroduction"
        ></div>

        <div class="basic_information" v-if="false">
          <div class="process_box">
            <div class="process_title">标准流程与耗时</div>
            <div class="process_list">
              <div class="header">
                <div class="name_header">阶段</div>
                <div class="header_item">
                  <div class="directory_header">目录</div>
                  <div class="needtime_header">耗时</div>
                  <div class="coordinate_header">备注</div>
                </div>
              </div>
              <div
                class="process_item"
                v-for="(item, index) in processList"
                :key="index"
              >
                <div class="name_box">
                  <div class="name_item">
                    {{ item.name }}
                  </div>
                  <div class="item_name">
                    <div
                      class="children"
                      v-for="(children, index1) in item.children"
                      :key="index1"
                    >
                      <div class="name">
                        {{ children.name }}
                      </div>
                      <div class="needtime">
                        {{ children.needtime }}
                      </div>
                      <div class="coordinate">
                        {{ children.coordinate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          v-if="false"
          class="img"
          :src="$store.state.onlineBasePath + goodsData.productIntroduction"
          alt=""
        />
      </van-tab>
      <van-tab title="知识博主简介" v-if="false">
        <div class="abstract">
          <div
            class="findPromoter"
            v-for="(item, index) in dialogUndertakerList"
            :key="index"
          >
            <div class="userImg">
              <img
                :src="
                  item.headImg
                    ? item.headImg
                    : require('assets/images/disk/headportrait.png')
                "
                @error="imgError(item)"
                alt=""
              />
            </div>
            <div class="name">
              {{ item.nickName }}
            </div>
            <!-- <div class="price">{{ item.payPrice }}</div> -->
            <div class="occupation">
              {{ item.occupation }}
            </div>
            <div class="introduction">
              {{ item.introduction }}
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="'用户评论(' + commentList.length + ')'">
        <div class="comments_box" v-if="commentList.length != 0">
          <div
            class="user_comments"
            v-for="(item, index) in commentList"
            :key="index"
          >
            <div class="comments_top">
              <div class="img">
                <img
                  :src="
                    item.userheadImg
                      ? item.userheadImg
                      : require('assets/images/disk/headportrait.png')
                  "
                  @error="userheadImg(item)"
                />

                <div class="user_info">
                  <div class="name">{{ item.nickName }}</div>
                  <div class="score">
                    <van-rate
                      readonly
                      v-model="item.score"
                      :size="25"
                      color="#ffd21e"
                      void-icon="star"
                      void-color="#eee"
                    />
                  </div>
                </div>
              </div>
              <div class="time_box">{{ item.createTime }}</div>
            </div>

            <div class="comments_content">
              <div class="text">
                {{ item.content }}
              </div>
            </div>

            <div class="img_box" v-if="item.evaluateImgs">
              <img
                v-for="(item1, index1) in item.evaluateImgs"
                :key="index1"
                :src="$store.state.onlineBasePath + item1"
                alt=""
              />
            </div>

            <div class="operation_box">
              <div class="operation">
                <span> 举报 </span>
                <span>
                  <van-icon v-if="good" name="good-job-o" />
                  <van-icon v-else class="red" name="good-job" />赞(5)
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="none_comments">
          <img
            :src="require('assets/images/disk/net-sch-default.png')"
            alt=""
          />
          暂无评论
        </div>
      </van-tab>
    </van-tabs>

    <!-- 提交订单栏 -->
    <van-submit-bar
      :decimal-length="2"
      class="submit_bar"
      :price="(price != 0 ? price : goodsData.productPrices) * 100"
      button-text="提交订单"
      @submit="onSubmit"
    >
      <div class="icon_box" @click="lick">
        <van-icon v-show="!isLike" class="red" name="like" />
        <van-icon v-show="isLike" name="like-o" />收藏
      </div>
    </van-submit-bar>

    <!-- 商品规格 -->
    <van-popup v-model="show" round position="bottom" class="popup">
      <div class="specifications_box">
        <div class="top_box">
          <img
            :src="
              imgList
                ? $store.state.onlineBasePath + imgList[0].fileUrl
                : require('assets/images/no-shop-img100.png')
            "
            alt=""
          />
          <div class="price_box">
            <div class="price_sum">
              ¥ <span>{{ price != 0 ? price : "0" }}</span>
            </div>
            <div class="the_selected">
              {{ undertaker.name ? "已选:" + undertaker.name : "选择执行律师" }}
            </div>
          </div>
        </div>

        <div class="content_box">
          <div class="name_text">
            <span>选择阶段流程&nbsp;</span>
            <span class="span">(&nbsp;多选&nbsp;)</span>
          </div>

          <div class="specifications_item">
            <div
              :class="item.child ? 'yellow' : ''"
              class="item"
              @click.prevent="handleClick(item, index)"
              v-for="(item, index) in processList"
              :key="index"
            >
              <span> {{ item.name }}</span>

              <!-- <span> {{ item.needtime }} 个工作时</span> -->
            </div>
          </div>

          <div class="name_text" v-if="goodsData.productName != '版权诉讼指引'">
            选择地域&nbsp;<span class="span">(&nbsp;地区&nbsp;)</span>
          </div>
          <div
            class="specifications_item"
            v-if="goodsData.productName != '版权诉讼指引'"
          >
            <div
              :class="regionIndex == i ? 'yellow' : ''"
              class="item"
              v-for="(v, i) in regionList"
              :key="i"
              @click="handleRegionClick(v, i)"
            >
              <span> {{ v.cityName }}</span>
            </div>
          </div>

          <div class="name_text" v-if="goodsData.productName != '版权诉讼指引'">
            选择执行律师&nbsp;<span class="span"
              >(&nbsp;函件文稿辅导团队&nbsp;)</span
            >
          </div>

          <div class="dialogUndertakerList_item">
            <div
              :class="levelIndex == index ? 'yellow' : ''"
              class="findPromoter"
              v-for="(item, index) in dialogUndertakerList"
              :key="index"
              @click="handleUndertakerClick(item, index)"
            >
              <div class="name">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="bottom_box">
          <!-- <div class="text_prompt">立即下单，畅想会员权益</div> -->
          <van-button @click="onBuyClicked" class="btn" round type="info"
            >立即购买</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 支付扫码弹窗 -->
    <van-dialog
      @close="handleClosePaymentPopup"
      v-model="paymentPopup"
      title="扫码支付"
      :show-cancel-button="false"
    >
      <div class="text">请使用微信扫码下方二维码完成支付</div>

      <div class="img_box">
        <img class="img" v-if="qrCodeurl" :src="qrCodeurl.codeImg" />
        <img
          class="img"
          v-else
          :src="require('assets/images/showslide3.jpg')"
          alt=""
        />
      </div>
      <div class="payment">
        应付款： <span>¥ {{ price != 0 ? price : "0" }}</span>
      </div>
    </van-dialog>

    <!-- 图片预览 -->
    <van-image-preview v-model="imageShow" :images="jointImgList">
      <template v-slot:index> </template>
    </van-image-preview>

    <!-- 富文本图片放大区域 -->
    <div
      class="imgDolg"
      v-show="imgPreview.show"
      @click.stop="imgPreview.show = false"
    >
      <i
        class="el-icon-close"
        id="imgDolgClose"
        @click.stop="imgPreview.show = false"
      ></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
  </div>
</template>

<script>
import {
  searchFile,
  searchID,
  FindProcess,
  FindPromoter,
  commentList,
  addOrDel,
  payOtherOrderPay,
  AddCopyrightPractice,
  PaywechatOrderQueryQrcode,
} from "api/shop";
export default {
  data() {
    return {
      // 图片预览数组
      jointImgList: [],
      // 轮播图
      index: 0,
      // 图片预览
      imageShow: false,
      // 点赞
      good: false,
      //评分
      value: 5,
      // 商品id
      goodsId: this.$route.params.val,
      // 轮播图
      imgList: [],
      // 商品详情数据
      goodsData: {},
      // Tab标签
      active: 0,
      // 收藏
      isLike: false,
      // 规格弹窗
      show: false,
      // 选择承办人员索引
      levelIndex: -1,
      // 选择地区
      regionIndex: -1,
      // 流程数组
      processList: [],
      // 选中流程id数组
      projectdetailIds: [],
      // 获取版权实务承办人
      dialogUndertakerList: [],
      // 选中承办人
      undertaker: "",
      // 工时
      needtime: 0,
      // 价格
      price: 0,
      // 评论列表
      commentList: [],
      // 扫码弹窗
      paymentPopup: false,
      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
      // 地区数组
      regionList: [],
      // 图片放大
      imgPreview: {
        img: "",
        show: false,
      },
    };
  },
  methods: {
    // 图片点击放大
    showImg(e) {
      // console.log(e.target)
      if (e.target.tagName == "IMG") {
        this.imgPreview.img = e.target.src;
        this.imgPreview.show = true;
      }
    },

    // 选择地区
    handleRegionClick(v, i) {
      this.dialogUndertakerList = v.lawyerUserList;
      this.regionIndex = i;
      this.levelIndex = -1;
      this.undertaker = "";
    },
    // 图片容错
    imgError(item) {
      item.headImg = require("assets/images/disk/headportrait.png");
    },
    userheadImg(item) {
      item.userheadImg = require("assets/images/disk/headportrait.png");
    },
    //返回
    onClickLeft() {
      if (window.android) {
        const jsonStr = {
          action: "back",
        };
        window.android.postMessage(JSON.stringify(jsonStr));
      } else if (window.webkit) {
        const jsonStr = {
          action: "back",
        };
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(jsonStr)
        );
      }
    },
    //提交订单
    onSubmit() {
      this.show = true;
    },
    // 立即购买
    onBuyClicked() {
      if (this.goodsData.productName != "版权诉讼指引") {
        if (this.price == 0) {
          return this.$toast({
            message: "请选择流程!",
            className: "toastStyle",
          });
        }
        if (this.regionIndex == -1) {
          return this.$toast({
            message: "请选择地区!",
            className: "toastStyle",
          });
        }
        if (this.undertaker == "") {
          return this.$toast({
            message: "请选择执行律师 !",
            className: "toastStyle",
          });
        }
      } else {
        if (this.price == 0) {
          return this.$toast({
            message: "请选择流程!",
            className: "toastStyle",
          });
        }
      }
      this.show = false;
      this.addCopyrightPractice();
    },
    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      window.clearInterval(this.timer);
    },
    // 承办人
    handleUndertakerClick(item, i) {
      this.levelIndex = i;
      this.undertaker = item;
    },

    // 选择
    handleClick(v, i) {
      v.child = !v.child;
      let sum = 0;
      let arr = [];
      this.processList.forEach((item) => {
        if (item.child) {
          arr.push(item.projectdetailId);
          sum += item.price;
        }
      });
      this.projectdetailIds = arr;
      this.needtime = sum;
      this.price = sum;
    },

    // 商品详情数据
    async goodsdata(id) {
      const params = {
        productId: id,
      };
      // form.append("");
      let res = await searchID(params);
      // console.log(res);
      if (res.code === 200) {
        this.goodsData = res.data;
        if (this.goodsData.collectionStatus) {
          this.isLike = true;
        } else {
          this.isLike = false;
        }
      } else {
        this.$toast({
          message: res.message,
          className: "toastStyle",
        });
      }
    },

    // 商品图片
    async goodsfils(id) {
      const data = {
        productId: id,
      };
      // JSON.stringify(data)
      const res = await searchFile(data);
      // console.log(res);
      if (res.code === 200) {
        this.imgList = res.data;
        this.jointImgList = res.data.map((item) => {
          return this.$store.state.onlineBasePath + item.fileUrl;
        });
      } else {
        this.$toast({
          message: res.message,
          className: "toastStyle",
        });
      }
    },

    // 获取版权实务规格
    async findProcess(id) {
      let form = new FormData();
      form.append("productId", id);

      const res = await FindProcess(form);
      if (res.code == 200) {
        this.processList = res.data.projectdetailList.map((item) => {
          // console.log(item);
          item.child = false;
          return item;
        });
        this.regionList = res.data.areaUserVos;
      } else {
        this.$toast({
          message: res.message,
          className: "toastStyle",
        });
      }
    },

    // 获取版权实务承办人
    async findPromoter(id, sort, sortType) {
      const data = {
        productId: id,
        limit: 100,
        page: this.undertakerPageNum,
        sort,
        sortType,
      };

      const res = await FindPromoter(data);
      if (res.code == 200) {
        this.dialogUndertakerList = res.data.data;
      } else {
        this.$toast({
          message: res.message,
          className: "toastStyle",
        });
      }
    },

    //获取评论列表
    async getcommentList(id) {
      let data = {
        productId: id,
      };
      let res = await commentList(data);
      if (res.code == 200) {
        this.commentList = res.data;
      } else {
        this.$toast({
          message: res.message,
          className: "toastStyle",
        });
      }
    },

    //收藏商品
    async lick() {
      let data = {
        productId: this.goodsId,
      };
      this.isLike = !this.isLike;
      let res = await addOrDel(data);
      if (res.code == 200) {
        this.$notify({
          type: "success",
          message: !this.isLike ? "收藏成功" : "取消收藏",
          className: "notifyStyle",
        });
      }
    },

    // 新增版权实务订单
    async addCopyrightPractice() {
      const data = {
        productId: this.goodsId,
        projectdetailIds: this.projectdetailIds,
        handleId: this.undertaker.userId,
      };

      const res = await AddCopyrightPractice(data);
      if (res.code === 200) {
        if (window.android) {
          const jsonStr = {
            action: "orderPay",
            data: res.data,
          };
          window.android.postMessage(JSON.stringify(jsonStr));
        } else if (window.webkit) {
          const jsonStr = {
            action: "orderPay",
            data: res.data,
          };
          window.webkit.messageHandlers.sendMessageForVue.postMessage(
            JSON.stringify(jsonStr)
          );
        }

        // this.QrCode(res.data);
      } else {
        this.$toast({
          message: res.message,
          className: "toastStyle",
        });
      }
    },

    //授权生成支付二维码
    // async QrCode(oid) {
    //   let that = this;
    //   const data = {
    //     oid,
    //   };
    //   const res = await payOtherOrderPay(data);
    //   if (res.code === 200) {
    //     this.paymentPopup = true;
    //     // console.log(res.data);
    //     this.qrCodeurl = res.data;
    //     this.timer = window.setInterval(function () {
    //       that.wechatOrderQueryQrcode(oid);
    //     }, 1000);
    //   } else {
    //     this.$toast({
    //       message: res.message,
    //       className: "toastStyle",
    //     });
    //   }
    // },

    // 调用微信支付的查询订单接口
    async wechatOrderQueryQrcode(oid) {
      const data = {
        oid,
      };
      const res = await PaywechatOrderQueryQrcode(data);
      if (res.code == 200) {
        window.clearInterval(this.timer);
        if (this.goodsData.productType == 4) {
          this.$router.push({
            name: "AgencyEntrustment",
          });
        }
      }
    },
  },
  created() {
    this.getcommentList(this.goodsId);
    // this.findPromoter(this.goodsId);
    this.findProcess(this.goodsId);
    this.goodsfils(this.goodsId);
    this.goodsdata(this.goodsId);
  },
  beforeDestroy() {
    //页面销毁前，清除定时器
    window.clearInterval(this.timer); //清除timer定时器
  },
};
</script>

<style>
/* 轻提示样式 */
.toastStyle {
  font-size: 28px;
  width: 192px;
  height: 72px;
  line-height: 1.5;
}
</style>
<style>
/* 消息提示 */
.notifyStyle {
  width: 100% !important;
  font-size: 28px;
  width: 192px;
  height: 72px;
}
</style>

<style lang="scss" scoped>
.shopIndex {
  padding-bottom: 100px;
  background-color: #f8f8f8;
  ::v-deep .van-nav-bar {
    .van-nav-bar__content {
      height: 100px;
    }
    .van-nav-bar__title {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .van-ellipsis {
      overflow: visible;
    }
    .van-nav-bar__text {
      color: #999999;
      font-size: 30px;
    }
    .van-icon {
      color: #333333;
    }
  }

  // 轮播图
  .my-swipe {
    .van-swipe-item {
      height: 600px;
      .img {
        height: 100%;
        width: 100%;
      }
    }

    ::v-deep .van-swipe__indicator {
      height: 18px;
      width: 18px;
      background-color: #bebebe;
    }
  }

  // 商品信息
  .shop_info {
    background-color: #fff;
    padding: 40px 20px;
    .price_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        display: flex;
        align-items: baseline;
        .price_sum {
          font-size: 46px;
          font-weight: bold;
          color: #eb441e;
        }

        .text {
          color: #eb441e;
          font-weight: 800;
          font-size: 30px;
        }

        span {
          s {
            font-size: 24px;
            font-weight: 400;
            margin: 0 20px;
            color: #999999;
          }
        }

        .van-tag {
          padding: 8px;
        }
      }

      .sold {
        .text {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .title_name {
      margin: 20px 0;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }

    .provider {
      .text {
        font-size: 26px;
        font-weight: 500;
        color: #4587ff;
      }
    }
  }

  // tab 标签
  .tab {
    margin-top: 20px;
    ::v-deep .van-tabs__wrap {
      height: 100px;
    }
    ::v-deep .van-tab {
      font-size: 28px;
      .van-tab__text--ellipsis {
        overflow: visible;
      }
    }
    ::v-deep .van-tab--active {
      color: #4587ff;
    }
    ::v-deep .van-tabs__line {
      height: 6px;
      background-color: #4587ff;
    }

    // 文稿服务介绍
    .basic_information {
      background-color: #fff;
      .process_box {
        background-color: #f7f7f7;
      }

      .process_title {
        text-align: center;
        padding: 50px 0;
        font-weight: 800;
        font-size: 30px;
      }

      .process_list {
        margin: 0 auto;
        padding: 20px;
        box-sizing: border-box;

        .header {
          font-weight: 800;
          font-size: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid #ccc;
          border-top: 1px solid #ccc;
          border-right: 2px solid #ccc;

          .name_header {
            text-align: center;
            width: 70px;
          }

          .header_item {
            display: flex;
            flex: 1;
            .directory_header {
              width: 160px;
              padding: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-left: 2px solid #ccc;
            }

            .needtime_header {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 60px;
              padding: 20px;
              border-left: 2px solid #ccc;
              border-right: 2px solid #ccc;
            }

            .coordinate_header {
              display: flex;
              justify-content: center;
              padding: 0 0 0 20px;
              align-items: center;
              flex: 1;
            }
          }
        }

        .process_item {
          box-sizing: border-box;
          font-size: 26px;
          border-left: 1px solid #ccc;

          &:last-child {
            border-bottom: 1px solid #ccc;
          }

          .name_box {
            border-top: 1px solid #ccc;
            display: flex;
            align-items: center;
            .name_item {
              width: 70px;
              text-align: center;
            }

            .item_name {
              flex: 1;

              .children {
                display: flex;
                border-bottom: 2px solid #ccc;
                &:last-child {
                  border-bottom: 0px solid #ccc;
                }
                .name {
                  border-left: 2px solid #ccc;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 20px;
                  width: 160px;
                }

                .needtime {
                  border-left: 2px solid #ccc;
                  border-right: 2px solid #ccc;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 20px;
                  width: 60px;
                }

                .coordinate {
                  border-right: 2px solid #ccc;
                  line-height: 1.5;
                  padding: 20px;
                  flex: 1;
                }
              }
            }
          }
        }
      }
    }

    .img {
      width: 100%;
    }
    .abstractHTML {
      padding: 30px;
      margin: 0 auto;
      text-align: justify;
      text-justify: distribute-all-lines;
    }

    // 知识博主简介
    .abstract {
      background-color: #fff;
      padding: 20px;
      .findPromoter {
        font-size: 28px;
        display: flex;
        align-items: center;

        padding: 20px 0;

        .userImg {
          margin-right: 20px;
          img {
            border-radius: 50%;
            width: 60px;
            height: 60px;
          }
        }

        .name {
          width: 120px;
        }

        .price {
          text-align: center;
          width: 200px;
        }

        .occupation {
          text-align: center;
          width: 150px;
        }
        .introduction {
          flex: 1;
          text-align: center;
        }
      }
    }

    // 用户评论
    .comments_box {
      background-color: #fff;
      padding: 20px;
      .user_comments {
        font-size: 26px;
        margin-bottom: 20px;
        border-bottom: 1px solid #f5f5f5;

        .comments_top {
          display: flex;
          justify-content: space-between;
          .img {
            display: flex;
            align-items: center;
            img {
              width: 70px;
              height: 70px;
              border-radius: 50%;
              margin-right: 20px;
            }

            .user_info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .name {
                font-weight: bold;
              }

              .score {
                ::v-deep .van-rate {
                  .van-rate__item {
                    .van-icon {
                      font-size: 18px !important;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }

          .time_box {
            font-size: 22px;
            color: #999999;
          }
        }

        .comments_content {
          margin: 20px 0;
          .text {
            line-height: 1.5;
          }
        }

        .img_box {
          img {
            margin-right: 20px;
            margin-bottom: 20px;
            width: 215.9px;
            height: 215.9px;
          }
        }

        .operation_box {
          display: flex;
          flex-direction: row-reverse;
          color: #999;
          margin-bottom: 20px;

          .operation {
            display: flex;
            align-items: center;
            span {
              &:nth-child(2) {
                display: flex;
                align-items: center;
                margin-left: 20px;

                .van-icon {
                  font-size: 40px;
                  margin-right: 10px;
                }

                .red {
                  color: #eb441e;
                }
              }
            }
          }
        }
      }
    }

    .none_comments {
      background-color: #fff;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #ccc;
      font-size: 28px;
      img {
        margin: 0 auto;
        margin-bottom: 10px;
        width: 200px;
        height: 200px;
      }
    }
  }

  // 提交订单栏
  .submit_bar {
    height: 98px;
    box-shadow: 0px -6px 27px 0px rgba(196, 196, 196, 0.28);
    .van-submit-bar__bar {
      height: 100%;

      .van-submit-bar__text {
        .van-submit-bar__price {
          font-size: 24px;

          .van-submit-bar__price--integer {
            font-size: 40px;
          }
        }
      }

      .van-button {
        width: 220px;
        height: 80px;
        font-size: 28px;
        font-weight: 500;
      }
    }
    .icon_box {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;

      .van-icon {
        font-size: 40px;
      }

      .red {
        color: #eb441e;
      }
    }
  }

  // 商品规格
  .popup {
    .specifications_box {
      position: relative;
      box-sizing: border-box;
      padding: 40px;

      .top_box {
        display: flex;
        padding-bottom: 40px;
        border-bottom: 2px solid #f5f5f5;

        img {
          width: 110px;
          height: 110px;
          margin-right: 40px;
        }

        .price_box {
          width: 100%;
          display: flex;
          justify-content: space-between;

          flex-direction: column;

          .price_sum {
            color: #eb441e;
            font-size: 24px;
            span {
              font-size: 40px;
            }
          }

          .the_selected {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 30px;
            color: #999;
          }
        }
      }

      .content_box {
        height: 700px;
        &::-webkit-scrollbar {
          display: none;
        }
        overflow-x: hidden;
        overflow-y: scroll;
        .name_text {
          padding-right: 10px;
          display: flex;
          margin: 40px 0;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;

          .span {
            color: #ccc;
          }
        }

        .specifications_item {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid #f5f5f5;
          padding-bottom: 40px;
          .item {
            padding: 20px;
            display: flex;

            span {
              flex: 1;
              text-align: center;
            }
          }

          .item {
            box-sizing: border-box;

            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            font-size: 28px;
            background-color: #f5f5f5;
          }

          .yellow {
            color: #ff5001;
            border: 2px solid #ff5001;
          }
        }

        .dialogUndertakerList_item {
          display: flex;
          flex-wrap: wrap;
          .yellow {
            color: #ff5001;
            border: 2px solid #ff5001;
          }
          .findPromoter {
            display: flex;
            align-items: center;
            font-size: 28px;
            padding: 20px;
            margin: 10px;
            background-color: #f5f5f5;

            .userImg {
              margin-right: 20px;
              img {
                border-radius: 50%;
                width: 60px;
                height: 60px;
              }
            }

            .price {
              text-align: center;
              flex: 1;
              margin: 0 20px;
            }

            .occupation {
              width: 200px;
            }
          }
        }
      }

      .bottom_box {
        .text_prompt {
          text-align: center;
          margin-top: 80px;
          padding-bottom: 20px;
          border-bottom: 2px solid #f5f5f5;
          color: #ccc;
          font-size: 26px;
        }

        .btn {
          font-size: 30px;
          height: 80px;
          width: 100%;
          margin: 40px 0 0 0;
          color: #fff;
          background-color: #ff5001;
          border: 0;
        }
      }
    }
  }

  // 扫码支付弹窗
  .van-dialog {
    width: 80%;
    ::v-deep .van-dialog__header {
      font-size: 28px;
      font-weight: 800;
      height: 50px;
      line-height: 50px;
    }

    ::v-deep .van-dialog__footer {
      .van-button {
        height: 80px;
        font-size: 30px;
      }
    }

    .text {
      margin-top: 20px;
      text-align: center;
    }
    .img_box {
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        margin: 20px auto;
        width: 90%;
      }
    }

    .payment {
      text-align: center;
      margin-bottom: 20px;
      font-size: 28px;
      span {
        font-weight: 800;
        color: red;
      }
    }
  }
}
</style>

<style lang="scss">
img {
  width: 100%;
  height: auto;
}

p {
  line-height: 1.8;
}

.imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(140, 134, 134, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 35px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img {
    width: 80%;
  }
}
</style>
