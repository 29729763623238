import vue from "vue";
import {
  Toast,
  Icon,
  List,
  Form,
  Button,
  Field,
  Tab,
  Tabs,
  NavBar,
  Col,
  ImagePreview,
  Row,
  Dialog,
  Popup,
  Loading,
  RadioGroup,
  Radio,
  Overlay,
  Divider,
  Uploader,
  PullRefresh,
  Badge,
  Empty,
  Popover,
  Search,
  ShareSheet,
  Image as VanImage,
  Swipe,
  SwipeItem,
  Tag,
  SubmitBar,
  Sku,
  progress,
  Cell,
  Collapse,
  CollapseItem,
  Steps,
  Step,
  Notify,
  Rate,
  Calendar,
} from "vant";

vue
  .use(Calendar)
  .use(Notify)
  .use(Rate)
  .use(Sku)
  .use(Steps)
  .use(Step)
  .use(Cell)
  .use(Collapse)
  .use(CollapseItem)
  .use(SubmitBar)
  .use(Tag)
  .use(Swipe)
  .use(SwipeItem)
  .use(Toast)
  .use(progress)
  .use(Loading)
  .use(Icon)
  .use(ShareSheet)
  .use(List)
  .use(Badge)
  .use(Search)
  .use(Empty)
  .use(Form)
  .use(Button)
  .use(Field)
  .use(Dialog)
  .use(ImagePreview)
  .use(VanImage)
  .use(Tab)
  .use(Divider)
  .use(Tabs)
  .use(Overlay)
  .use(NavBar)
  .use(Col)
  .use(Row)
  .use(Radio)
  .use(RadioGroup)
  .use(Popup)
  .use(Uploader)
  .use(NavBar)
  .use(PullRefresh)
  .use(Popover);
