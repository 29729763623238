<template>
  <div class="location">
    <van-nav-bar
      class="van-bar"
      @click-right="onArrow"
      right-text="返回"
      title="用户协议"
    >
    </van-nav-bar>
    <div class="Agreement">
      <p>
        感谢您选择反抄袭（“本软件”）！本“软件”受中华人民共和国著作权法及国际著作权条约和其它知识产权法和条约的保护。本“软件”权利只许可使用，而不出售。在您使用本软件前，请您仔细阅读和理解此反抄袭许可协议（“本《协议》”）中规定的所有权利和限制（如果您获得的软件是反抄袭专业版、反抄袭政务专版、反抄袭教育版等机构用户中文版本，请至网址http://antiplagiarize.com/阅读其最终用户许可协议）。我们在此善意地提醒您，本协议中与您的权益（可能）存在重大关系的条款，我们已采用字体加粗的方式来特别提示，请您留意重点查阅。请您在确认充分阅读本协议字体加粗条款，明确理解了该等字体加粗条款的前提下使用本软件。如果您对本协议及/或本软件有任何问题、疑惑，请随时联系我们，我们为您提供了多种反馈渠道，希望为您提供满意的解决方案：
      </p>
      <p>
        1.人工客服通道：您可以在工作日的工作时间（9:00-18:00）拨打我们的客服热线：0755-23345494；13828584514
      </p>
      <p>
        2.专设的邮件通道：writemall@126.com邮箱，您可以通过发送电子邮件至该邮箱与我们联系；
      </p>
      <p>
        本《协议》是您与反抄袭之间有关本“软件”的法律协议。本“软件”包括随附的计算机软件，并可能包括计算机软件相关载体、相关文档电子或印刷材料。除非另附单独的最终用户许可协议或使用条件说明，否则本“软件”还包括在您获得本“软件”后由反抄袭不时有选择所提供的任何本“软件”升级版本、修正程序、修订、附加成分和补充内容。请您知悉，除非或直至您接受本《协议》的全部条款，否则您不得将本“软件”安装在任何计算机上。如果您因年龄、智力等因素而不具有完全民事行为能力，请在父母或其他监护人的陪同下阅读和判断是否同意本《协议》。
      </p>
      <p>本《协议》将帮助您了解以下内容：</p>
      <p>一. 术语解释</p>
      <p>二. 您的权利</p>
      <p>三. 您的义务</p>
      <p>四. 本“软件”的替换、修改和升级</p>
      <p>五. 本“软件”的著作权及其他知识产权</p>
      <p>六. 广告</p>
      <p>七. 不允诺担保</p>
      <p>八. 责任有限</p>
      <p>九. 许可终止</p>
      <p>十.用户行为规范</p>

      <p>十一.不正当竞争</p>

      <p>十二.使用限制</p>

      <p>十三.知识产权</p>

      <p>十四.收费服务</p>

      <p>十五.收益与提现</p>

      <p>十六.个人隐私</p>

      <p>十七.适用、管辖法律及条款效力</p>

      <p>十八. 特别提示</p>

      <p>十九. 其他</p>
      <h2>一．术语解释</h2>
      <p>以下术语的解释是为了您更加清晰地了解本协议的内容：</p>
      <p>
        1.“反抄袭”或“我们”：是指深圳写手智能科技有限公司及/或其直接或间接持股50%以上的公司。具体到本协议提及的每个“反抄袭”是指哪一/些公司，深圳写手智能科技有限公司将结合您的具体情况判断确定。
      </p>
      <p>2.“您”或“用户”：指享有合法授权，使用本软件的自然人用户。</p>
      <p>
        3.“中国大陆地区”：仅为本协议之目的，指中华人民共和国范围内香港特别行政区、澳门特别行政区以及台湾地区以外的其他地区。
      </p>
      <h2>二．您的权利</h2>
      <p>
        1.在您接受本《协议》各项条款约束的前提下，反抄袭将本"软件"中国大陆地区的非专有的使用权授予您。您可以：
      </p>
      <p>
        在本台计算机上安装、使用、显示、运行（“运行”）本“软件”。本“软件”为个人版软件，仅供个人所有计算机使用，不得用于法人或其他组织（包括但不限于政府机关、公司、企事业单位、其他组织等；无论该组织是否为经济性组织；无论该组织的使用是否构成商业目的使用）所有计算机。如若个人所有计算机长期固定为法人或其他组织服务，则将被视为“法人或其他组织所有计算机”，无法享受本授权。任何超出上述授权范围的使用均被视为非法复制的盗版行为，反抄袭保留权利要求相关责任人承担相应的法律责任包括但不限于民事责任、行政责任、刑事责任。
      </p>
      <p>2.就您获得合法使用权的本“软件”，反抄袭将向您提供以下支持服务：</p>
      <p>
        2.1.如在本“软件”官网、附随用户手册或其它文档电子或印刷材料（如有）中，反抄袭明示将为您提供支持服务，则反抄袭将依照该官网、用户手册或其它文档电子或印刷材料（如有）中所述的各项政策和计划为您提供与本“软件”有关的支持服务（“支持服务”）。
      </p>
      <p>
        2.2提供给您作为支持服务（如有）的一部分的任何附加软件代码（如有）应被视为本“软件”的一部分，并须遵照本《协议》中的各项条款。
      </p>
      <h2>三．您的义务</h2>
      <p>非经反抄袭书面同意，您不得：</p>
      <p>
        1.
        在本《协议》规定的条款之外，使用、复制、修改本“软件”或其中的任一部分或以任何方式转让或向第三方提供（包括但不限于有偿、无偿、单独、与其他软、硬件一并转让）本“软件”或其中的任一部分。
      </p>

      <p>2.在本台计算机以外的其他设备上安装本“软件”。</p>
      <p>3.分拆本“软件”，将本“软件”各组件分开放在超过一台计算机上使用。</p>
      <p>
        4.对本“软件”进行反向工程、反向编译或反汇编；以及试图进行任何获得本“软件”源代码的访问或行为。
      </p>
      <p>5.发行、出租、信息网络传播、翻译本“软件”。</p>
      <p>6.删除本“软件”上包含的版权标识。</p>
      <h2>四．本“软件”的替换、修改和升级</h2>
      <p>
        1.为了增进用户体验、完善服务内容，反抄袭将不断努力开发本“软件”的新功能，并不时为您提供本“软件”更新（这些更新可能会采取软件替换、修改、版本升级等形式）。
      </p>

      <p>
        2.反抄袭将随时通过提前向您公告（包括但不限于本“软件”客户端弹出页面、您点击本“软件”客户端“检查更新”按钮后的显示或本“软件”官方网站）的方式为您提供本“软件”的更新版本以替换、修改或使本“软件”升级。
      </p>

      <p>
        3.本“软件”更新版本发布后，反抄袭可能不会再向旧版本软件持续提供支持服务，为了保障您的使用体验及服务感受，我们建议您随时核对并及时下载本“软件”的最新版本。
      </p>

      <p>
        4.
        您可自行选择接受（包括您设定对本“软件”自动安装更新的情况）反抄袭为您提供的本“软件”的更新版本以替换、修改或升级本“软件”。如您不接受本“软件”的更新版本，请您不要下载、安装、使用本“软件”的更新版本，一旦您下载本“软件”的更新版本，则表明（a）您同意接受本“软件”的更新版本，并认可更新版本不一定包括旧版本（指本“软件”的更新版本发布之前的版本）的全部功能，反抄袭保留因业务发展需要，单方面对本“软件”的部分功能效果进行改变或进行限制的权利；且（b）您承诺已满足且同意以下本“软件”更新版本的全部使用条件：（1）更新版本须受到本《协议》条款的制约，若本《协议》被随附更新版本的另外一份《协议》取代（“取代协议”），则更新版本须受该取代协议制约；（2）您对本“软件”的旧版本的使用权将被终止。
      </p>
      <h2>五．本“软件”的著作权及其他知识产权</h2>
      <p>
        1.本“软件”（包括但不限于本“软件”中所含的任何源代码、图像、照片、动画、录像、录音、音乐、文字和附加程序）、随附的文档电子或印刷材料及本“软件”任何副本的著作权及其他知识产权，均由反抄袭所有或享有合法授权。
      </p>
      <p>
        2.本“软件”及文档电子或印刷材料享有版权，并受中华人民共和国著作权法及国际条约条款的保护，请不要随意复制。
      </p>
      <h2>六.广告</h2>
      <p>鉴于本“软件”使用许可授权免费向您提供，故</p>
      <p>
        1）您认可在任何时候反抄袭均有权利用本“软件”向您展示广告或推销，包括但不限于利用本“软件”为任何第三方发布广告、推广第三方产品及服务或者针对反抄袭自身产品及服务发布广告或进行推广等。您同意对反抄袭在任何时候利用本“软件”获取的任何利益（包括但不限于为任何第三方发布广告、推广第三方产品及服务而获得的收入），您均不享有任何权利，反抄袭在任何时候因本“软件”获取的任何利益均全部归属于反抄袭所有。
      </p>

      <p>
        2）反抄袭利用本“软件”展示、发布广告、进行推销、推广的形式（包括但不限于在本“软件”启动界面展示第三方广告等）、模式和范围可不经向您特别通知而变更。
      </p>

      <p>
        3）您应意识到本“软件”上展示给您的内容，包括但不限于本“软件”中的广告及推广服务中展示的内容，可能受知识产权的保护。请您不要修改、租赁、出租、借贷、出售、分发该内容（无论全部还是部分）或根据该内容创作衍生作品，除非反抄袭或内容权利人在相关协议、相关业务规则中特别告知您可以为之。
      </p>

      <p>
        4）您于本“软件”或经由本“软件”与广告商进行通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及前述交易其他任何相关条款、条件、保证或声明，是您与前述广告商之间自主决定的行为，请您仔细阅读广告内容、谨慎交易
      </p>
      <h2>七．不允诺担保</h2>
      <p>
        本《协议》所述明示担保，为担保的全部内容。在适用法律所允许的最大范围内，反抄袭及其供应商提供的本“软件”和支持服务（如有）均为按现状及包含所有瑕疵状况下提供，不允诺其它不论是明示的、暗示的还是法定的任何保证和担保：包括但不限于本“软件”对特殊应用目的适销性或适应性，反应的精确性，结果的完整性，不含漏洞或瑕疵。
      </p>
      <h2>八．责任有限</h2>
      <p>
        1.在适用法律所允许的最大范围内，反抄袭或其供应商不就您因使用或不能使用本“软件”所引起的或有关的任何间接的、意外的、直接的、特殊的、惩罚性的或其它任何损害（包括但不限于财产损坏而造成的损害，因利润损失、数据损失、营业中断、计算机瘫痪或故障、商业信息的遗失而造成的损害，因疏忽而造成的损害，或因任何金钱上的损失或任何其它损失而造成的损害）承担赔偿责任
        ,但因反抄袭或其供应商的故意或重大过失造成您的财产损失的除外。
      </p>
      <p>
        2.用户如果与反抄袭的其它用户联系、沟通或达成交易（无论是否收费），应由用户自行协商并履行各方的约定，反抄袭对此不提倡亦不禁止，不对此承担连带或担保的责任。
      </p>

      <p>
        3.特别提示的是，反抄袭不能保证用户在本网站上提供的自我介绍信息是真实和全面的；换而言之，如果某用户声称系某家律师事务所或某名律师，并提供联系方式或服务，反抄袭不能保证该信息属实亦对此不承担任何责任。
      </p>

      <p>4. 就反抄袭信息库、数据采集等页面中的合同、法律文书、条款：</p>

      <p>
        （1）本软件中公开或共享的合同、法律文书、条款及其简介、评论（以信息库、数据采集为主），以下简称公开文本）。无论该公开文本是免费或需要付费下载使用，无论该文本与信息系由反抄袭提供或由用户提供，无论是否经过反抄袭平台编辑、修订或经“反抄袭认证”，均在本免责声明范围之内。
      </p>

      <p>
        （2）用户（无论是政府公务人员、律师、法律专业人员，或普通用户）应根据自身的具体情况参考、修订使用反抄袭的公开文本。
      </p>

      <p>
        （3）本网站中的公开文本一般系依据中华人民共和国（港澳台地区除外）法律法规而拟定，未必适用于其它国家地区。即使在中华人民共和国范围内，亦根据本文件享有相应免责权利。
      </p>

      <p>
        （4）反抄袭致力于提升公开文本的质量，但仍不能保证本网站中的公开文本不存在法律上的错误。
      </p>

      <p>
        （5）免责声明：在任何情况下，不得因使用或依赖本网站上的信息、模板、文本而导致的任何损失要求反抄袭承担责任，亦不得要求提供此信息的反抄袭用户承担责任。无论用户系免费或付费使用，均适用本免责声明。
      </p>

      <p>
        （6）反抄袭不能对用户在软件中发表的文章、观点、回答或评论的正确性进行保证。用户在反抄袭发表的内容仅表明其个人的立场和观点，并不代表反抄袭的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。反抄袭不承担任何法律及连带责任。
      </p>

      <p>
        （7）公开文本来自于用户上传发布或网络公开渠道。如有涉嫌侵权（包括侵犯著作权、商业秘密或个人隐私等情形），欢迎用户向反抄袭进行投诉，反抄袭将视情况予以删除或修订处理。
      </p>
      <h2>九．许可终止</h2>
      <p>
        1.如您未遵守本《协议》的各项条款和条件，反抄袭可终止本“软件”许可，届时您必须立即卸载本”软件“、销毁附随的文档电子或印刷材料，并赔偿因您违反本《协议》导致的反抄袭遭受的损失。
      </p>
      <h2>十.用户行为规范</h2>
      <p>（一）信息内容规范</p>

      <p>
        1.本条所述信息内容是指用户使用本软件及服务过程中所上传、制作、复制、发布、传播的任何内容，包括但不限于反抄袭号或功能帐号的头像、名字、用户说明、简介等注册信息，或上传的模板、文书、文字、语音、图片、视频、表情等信息和相关链接页面，以及其他使用反抄袭帐号或功能帐号或本软件及服务所产生的内容。
      </p>

      <p>
        2.
        用户不得利用反抄袭帐号或功能帐号或本软件及服务制作、复制、发布、传播如下干扰反抄袭正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：
      </p>

      <p>2.1 发布、传送、传播、储存违反国家法律法规禁止的内容：</p>

      <p>（1）违反宪法确定的基本原则的；</p>

      <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>

      <p>（3）损害国家荣誉和利益的；</p>

      <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>

      <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>

      <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

      <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>

      <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>

      <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>

      <p>（10）以非法民间组织名义活动的；</p>

      <p>（11）含有法律、行政法规禁止的其他内容的。</p>

      <p>
        2.2
        发布、传送、传播、储存侵害他人或第三方知识产权、名誉权、肖像权、商业秘密等合法权利的内容；
      </p>

      <p>2.3 涉及他人隐私、个人信息或资料的；</p>

      <p>
        2.4其他违反法律法规、政策及公序良俗、社会公德或干扰反抄袭正常运营和侵犯其他用户或第三方合法权益内容的信息。
      </p>

      <p>（二）软件使用规范</p>

      <p>
        1. 除非法律允许或反抄袭书面许可，你使用本软件过程中不得从事下列行为：
      </p>

      <p>2. 删除本软件及其副本上关于著作权的信息；</p>

      <p>
        3.对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
      </p>

      <p>
        4.
        对反抄袭拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </p>

      <p>
        5.对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非反抄袭经授权的第三方工具
      </p>
      /服务接入本软件和相关系统；

      <p>
        6.通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
      </p>

      <p>
        7.通过非反抄袭开发、授权的第三方软件、插件、外挂、系统，登录或使用反抄袭软件及服务，或制作、发布、传播上述工具；
      </p>

      <p>
        8.自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；
      </p>

      <p>9.其他未经反抄袭明示授权的行为。</p>

      <p>（三）违约处理</p>

      <p>
        1.如果反抄袭发现或收到他人举报或投诉用户违反本协议约定的，反抄袭有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。
      </p>

      <p>
        2.反抄袭有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
      </p>

      <p>
        3.你理解并同意，因你违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，你应当独立承担责任；反抄袭因此遭受损失的，你也应当一并赔偿。
      </p>
      <h2>十一.不正当竞争</h2>
      <p>
        用户了解反抄袭及作者对模板整理付出巨大劳动，具有商业价值；任何用户以网络爬虫技术或其他技术获取反抄袭文本或网站其他内容，均属于不正当竞争行为，反抄袭将对公开模板或文档作者将追究其侵权行为。
      </p>
      <h2>十二.使用限制</h2>
      <p>
        1.用户知晓并确认，在反抄袭以免费下载或付费购买方式获得模板或文档，可用作个人为客户审查、起草合同，起草法律文书等业务领域的具体使用。
      </p>

      <p>
        2.用户不可将反抄袭的模板或文档（无论是否购买取得）用于建设与反抄袭同类的模板分享平台或中心，不可以微信群、微信公众号、淘宝等方式向不特定公众出售或赠送，否则属于违反用户条款，反抄袭或模板作者均可要求用户支付20万元违约金，违约金不足以弥补反抄袭损失或用户获利超过违约金的，反抄袭有权另行追究其赔偿责任。
      </p>

      <p>
        3.反抄袭有权在用户存在不正常使用行为时，取消其用户权限，包括但不限于禁止其下载或购买文本，停止其账号使用权限，中止已经购买的合同包或会员的功能和权限。
      </p>
      <h2>十三.知识产权</h2>
      <p>
        1．反抄袭是一个原创版权收益与服务平台，反抄袭尊重和鼓励反抄袭用户创作的内容，认识到保护知识产权对反抄袭生存与发展的重要性；同时，也认识到法律文本在知识产权上的特殊性（大量合同等法律文本由于缺乏独创性而不具备知识产权，或不具备完整的知识产权）。
      </p>

      <p>
        2．用户在反抄袭上发表的信息、使用的文本，应由用户自行保证其不侵犯他人的知识产权。
      </p>

      <p>
        3．用户在反抄袭上发布的公开信息，应特别注意勿侵犯或泄露他人隐私或秘密，并由用户自行对此负责。反抄袭有权但无义务对此进行审核。
      </p>

      <p>
        4．用户在反抄袭上公开发布模板，即视为用户允许任何第三方下载及自行使用。
      </p>

      <p>
        5．用户下载或购买反抄袭的公开模板（含成套模板）时，该文本的知识产权仍属于原作者所有，用户仅可以自行使用或用于签约，但不得向公开渠道或不特定第三方传播（无论是否营利）。
      </p>

      <p>
        6 .
        任何人发现反抄袭的任何内容涉嫌侵权时，均可通过反抄袭的投诉渠道进行投诉。
      </p>

      <p>
        7．出于下列目的之一，反抄袭有权对用户发布的公开信息（包括公开模板及其批注、成套模板、公开评论等）进行删减、修订、隐藏处理，无论是针对全部或部分：
      </p>

      <p>1）反抄袭认为用户发布的信息可能涉嫌侵犯他人知识产权或隐私时；</p>

      <p>2）用户投诉或存在争议时；</p>

      <p>
        3）反抄袭的专家团队认为需要提高该信息或文本的质量，或需要统一格式，特别是可能对用户造成困扰、不便或误导时。
      </p>

      <p>
        反抄袭有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及反抄袭社区指导原则对侵权信息进行处理。
      </p>
      <p>
        8．特别说明：用户在反抄袭上发布免费公开模板时，即为授予反抄袭免费的、不可撤销的、非独家使用许可，反抄袭有权将该信息（全部或部分）免费用于反抄袭各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品、书籍出版，包括免费或收费服务。但此种情况下，不应影响用户的署名权（署名以用户在反抄袭的注册称呼为准，用户有特别要求时除外）。
      </p>
      <h2>十四.收费服务</h2>
      <p>反抄袭目前提供合同库方面的下列收费服务：</p>
      <p>1.面向单个用户服务</p>

      <p>1）购买文本（单个模板或成套模板）。</p>

      <p>
        用户可根据文本（含成套模板）的简介、提示等购买。用户购买后可以另存编辑或下载。
      </p>

      <p>2）购买反抄袭个人云盘扩容。</p>

      <p>
        用户可在一定期限享受一定数量的文本下载与使用权限，及其它功能，具体参见个人云盘页面。
      </p>

      <p>2.面向机构、单位的服务</p>

      <p>1）机构版合同库接入</p>

      <p>由机构充值，机构提供给其成员（用户）使用，不得开放给公开渠道使用。</p>

      <p>2）机构版文书助手</p>

      <p>由机构购买，机构提供给其成员（用户）使用，成员无需另外付费。</p>

      <h2>好</h2>

      <p>1．反抄袭的用户目前可通过以下方式获得收益：</p>

      <p>
        发布收费模板（单个模板或成套模板），由其它用户的购买、使用后获得分成作为收益。
      </p>

      <p>2．反抄袭的用户所获得的收益，可在登录后查阅。</p>

      <p>
        3．对于用户在反抄袭网站上所获得的收益，反抄袭有权扣留一定的比例（服务费用）之后支付给作者。
      </p>

      <p>4．反抄袭将对提现的周期、金额作适当限制与调整。具体以页面提示为准。</p>

      <p>
        5．特别说明的是，如果由于下列原因导致用户的收益受到损失，反抄袭不承担赔偿与补偿责任：
      </p>

      <p>1）用户账号被他人盗用；</p>

      <p>2）反抄袭受他人攻击，导致账户金额受损，且无法恢复的；</p>

      <p>3）其它非反抄袭故意造成的技术原因，导致账户金额受损，且无法恢复的。</p>

      <p>6．用户应妥善保存账户名与密码，并及时提现，以避免损失。</p>
      <h2>十六.个人隐私</h2>
      <p>
        尊重用户个人隐私信息的私有性是反抄袭的一贯原则，反抄袭将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，反抄袭保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善反抄袭的技术与服务，反抄袭将可能会自行收集使用提供用户的非个人隐私信息，这将有助于反抄袭向用户提供更好的用户体验和服务质量。具体详见《反抄袭隐私政策》。
      </p>
      <h2>十七.适用、管辖法律及条款效力</h2>
      <p>1. 本《协议》的订立、执行和解释及争议的解决均应适用中国大陆法律。</p>

      <p>
        2.
        如您与反抄袭就本《协议》内容或其执行发生任何争议，双方应进行友好协商；协商不成时，任何一方均可向反抄袭住所地有管辖权的深圳前海合作区人民法院提起诉讼。
      </p>

      <p>
        3.
        本《协议》的各项条款具有可分割性，如果某一条款被适用法律认定为无效，则其他条款不受影响，应继续有效并执行。
      </p>
      <h2>十八．特别提示</h2>
      <p>
        1.请您登录网址为http://antiplagiarize.com的网页仔细阅读本“软件”的隐私政策，即《反抄袭隐私保护政策》的内容。请您定期查阅该政策，以随时了解该政策是否发生修改以及修改后的内容。
      </p>

      <p>
        2.
        虽有本《协议》其他条款之约定，但请您特别知悉，本“软件”仅在完全满足如下（1）、（2）或（3）运行环境要求的计算机（本《协议》凡提及“计算机”均仅指能够完全满足本款下述（1）、（2）或（3）运行环境要求的计算机）上方可正常安装运行：
      </p>

      <p>
        （1）如您的计算机为Windows操作系统，则本“软件”对该计算机的运行环境要求为：
      </p>

      <p>
        操作系统：Windows 10/Windows 8/Windows 7/Windows XP <br />
        CPU：双核及以上<br />
        内存：至少2GB内存<br />
        硬盘：至少4GB可用空间
      </p>

      <p>浏览器：IE 6.0，google, qq，firefox，搜狗，猎豹 等主流浏览器</p>

      <p>
        （2）如您的计算机为Linux操作系统，则本“软件”对该计算机的运行环境要求为：
      </p>
      <p>操作系统：Ubuntu、优麒麟、深度、Fedora</p>

      <p>CPU： X86</p>

      <p>内存：至少2GB内存</p>

      <p>硬盘：至少2GB可用空间</p>

      <p>
        （3）如您的计算机为macOS操作系统，则本“软件”对该计算机的运行环境要求为：
      </p>

      <p>操作系统：macOS 10.12或更高版本</p>

      <p>CPU：双核及以上</p>

      <p>内存：至少2GB内存</p>

      <p>硬盘：至少4GB可用空间</p>

      <p>
        3.
        您通过使用您注册获得的反抄袭账号或使用反抄袭认可的可用于登录下述增值服务的您在第三方注册的账号（简称“反抄袭认可的第三方账号”，如微信账号/
        QQ账号/新浪微博账号/小米账号，反抄袭可能随时增加、减少、替换其认可的可用于登录下述增值服务的的第三方账号）在本“软件”提供的登录入口联网登录后，您在您登录的账号或反抄袭认可的第三方账号下获得的服务及产品（包括反抄袭平台服务、反抄袭会员服务、反抄袭云服务等，简称为“增值服务”，该等增值服务的部分或全部需您在联网状态下方可获得或使用），除非反抄袭明示该等服务及产品包含本“软件”或属于本“软件”的一部分，否则该等服务及产品均为独立于本“软件”功能以外的服务及产品，该等增值服务并不是本“软件”的组成部分，也不属于本“软件”的附带功能。请您知悉，即便您为使用本“软件”或本“软件”的部分功能向反抄袭支付了相关费用，您支付的该等费用并不包括该等增值服务的服务费用。如果您需要使用该等增值服务，您应当阅读并接受该等增值服务附带的协议或条款，并根据该等协议或条款的约定且按照反抄袭届时的相关资费政策（该等政策在反抄袭相关网站上进行公布）向反抄袭支付对应的服务费用（如按反抄袭届时的资费政策您需获得的增值服务需付费方可获得），并按照该等协议或条款的约定使用该等增值服务。如您为了使用增值服务向反抄袭支付了对应的服务费用的，您不得以您在安装、使用本“软件”过程中遇到问题或因安装、使用本“软件”与反抄袭发生任何争议为由，要求反抄袭对该等增值服务承担责任或要求反抄袭退还您为使用增值服务支付的任何服务费用。
      </p>
      <p>
        4.
        请您知悉，若您未额外向反抄袭付费购买增值服务或未单独付费向反抄袭购买具有特定功能的插件，本“软件”的某些功能（例如PDF组件(如有）附带的PDF转文字功能）将会全部或部分受限。您在使用本“软件”的某些功能时需遵守增值服务的服务政策或具有特定功能的插件的授权政策。
      </p>
      <p>
        5.
        反抄袭愿意尽最大努力通过本“软件”为您提供更多、更优质的功能。尽管反抄袭向您提供本“软件”的现有功能（简称“现有功能”），但反抄袭未来有可能就本“软件”的一个替换或修改版本或任何升级版本的任何新增、替换或附加功能的全部或部分（简称“收费功能”）向您收取费用，具体资费政策以反抄袭届时公布内容为准，届时您可自行选择是否接受反抄袭提供的本“软件”的收费功能，如您选择接受，则您应按照反抄袭届时制定的收费政策支付相关费用。如果反抄袭针对本“软件”的任何收费功能有任何随附的协议或条款，则您在选择接受该收费功能时，需先行同意此等协议或条款，且此等协议或条款将视为对本《协议》内容之补充，如有冲突，此等协议或条款将优先适用。
      </p>
      <p>
        6.
        您在使用本“软件”过程中将会调用您安装本“软件”的本台计算机所安装的操作系统中的系统字库（简称“该等系统字库”），请您按照该等系统字库的许可使用范围及许可使用方式使用该等系统字库。反抄袭也可能以免费或收费形式随本软件向您提供自研字库或第三方授权反抄袭嵌入“本软件”的字库（简称“第三方字库”），您不得脱离本“软件”使用第三方字库，且仅可在合法获得本软件后将第三方字库用于非商业用途使用，商业用途的使用行为是指：以直接营利或者间接营利为目的，将字库中字体作为视觉设计要素，用于商业广告、商标、LOGO的设计或使用的行为。如该第三方字库附带有原厂商许可政策的（例如本“软件”
        <b
          >如内置了方正字库，则您在使用该方正字库时需遵守方正公司于https://www.foundertype.com/index.php/About/
          bookAuth/key/my_sysq.html</b
        >
        发布的商务授权政策说明），您还须阅读和遵循该原厂商许可政策使用该第三方字库。
      </p>
      <p>
        7.另外提醒您注意，本《协议》之约定有时可能会更改（包括但不限于直接在本《协议》基础上对本《协议》现有条款的修改、变更、替换、增加、删除以及以补充协议等方式对本《协议》现有条款进行修改、变更、替换、增加、删除），本《协议》一旦发生更改，将会以下述两种方式或其中的任一种方式向您告知：
      </p>

      <p>
        1)在网址为http://antiplagiarize.com的页面上提示更改内容，请您随时关注该页面提示的更改内容以确保您能在第一时间阅读、知悉更改内容，如您在反抄袭在该页面显示本《协议》更改内容后又使用过本“软件”的，即视为您已阅读、知悉并同意更改后的本《协议》的全部内容（包括但不限于对本《协议》现有条款进行修改、变更、替换、增加、删除的补充协议等，如有，下同）；
      </p>
      <p>
        2）在向您提供的本“软件”的任何替换版本或修改版本或升级版本的安装包里附带一份本《协议》的更改内容及/或更改后的本《协议》的全部内容或能够显示前述内容的网址链接，一旦您成功安装这类替换、修改或升级版本的安装包，即表明您已阅读、知悉并同意更改后的本《协议》的全部内容。
      </p>
      <p>
        在您需登录网页阅读网页上显示的本《协议》内容或本《协议》的修改内容时，请您确保您系处于联网状态且能够完整阅读本《协议》或本《协议》的修改内容的全部条款，任何时候您均不得以您未实际阅读本《协议》或本《协议》的更改内容（包括但不限于因您不能联网或未实际登录相关网页而未阅读本《协议》或本《协议》的更改内容）为由拒绝认可本《协议》或本《协议》的更改内容对您产生的法律约束力以及合同效力。
      </p>
      <p>
        8.如果您获得合法授权的本“软件”包含Equation
        Editor资源包及/或其他第三方资源包（反抄袭不承诺您获得的本“软件”均包含Equation
        Editor资源包及/或其他第三方资源包，是否包含Equation
        Editor资源包及/或其他第三方资源包以您获得的本“软件”的实际情况为准。Equation
        Editor资源包、其他第三方资源包统称为“该等第三方资源包”），请您关注下述Notice内容：
      </p>
      <p>
        1）该等第三方资源包被整合在您获得合法授权之本“软件”中而成为您获得合法授权之本“软件”的组成部分，且仅能作为您获得合法授权之本“软件”包含的部分功能供您本人随您获得合法授权之本“软件”一同在本台计算机上使用，您无论在任何条件下均无权单独转让该等第三方资源包（授权），也不得将该等第三方资源包（授权）进行出售，分发，分授权、再授权或转授权。
      </p>
      <p>
        2）该等第三方资源包作为您获得合法授权之本“软件”之组成部分，其授权使用范围及权利限制条款适用本《协议》，如该等第三方资源包有其独立的最终用户许可协议的（如Equation
        Editor资源包附带的Design
        Science最终用户许可协议），则该等第三方资源包的授权使用范围及权利限制条款同时适用其独立的最终用户许可协议，鉴于本《协议》有可能与该等第三方资源包独立的最终用户许可协议条款存在不一致之处，在此特别说明，当两份许可协议存在不一致时，则优先适用用户许可更为严格者。
      </p>
      <p>
        3）Equation Editor资源包系Design Science的产品，反抄袭对包括Equation
        Editor资源包在内的全部第三方资源包均不承担任何保证及责任，亦不提供任何支持及服务。
      </p>
      <p>Notice:</p>
      <p>
        为Design Science（
        <a href="www.dessci.com">www.dessci.com</a>
        )美国及/或其他国家的注册商标。
      </p>
      <p>Design Science最终用户许可协议</p>
      <p>Design Science属性</p>
      <h2>
        十九．本《协议》第十八条特别提示条款，效力优先于本《协议》的其他条款，当本《协议》第十八条特别提示条款与本《协议》的其他条款相抵触时，以本《协议》第十八条特别提示条款约定内容为准。
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onArrow() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.location {
  width: 100%;
  .header-hds {
    max-height: calc(100vh - 123px);
    overflow-y: auto;
  }
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  .header {
    width: 100%;
    height: 467px;
    margin-top: 29px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.Agreement {
  width: 100%;
  height: calc(100vh - 94px);
  overflow-y: auto;
  padding: 0 30px 40px;
  box-sizing: border-box;
  p {
    font-size: 24px;
    line-height: 36px;
    text-indent: 2em;
  }
  h2 {
    font-size: 24px;
    margin: 30px 0;
    text-indent: 2em;
    line-height: 36px;
  }
  a {
    color: #0000f1;
  }
}

::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text {
  color: #333333;
  font-size: 28px;
}
::v-deep .van-swipe__indicators .van-swipe__indicator {
  width: 38px;
  height: 7px;
  border-radius: 3.5px;
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 1px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
