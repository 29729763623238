import request from "api/request";
import qs from "qs";

// 获取文件列表
export function getDiskList(data) {
  return request({
    method: "post",
    url: "/disk/list",
    data,
  });
}
// 文件搜索
export function searchList(data) {
  return request({
    method: "post",
    url: "/disk/search",
    data,
  });
}
// 新增文件夹
export function addFiles(data) {
  return request({
    method: "post",
    url: "/disk/addCatalogue",
    data,
  });
}
// 获取侧边栏详情
export function sideBarType(data) {
  return request({
    method: "post",
    url: "/disk/type",
    data,
  });
}
// 删除
export function delFiles(data) {
  return request({
    method: "post",
    url: "/disk/recycle",
    data,
  });
}
// 修改名称
export function changeFilesName(data) {
  return request({
    method: "post",
    url: "/disk/revise",
    data,
  });
}
// 详细信息
export function changeFileDetail(data) {
  return request({
    method: "post",
    url: "/disk/fileDetail",
    data,
  });
}
// 目录层级
export function directory(data) {
  return request({
    method: "post",
    url: "/disk/directory",
    data,
  });
}
// 移动文件
export function moveFile(data) {
  return request({
    method: "post",
    url: "/disk/moveFile",
    data,
  });
}
// 网盘内存
export function memoryFile(data) {
  return request({
    method: "post",
    url: "/disk/memory",
    data,
  });
}
// 文件预览
export function previewFile(data) {
  return request({
    method: "post",
    url: "/disk/preview",
    data,
  });
}
// 文件下载
export function fileDownload(data) {
  return request({
    method: "get",
    url: "/disk/fileDownload?id=" + data,
    responseType: "blob",
  });
}
// 回收站删除
export function recycleBinDel(data) {
  return request({
    method: "post",
    url: "/disk/recycleDelete",
    data,
  });
}
// 回收站还原文件
export function recycleBinReduction(data) {
  return request({
    method: "post",
    url: "/disk/resume",
    data,
  });
}
// 回收站清空回收站
export function recycleBinEmpty(data) {
  return request({
    method: "post",
    url: "/disk/recycleClear",
    data,
  });
}
//上传文件
export function mergeFile(data, data1) {
  return request({
    url: "mobile/uploader/chunk?" + qs.stringify(data1),
    method: "post",
    data,
  });
}
