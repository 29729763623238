<template>
  <div class="NavigationBar">
    <slot name="nleft"> </slot>

    <div class="NavigationBar-searchbox">
      <!-- 搜索的icon -->
      <div class="NavigationBar-searchbox-icon">
        <!-- <van-icon name="search" /> -->
      </div>
      <div class="NavigationBar-searchbox-inp">
        <input
          type="text"
          name="seach"
          id="seach"
          ref="search"
          v-model="value"
          autocomplete="off"
          @keyup.enter="onSubmit"
          :placeholder="placeholder"
        />
      </div>
      <!-- 扫描的icon -->
      <div
        class="NavigationBar-searchbox-clear"
        @click="handleClear"
        v-show="value"
      >
        <slot name="nclear"></slot>
      </div>
      <!-- <div class="NavigationBar-searchbox-code" @click="handleScan"> -->
      <!-- <van-icon name="envelop-o" /> -->
      <!-- </div> -->
    </div>
    <!-- 插槽  需要添加组件时使用 -->
    <div class="NavigationBar-right">
      <slot name="nright"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: {
    placeholder: {
      typr: String,
      default: "输入搜索内容",
    },
    searchValue: {
      typr: String,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    this.value = this.searchValue;
  },
  methods: {
    // 触发搜索事件  监听 回车键
    onSubmit() {
      // if(!this.value){
      //   this.$toast("请输入搜索关键字!");
      //   return;
      // }
      this.$emit("search", this.value);
    },
    //清除内容
    handleClear() {
      this.value = "";
    },
    slideBlur() {
      this.$refs.search.blur();
    },

    // 扫码
    handleScan() {
      const params = {
        action: "scan",
      };
      if (window.android) {
        window.android.postMessage(JSON.stringify(params));
      } else if (window.webkit) {
        // window.webkit.postMessage(JSON.stringify(params));
        // const params = {
        //   action: "scan",
        // };
        window.webkit.messageHandlers.sendMessage.postMessage(
          JSON.stringify(params)
        );
      } else {
        // 隐藏弹出框
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.NavigationBar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  padding: 20px 30px 10px;
  font-size: 28px;
  &-searchbox {
    flex: 1;
    display: flex;
    align-items: center;
    // width: 313px;
    height: 70px;
    margin-top: -10px;
    padding: 0 30px;
    height: inherit;
    background-color: #fafafa;
    border-radius: 36px;
    &-icon {
      width: 32px;
      height: 32px;
      background-image: url("../../assets/images/disk/searchicon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &-inp {
      background-color: #fafafa;
      padding: 0 20px;
      flex: 1;
      input {
        width: 100%;
        background-color: #fafafa;
      }
    }
    &-clear {
      width: 32px;
      height: 32px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-code {
      width: 32px;
      height: 32px;
      background-image: url("../../assets/images/disk/saoma.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    height: inherit;
  }
  &-left {
    height: inherit;
  }
}
</style>
