import request from "api/request";

// 获取待办委托列表
export function getagencyEntrustmentList(data) {
  return request({
    url: "/task/pageList",
    method: "post",
    data,
  });
}

// 获取待办委托详情
export function getcheckDetail(data) {
  return request({
    url: "/task/checkDetail",
    method: "post",
    data,
  });
}

// 获取反馈列表
export function getfeedbackList(data) {
  return request({
    url: "/practice/taskdetail-feedback/findFeedbackList",
    method: "post",
    data,
  });
}

// 撤销反馈
export function recallFeedback(data) {
  return request({
    url: "/practice/taskdetail-feedback/deleteFeedback",
    method: "delete",
    data,
  });
}

// 上传反馈
export function uploadList(data) {
  return request({
    url: "/taskdetailfile/fileBatchUpload",
    method: "post",
    data,
  });
}

// 修改时间
export function modifyTime(data) {
  return request({
    url: "/task/update",
    method: "post",
    data,
  });
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/user/getUserInfoById",
    method: "post",
    data,
  });
}
