<template>
  <div>
    <div class="header">
      <div class="head-logo">
        <img src="../../assets/images/15.png" alt="" />
      </div>
      <img src="../../assets/images/11.png" alt="" />
      <div class="header-bd">
        <div class="box" @click="iosDownload">
          <div class="div"></div>
          <span>反抄袭IOS版本下载</span>
        </div>
        <div class="box" @click="anDownload">
          <div class="div1"></div>
          <span>反抄袭Android版本下载</span>
        </div>
      </div>
      <img src="../../assets/images/04.png" alt="" />
      <div class="header-bd" @click="cyDownload">
        <div class="box">
          <div class="div"></div>
          <span>成语IOS版本下载</span>
        </div>
        <div class="box" @click="cyDownload">
          <div class="div1"></div>
          <span>成语Android版本下载</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  methods: {
    iosDownload() {
      window.location.href =
        "https://itunes.apple.com/cn/app/%E5%8F%8D%E6%8A%84%E8%A2%AD/1618189432";
    },
    anDownload() {
      window.location.href =
        " https://a.app.qq.com/o/simple.jsp?pkgname=com.writemall.antiplagiarize.android";
    },
    cyDownload() {
      Toast({
        message: "APP上线中 敬请期待...",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  overflow-y: auto;
  .head-logo {
    position: absolute;
    top: 62px;
    left: 82px;
    width: 204px;
    height: 58px;
    img {
      width: 100%;
    }
  }
  img {
    width: 100%;
  }
  .header-bd {
    padding: 72px 82px 44px 80px;
    box-sizing: border-box;
    .box {
      display: flex;
      //   justify-content: center;
      width: 590px;
      height: 100px;
      border-radius: 50px;
      background-color: #4588ff;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #fefefe;
      line-height: 100px;
      .div {
        display: inline-block;
        width: 46px;
        height: 46px;
        margin: auto 26px auto 78px;
        background-image: url("../../assets/images/05.png");
        background-size: cover;
        background-repeat: no-repeat;
      }
      .div1 {
        display: inline-block;
        width: 46px;
        height: 46px;
        margin: auto 26px auto 78px;
        background-image: url("../../assets/images/06..png");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .box:nth-child(2) {
      margin-top: 2.2vh;
    }
  }
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 1px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
