import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const originPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => {});
  } else {
    originPush.call(this, location, onComplete, onAbort);
  }
};
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => {});
  } else {
    originReplace.call(this, location, onComplete, onAbort);
  }
};

const routes = [
  {
    path: "/",
    name: "",
    component: () => import("views/home"),
  },
  //首页
  {
    name: "homePage",
    path: "/homePage",
    component: () => import("views/homePage/index"),
  },
  //下载
  {
    name: "yddownload",
    path: "/yddownload",
    meta: {
      show: true,
    },
    component: () => import("views/yddownload/index"),
  },
  //关于我们
  {
    name: "regardsMy",
    path: "/regardsMy",
    component: () => import("views/regardsMy/index"),
  },
  {
    name: "location",
    path: "/location",
    component: () => import("views/regardsMy/location"),
  },
  {
    name: "openFile",
    path: "/openFile",
    component: () => import("views/share/openFile"),
  },
  {
    name: "share",
    path: "/share",
    component: () => import("views/share/index"),
  },
  {
    name: "Agreement",
    path: "/Agreement",
    component: () => import("views/regardsMy/Agreement"),
  },
  {
    name: "Privacy",
    path: "/Privacy",
    component: () => import("views/regardsMy/Privacy"),
  },
  {
    name: "functionality",
    path: "/locfunctionalityation",
    component: () => import("views/regardsMy/functionality"),
  },
  //智能内容库
  {
    name: "disk",
    path: "/disk",
    component: () => import("views/disk/index"),
  },
  //预览文件
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("views/disk/redirect"),
  },
  //网盘搜索结果
  {
    path: "/netfilesearch/:val",
    name: "netfilesearch",
    component: () => import("views/disk/netfilesearch/netfilesearch.vue"),
  },
  //用户登录
  {
    path: "/login",
    name: "Login",
    component: () => import("views/login/Login"),
  },
  // 商品详情
  {
    path: "/shopIndex/:val",
    name: "ShopIndex",
    component: () => import("views/shop/shopIndex.vue"),
  },
  // 待办委托
  {
    path: "/agencyEntrustment",
    name: "AgencyEntrustment",
    component: () => import("views/agencyEntrustment/agencyEntrustment"),
    redirect: "agencyEntrustment/agencyEntrustmentIndex",
    children: [
      {
        path: "agencyEntrustmentIndex",
        name: "AgencyEntrustmentIndex",
        component: () =>
          import("views/agencyEntrustment/agencyEntrustmentIndex.vue"),
      },
      {
        path: "agencyDetails/:id/:idType",
        name: "AgencyDetails",
        component: () => import("views/agencyEntrustment/agencyDetails.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
