import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'store/index'
import 'assets/scss/index.scss'
import './plugins/vant.js'

Vue.prototype.removeToken = function () {
  window.localStorage.removeItem('userToken')
}
new Vue({
  router,
  store,
  created() {
    var url = window.location.href //获取url中"?"符后的字串
    // var url =
    //   'http://localhost:8082/disk?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoZW50aWNhdGlvbk1ldGhvZCI6InVzZXJuYW1lIiwidXNlcl9uYW1lIjoiMTc2Mzk4ODkzMjkiLCJzY29wZSI6WyJhbGwiXSwiZXhwIjoxNjU1MDg1MzIzLCJ1c2VySWQiOjMzOCwianRpIjoiYTllNzEwYmUtMzMzZi00NjNiLTgxOTAtODU2NjEwMDA2MjUyIiwiY2xpZW50X2lkIjoiY2xpZW50LWFwIn0.uE3eDS1vBLB9MVeot5hlkjIDyre_QnQ2rP_5TlPb8ao&code=3'
    if (url.includes('share?')) {
      window.localStorage.setItem('url', JSON.stringify(window.location.href))
      this.$router.push({
        name: 'share',
      })
      return
    } else {
      if (url.split('?').length > 1) {
        if (url.includes('code')) {
          var cs = url.split('?')[1]
          let cz = cs.split('=')[1]
          cz = cz.split('&')[0]
          let code = cs.split('&')[1]
          code = code.split('=')[1]
          const userToken = cz //获取?之后的参数字符串
          // 获取到的信息 存储到localStorage中
          window.localStorage.setItem('userToken', JSON.stringify(userToken))
          window.localStorage.setItem('code', JSON.stringify(code))
        } else {
          var cx = url.split('?')[1]
          let ct = cx.split('=')[1]
          ct = ct.split('&')[0]
          const userToken = ct
          window.localStorage.setItem('userToken', JSON.stringify(userToken))
          window.localStorage.removeItem('code')
        }
      }
    }
  },
  render: (h) => h(App),
}).$mount('#app')
