<template>
  <div class="home">
    <van-button type="primary" @click="navShop">商品详情</van-button>

    <van-button type="success" @click="IntelligentContent"
      >移动端网盘</van-button
    >
    <van-button type="info" @click="toAgencyEntrustment">代办委托</van-button>
    <van-button type="warning" @click="homePage">首页</van-button>
    <van-button type="warning" @click="regardsMy">关于我们</van-button>
    <van-button type="warning" @click="share">分享</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    IntelligentContent() {
      this.$router.push({
        name: "disk",
      });
    },
    share() {
      this.$router.push({
        name: "share",
      });
    },
    homePage() {
      this.$router.push({
        name: "homePage",
      });
    },

    regardsMy() {
      this.$router.push({
        name: "regardsMy",
      });
    },
    navShop(val) {
      val = 28;
      this.$router.push({
        path: `/shopIndex/${val}`,
      });
    },
    // toGoldenSentence() {
    //   this.$router.push({
    //     name: "disk",
    //   });
    // },
    // toKnowledgeService() {
    //   this.$router.push("/knowledgeServiceIndex");
    // },
    // toIdiom() {
    //   this.$router.push({
    //     path: "/idiom",
    //   });
    // },
    toAgencyEntrustment() {
      this.$router.push({
        name: "AgencyEntrustment",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  margin: 30px 20px;
}
</style>
