<template>
  <div>
    <div class="niao">
      <van-nav-bar
        :border="false"
        @click-left="onArrow"
        class="van-bar"
        title="预览"
        left-arrow
      >
      </van-nav-bar>
    </div>
    <iframe class="ntiplagiarize" :src="url"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: this.$route.query.data,
    };
  },

  methods: {
    onArrow() {
      this.$router.go(-1);
      //   this.$router.push({
      //     name: "disk",
      //   });
    },
  },
};
</script>
<style lang="scss" scoped>
.ntiplagiarize {
  width: 100%;
  height: calc(100vh - 92px);
}
.niao {
  width: 100%;
  height: 92px;
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  ::v-deep .van-nav-bar__left {
    .van-icon {
      color: #333333;
      margin-left: 70px;
    }
  }
  .van-nav-bar__content {
    width: 100%;
    height: 100% !important;
  }
}
::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 4.8vw;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
</style>
