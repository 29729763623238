<template>
  <div class="agencyEntrustmentIndex">
    <van-nav-bar
      title="待办委托"
      right-text="刷新"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多啦"
      @load="onload"
      class="publist"
    >
      <div class="agencyEntrustmentIndex-content">
        <div
          class="agencyEntrustmentIndex-content-item"
          v-for="(item, index) in list"
          :key="item.taskId"
          @click="itemClick(item)"
        >
          <div class="item-left">
            <i
              class="item-left-icon"
              :class="item.overTime == true ? 'yellow' : 'blue'"
            ></i>
            <div
              class="item-left-text"
              :class="item.overTime == true ? 'fontYellow' : ''"
            >
              {{ item.overTime == true ? "已超时" : "未超时" }}
            </div>
          </div>
          <div class="item-right">
            <div class="item-right-title">
              {{ index + 1 }}. {{ item.taskName }}
            </div>
            <van-progress
              :percentage="item.progress"
              stroke-width="3px"
              :show-pivot="false"
              :color="item.overTime == true ? '#FFC643' : ''"
              :track-color="item.overTime == true ? '#FFEEC9' : ''"
            />
            <div class="item-right-text">完成度{{ item.progress }}%</div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getagencyEntrustmentList } from "api/agencyEntrustment";
export default {
  data() {
    return {
      page: 1, //页码
      limit: 10, //一页几条
      total: null, //总条数
      list: [], //列表数组
      loading: false, //加载状态
      finished: true, //是否加载
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      if (window.android) {
        const jsonStr = {
          action: "back",
        };
        window.android.postMessage(JSON.stringify(jsonStr));
      } else if (window.webkit) {
        const jsonStr = {
          action: "back",
        };
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(jsonStr)
        );
      }
    },
    // 刷新
    onClickRight() {
      this.$router.go(0);
    },
    // 获取页面List
    async getList() {
      let data = {
        page: this.page,
        limit: this.limit,
      };
      let res = await getagencyEntrustmentList(data);
      if (res.code === 200) {
        this.loading = false;
        res.data.data.map((item) => {
          this.list.push(item);
        });
        this.total = res.data.total;
        if (
          this.page == Math.ceil(this.total / this.limit) ||
          this.page > Math.ceil(this.total / this.limit)
        ) {
          //判断是否数据全部加载完成
          this.finished = true;
        } else {
          this.finished = false;
        }
      } else {
        this.$toast(`${res.message}`);
      }
    },
    // 下拉触发
    onload() {
      this.page++;
      this.getList();
    },
    // item的点击事件
    itemClick(item) {
      this.$router.push({
        path: `/agencyEntrustment/agencyDetails/${item.taskId}/${item.identityType}`,
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.agencyEntrustmentIndex {
  height: 100vh;
  background: #f5f5f8;
  ::v-deep .van-nav-bar {
    .van-nav-bar__content {
      height: 100px;
    }
    .van-nav-bar__title {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .van-ellipsis {
      overflow: visible;
    }
    .van-nav-bar__text {
      color: #999999;
      font-size: 30px;
    }
    .van-icon {
      color: #333333;
      font-size: 36px;
    }
  }
  &-content {
    padding: 20px 31px;
    &-item {
      height: 166px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .item-left {
        margin-left: 30px;
        text-align: center;
        .item-left-icon {
          width: 72px;
          height: 72px;
          display: inline-block;
        }
        .blue {
          @include backgroundGroup(
            "~assets/images/agencyEntrustment/time-blue.png"
          );
        }
        .yellow {
          @include backgroundGroup(
            "~assets/images/agencyEntrustment/time-yellow.png"
          );
        }
        .item-left-text {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          margin-top: 10px;
          color: #4587ff;
        }
        .fontYellow {
          color: #fab619;
        }
      }
      .item-right {
        position: relative;
        margin-left: 30px;
        width: 480px;
        .item-right-title {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          width: 500px;
          @include ellipsis();
        }
        .van-progress {
          margin-top: 24px;
        }
        .item-right-text {
          margin-top: 20px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        .van-icon {
          position: absolute;
          bottom: 3px;
          right: 0;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
}
</style>
