<template>
  <div>
    <div class="niao">
      <van-nav-bar
        :border="false"
        @click-left="onArrow"
        class="van-bar"
        :title="title"
        left-text="返回"
      >
      </van-nav-bar>
      <iframe class="ntiplagiarize" frameborder="0" :src="url"></iframe>
      <!-- <iframe
        class="ntiplagiarize"
        frameborder="0"
        scrolling="no"
        :src="url"
      ></iframe> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: this.$route.query.url,
      title: this.$route.query.fileName,
    };
  },
  mounted() {
    console.log(this.$route.query.url);
  },

  methods: {
    onArrow() {
      this.$router.go(-1);
      //   this.$router.push({
      //     name: "disk",
      //   });
    },
  },
};
</script>
<style lang="scss" scoped>
.ntiplagiarize {
  width: 100%;
  height: calc(100vh - 92px);
}
.niao {
  width: 100%;
  .van-bar {
    height: 92px;
    line-height: 92px;
  }

  .van-nav-bar__content {
    width: 100%;
    height: 100% !important;
  }
}
::v-deep .van-nav-bar__text {
  color: #333333;
}
::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 35%;
}
</style>
