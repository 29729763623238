<template>
  <div class="agencyDetails">
    <van-nav-bar :title="details.taskName" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="friends-o" @click="lookUpPeople" />
      </template>
    </van-nav-bar>
    <div class="agencyDetails-content">
      <van-cell class="cell-height">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <span class="custom-title">预计完成时间</span>
        </template>

        <template #default>
          <span class="custom-time">{{ details.plannedCompletionTime }}</span>
          <van-icon
            name="edit"
            v-if="identityType == 1"
            @click="modifyTime = true"
          />
        </template>
      </van-cell>

      <van-cell class="cell-height">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <span class="custom-title">目前完成度</span>
        </template>

        <template #default>
          <van-progress
            ref="progress"
            :percentage="progress"
            stroke-width="4px"
            :show-pivot="false"
          />
          <div class="custom-value">完成度{{ details.progress }}%</div>
        </template>
      </van-cell>

      <!--  步骤条 -->
      <ul class="triangle_step">
        <li
          @click="StepClick(item.parentId, index)"
          :class="{ cur: active - 1 >= index, clikc: i == index }"
          v-for="(item, index) in obj"
          style="width: 100%"
          :key="index"
        >
          <div class="step-text">{{ item.stepName }}</div>
          <div
            v-if="index < obj.length - 1"
            :class="[
              active - 1 >= index ? 'jiaoActive' : 'jiao',
              i == index ? 'jiaoclikc' : '',
            ]"
          ></div>
          <div class="interval" v-if="index < obj.length - 1"></div>
        </li>
      </ul>

      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item
          v-for="(item, index) in tableData"
          :key="index"
          :name="index + 1"
        >
          <template #title>
            <div class="title">
              <span>{{ item.name }}</span>
              <span v-if="item.status == 0" class="textFont bgcprimary">{{
                item.statusText
              }}</span>
              <span v-if="item.status == 1" class="textFont bgcplain">{{
                item.statusText
              }}</span>
              <span v-if="item.status == 2" class="textFont bgcplain">{{
                item.statusText
              }}</span>
              <span v-if="item.status == 3" class="textFont bgcwarning">{{
                item.statusText
              }}</span>
              <span v-if="item.status == 4" class="textFont bgcdanger">{{
                item.statusText
              }}</span>
              <span v-if="item.status == 5" class="textFont bgcsuccess">{{
                item.statusText
              }}</span>
            </div>
          </template>
          <van-cell>
            <template #title>
              <span class="custom-title">耗时：</span>
            </template>
            <template #default>
              <span class="custom-content">{{ item.needtime }}小时</span>
            </template>
          </van-cell>

          <van-cell>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">需客户知悉、配合事项：</span>
            </template>
            <template #default>
              <span class="custom-content">{{ item.coordinate }}</span>
            </template>
          </van-cell>

          <van-cell>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">执业规范要求：</span>
            </template>
            <template #default>
              <span class="custom-content">{{ item.require }}</span>
            </template>
          </van-cell>

          <div class="btnBox">
            <van-button color="#7232dd" @click="openMouldPopup(item)"
              >查看模板</van-button
            >
            <van-button type="info" @click="openFeedbackPopup(item)"
              >查看反馈</van-button
            >
            <van-button type="warning" @click="openSubmitFeedBackPopup(item)"
              >提交反馈</van-button
            >
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 模板弹出层 -->
    <van-popup
      class="mouldPopup"
      v-model="mouldPopup"
      :closeable="true"
      round
      position="bottom"
      :style="{ height: '60%' }"
    >
      <table class="popup-table">
        <thead>
          <tr>
            <th>文件名</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </thead>
        <tfoot>
          <tr v-for="item in childrenList" :key="item.id">
            <td>{{ item.fileName }}</td>
            <td>{{ item.remark }}</td>
            <td><span @click="download(item.fileUrl)">下载</span></td>
          </tr>
        </tfoot>
      </table>
    </van-popup>
    <!-- 查看反馈 -->
    <van-popup
      class="feedbackPopup"
      v-model="feedbackPopup"
      :closeable="true"
      round
      position="bottom"
      :style="{ height: '80%' }"
    >
      <section class="timeline" style="">
        <div class="timeline-block" v-for="item in feedbackList" :key="item.id">
          <div class="ctimeline-img cd-picture"></div>
          <span class="item dot-top"></span>
          <div class="r-info">
            <div class="timeTitle">
              <div>{{ item.createTime }}</div>
            </div>
            <div class="msgBox">
              <div class="msg-content">{{ item.feedback }}</div>
              <div
                class="jumpA"
                v-if="item.fileName != null && item.fileUrl != null"
              >
                附件：
                <span @click="download(item.fileUrl)">{{ item.fileName }}</span>
              </div>
              <div class="lastBox">
                <p>
                  <span
                    ><img
                      :src="
                        item.userInfoVO.headImg == null
                          ? require('assets/images/user.png')
                          : item.userInfoVO.headImg
                      "
                      @error="imgError(item)"
                      alt=""
                    />{{
                      item.userInfoVO.realName == null
                        ? item.userInfoVO.username
                        : item.userInfoVO.realName
                    }}</span
                  >
                </p>
                <div class="btnGroup" v-if="item.isRead == true">
                  <van-tag type="success">已读</van-tag>
                </div>
                <div class="btnGroup" v-else>
                  <van-tag type="primary">未读</van-tag>
                  <button @click="recall(item)">撤回</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </van-popup>
    <!-- 提交反馈 -->
    <van-popup
      class="submitfeedbackPopup"
      v-model="submitfeedbackPopup"
      :closeable="true"
      round
      @close="closePopup"
      position="bottom"
      :style="{ height: '55%' }"
    >
      <div class="content">
        <textarea
          v-model="textarea"
          :placeholder="identityType == 2 ? '请输入承办反馈' : '请输入督办反馈'"
        ></textarea>
        <input
          type="file"
          ref="file"
          @change="fileChange($event)"
          :accept="phone == true ? '*/*' : ''"
          style="display: none"
        />
        <div class="uploadBox">
          <van-button type="default" class="uploadBtn" @click="upload"
            ><van-icon name="plus" />上传文件</van-button
          >
          <div v-if="fileName != ''" class="fileName">
            <p>{{ fileName }}</p>
            <van-icon name="cross" @click="emptyFileName" />
          </div>
        </div>
        <van-field name="radio" label="反馈类型:" v-if="identityType == 2">
          <template #input>
            <van-radio-group
              v-model="radioOne"
              @change="handleRadio"
              direction="horizontal"
            >
              <van-radio name="1">承办反馈</van-radio>
              <van-radio name="3">提请验收</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="radio" label="反馈类型:" v-else>
          <template #input>
            <van-radio-group
              v-model="radioTwo"
              @change="handleRadio"
              direction="horizontal"
            >
              <van-radio name="2">督办反馈</van-radio>
              <van-radio name="4">验收不通过</van-radio>
              <van-radio name="5">验收通过</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </div>
      <div class="btnGroup">
        <button class="suer" @click="submit">提交</button>
        <button class="close" @click="closePopup">取消</button>
      </div>
    </van-popup>
    <!-- 修改时间 -->
    <van-popup
      class="modifyTime"
      v-model="modifyTime"
      :closeable="true"
      round
      @close="closeModifyTime"
      position="bottom"
      :style="{ height: '25%' }"
    >
      <van-cell title="修改时间" :value="date" @click="show = true" is-link />
      <div class="btnGroup">
        <button class="sure" @click="modifyTimeSure">提交</button>
        <button class="close" @click="closeModifyTime">取消</button>
      </div>
    </van-popup>
    <!-- 人员弹窗 -->
    <van-popup v-model="peoplePopup" class="peoplePopup" :closeable="true">
      <div class="userInfo" ref="user">
        <div class="user">
          <div class="headImg">
            <van-image
              style="
                width: 43px;
                height: 43px;
                margin-right: 10px;
                border-radius: 50%;
                cursor: pointer;
              "
              round
              :src="
                userInfoVO.headImg == null
                  ? require('assets/images/user.png')
                  : userInfoVO.headImg
              "
              fit="cover"
            >
            </van-image>

            <div class="grade" v-if="userInfoVO.memberlevel == 0"></div>
            <div class="grade" v-else-if="userInfoVO.memberlevel == 1"></div>
            <div
              class="grade vipIcon"
              v-else-if="userInfoVO.memberlevel == 2"
            ></div>
            <div class="grade svipIcon" v-else></div>
          </div>
          <div class="user-text">
            <div class="user-name">
              {{
                userInfoVO.realName == null
                  ? userInfoVO.username
                  : userInfoVO.realName
              }}
            </div>
            <div class="user-id">ID:{{ userInfoVO.number }}</div>
            <div class="user-id">手机号:{{ userInfoVO.username }}</div>
            <div class="user-id">微信:{{ userInfoVO.wechatNumber }}</div>
            <div class="user-id">邮箱:{{ userInfoVO.mail }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 日历组件 -->
    <van-calendar
      ref="calendar"
      v-model="show"
      row-height="8vw"
      :show-title="false"
      @confirm="onConfirm"
      class="choiceTime"
    />
  </div>
</template>

<script>
import {
  getcheckDetail,
  getfeedbackList,
  recallFeedback,
  getUserInfo,
  uploadList,
  modifyTime,
} from "api/agencyEntrustment";
export default {
  data() {
    return {
      taskId: null,
      // 阶段数组
      obj: [],
      // 步骤条
      active: 0,
      // 分类阶段数组
      arr: [],
      i: 0,
      tableData: [],
      http: "https://fafs.antiplagiarize.com/",
      details: "", //详情信息
      progress: 0, //进度条
      activeNames: "1",
      mouldPopup: false, //模板弹窗
      childrenList: [], //模板list
      feedbackPopup: false, //反馈弹窗
      taskdetailId: null, //任务详情ID
      projectdetailId: null,
      feedbackList: [], //反馈list
      submitfeedbackPopup: false, //提交反馈弹窗
      textarea: "", //反馈意见内容
      radioOne: "1", //承办人radio
      radioTwo: "2", //用户radio
      identityType: null,
      file: "", //上传文件
      fileName: "", //文件名字
      modifyTime: false, //修改时间
      date: "", //页面渲染时间
      time: "", //未改变格式时间
      show: false, //控制日期弹窗开关
      peoplePopup: false, //人员弹窗
      userInfoVO: "",
      phone: "",
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.go(-1);
    },
    // 反馈类型
    handleRadio(val) {
      if (val == 1 || val == 2) {
        this.textarea = "";
      } else if (val == 3) {
        this.textarea = "请验收";
      } else if (val == 4) {
        this.textarea = "验收不通过";
      } else if (val == 5) {
        this.textarea = "验收通过";
      }
    },
    // 关闭修改时间触发
    closeModifyTime() {
      this.date = "";
      this.show = false;
      this.modifyTime = false;
      this.$refs.calendar.reset();
    },
    // 时间格式化
    formatDate(date) {
      return `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日`;
    },
    // 选择日期确定
    onConfirm(date) {
      this.show = false;
      this.time = date;
      this.date = this.formatDate(date);
    },
    // 修改时间确定
    async modifyTimeSure() {
      // 转换时间格式
      let curtime = new Date(this.time);
      let year = curtime.getFullYear();
      let month = curtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let date = curtime.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      let time = `${year}-${month}-${date} 00:00:00`;
      const data = {
        taskId: this.taskId,
        plannedCompletionTime: time,
      };
      const res = await modifyTime(data);
      if (res.code === 200) {
        this.$toast({
          message: `修改成功`,
          getContainer: ".agencyDetails",
        });
        this.closeModifyTime();
        this.getDetails();
      } else {
        this.$toast({
          message: `${res.message}`,
          getContainer: ".agencyDetails",
        });
      }
    },
    // 被点击项
    StepClick(id, index) {
      this.i = index;
      this.activeNames = "1";
      this.tableData = this.arr.filter((item, index) => {
        if (item.parentId == id) {
          return item;
        }
      });
    },
    //删除文件
    emptyFileName() {
      this.file = "";
      this.fileName = "";
      this.$refs.file.value = "";
    },
    // 打开提交反馈弹窗
    openSubmitFeedBackPopup(item) {
      this.taskdetailId = item.taskdetailId;
      this.projectdetailId = item.projectdetailId;
      this.submitfeedbackPopup = true;
    },
    // 图片容错触发
    imgError(item) {
      item.userInfoVO.headImg = require("assets/images/user.png");
    },
    // 获取页面详情
    async getDetails() {
      let formData = new FormData(); // 当前为空
      formData.append("taskId", this.taskId);
      let res = await getcheckDetail(formData);
      if (res.code === 200) {
        this.arr = res.data.taskDetailCheckVo;
        this.obj = res.data.step;
        this.obj.forEach((item, index) => {
          if (item.status == this.obj[0].parentId) {
            this.active = item.sort;
          }
        });
        this.tableData = this.arr.filter((item, index) => {
          if (item.parentId == this.obj[0].parentId) {
            return item;
          }
        });

        this.details = res.data;
        this.progress = this.details.progress;
        //转换时间格式
        let myDate = new Date(this.details.plannedCompletionTime);
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let date = myDate.getDate();
        if (date < 10) {
          date = "0" + date;
        }
        this.details.plannedCompletionTime =
          year + "年" + month + "月" + date + "日";
      }
    },
    // 打开模板弹窗
    openMouldPopup(item) {
      this.childrenList = item.templateList;
      this.mouldPopup = true;
    },
    // 上传文件
    upload() {
      this.$refs.file.click();
    },
    // 改变文件触发
    fileChange(e) {
      this.file = e.target.files[0];
      this.fileName = this.file.name;
    },
    // 关闭提交反馈弹窗触发
    closePopup() {
      this.file = "";
      this.fileName = "";
      this.textarea = "";
      this.$refs.file.value = "";
      this.radioOne = "1";
      this.radioTwo = "2";
      this.submitfeedbackPopup = false;
    },
    // 打开反馈弹窗
    openFeedbackPopup(item) {
      this.taskdetailId = item.taskdetailId;
      this.projectdetailId = item.projectdetailId;
      this.feedBackList();
    },
    //下载
    download(url) {
      let src = `${this.http}${url}`;
      if (window.android) {
        let obj = {
          action: "downloadByUrl",
          data: src,
        };
        window.android.postMessage(JSON.stringify(obj));
      } else if (window.webkit) {
        let obj = {
          action: "downloadByUrl",
          data: src,
        };
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(obj)
        );
      }
    },
    // 撤回按钮
    async recall(item) {
      let formData = new FormData();
      formData.append("feedbackId", item.id);
      const res = await recallFeedback(formData);
      if (res.code === 200) {
        this.$toast({
          message: `撤回成功`,
          getContainer: ".agencyDetails",
        });
        this.feedBackList();
      } else {
        this.$toast({
          message: `${res.message}`,
          getContainer: ".agencyDetails",
        });
      }
    },
    // 获取反馈列表
    async feedBackList() {
      this.feedbackList = [];
      let formData = new FormData();
      formData.append("projectDetailId", this.projectdetailId);
      formData.append("taskDetailId", this.taskdetailId);
      const res = await getfeedbackList(formData);
      if (res.code === 200) {
        this.feedbackList = res.data;
        if (this.feedbackList.length > 0) {
          this.feedbackPopup = true;
        } else {
          this.$toast({
            message: "暂无反馈信息",
            getContainer: ".agencyDetails",
          });
        }
      } else if (res.code === 2001) {
        this.feedbackList = res.data;
        this.$toast({
          message: "暂无反馈信息",
          getContainer: ".agencyDetails",
        });
      } else {
        this.$toast({
          message: `${res.message}`,
          getContainer: ".agencyDetails",
        });
      }
    },
    // 反馈提交
    async feedbackSumbit() {
      // 1用户
      if (this.identityType == 1) {
        let formData = new FormData();
        formData.append("files", this.file);
        formData.append("taskDetailId", this.taskdetailId);
        formData.append("type", this.identityType);
        formData.append("feedback", this.textarea);
        formData.append("status", this.radioTwo);
        const res = await uploadList(formData);
        if (res.code === 200) {
          this.$router.go(0);
          this.$toast({
            message: "成功！",
            getContainer: ".agencyDetails",
          });
        } else {
          this.$toast({
            message: `${res.message}`,
            getContainer: ".agencyDetails",
          });
        }
      } else {
        //2承办人
        let formData = new FormData();
        formData.append("files", this.file);
        formData.append("taskDetailId", this.taskdetailId);
        formData.append("type", this.identityType);
        formData.append("feedback", this.textarea);
        formData.append("status", this.radioOne);
        const res = await uploadList(formData);
        if (res.code === 200) {
          this.$router.go(0);
          this.$toast({
            message: "成功！",
            getContainer: ".agencyDetails",
          });
        } else {
          this.$toast({
            message: `${res.message}`,
            getContainer: ".agencyDetails",
          });
        }
      }
    },
    // 反馈提交
    submit() {
      if (this.textarea == "") {
        this.$toast({
          message: `请输入反馈意见`,
          getContainer: ".agencyDetails",
        });
        return;
      }
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认提交当前反馈信息",
          getContainer: ".agencyDetails",
        })
        .then(() => {
          this.feedbackSumbit();
          this.getDetails();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 查看人员
    async lookUpPeople() {
      if (this.identityType == 1) {
        //用户
        let data = {
          userId: this.details.handleId,
        };
        const res = await getUserInfo(data);
        if (res.code === 200) {
          this.peoplePopup = true;
          this.userInfoVO = res.data;
        } else {
          this.$toast({
            message: `${res.message}`,
            getContainer: ".agencyDetails",
          });
        }
      } else {
        //承办人
        let data = {
          userId: this.details.userId,
        };
        const res = await getUserInfo(data);
        if (res.code === 200) {
          this.peoplePopup = true;
          this.userInfoVO = res.data;
        } else {
          this.$toast({
            message: `${res.message}`,
            getContainer: ".agencyDetails",
          });
        }
      }
    },
    // 判断是苹果还是安卓
    isPhone() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("android") != -1) {
        this.phone = true; //true是安卓 false是ios
      } else if (ua.indexOf("iphone") != -1) {
        this.phone = false;
      }
    },
  },
  created() {
    this.taskId = this.$route.params.id;
    this.identityType = this.$route.params.idType;
    this.getDetails();
    this.isPhone();
  },
  mounted() {
    this.$refs.progress.$el.style.width = "18vw";
    this.$refs.progress.resize();
  },
};
</script>

<style lang="scss" scoped>
.agencyDetails {
  ::v-deep .van-nav-bar {
    .van-nav-bar__content {
      height: 100px;
    }
    .van-nav-bar__title {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .van-ellipsis {
      overflow: visible;
    }
    .van-icon {
      color: #409eff;
      font-size: 36px;
    }
  }
  &-content {
    margin: 0 31px 0 29px;
    ::v-deep .cell-height {
      height: 107px;
      align-items: center;
      .van-cell__value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: visible;
        .van-progress {
          margin-right: 20px;
        }
        .van-icon {
          font-size: 35px;
          color: #4587ff;
          margin-left: 30px;
        }
        .custom-value {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        .custom-time {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
      .van-cell__title {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
    }
    // 步骤条
    .triangle_step {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
      li {
        cursor: pointer;
        height: 80px;
        background: #ebedf0;
        color: #000;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .step-text {
          font-size: 26px;
          font-weight: 800;
          z-index: 20;
          width: 70px;
          margin-left: 55px;
        }
      }
      li.cur {
        background: #67c23a;
        color: #fff;
      }
      li.clikc {
        background: #409eff;
        color: #fff;
      }

      /*三角形绘制*/
      .jiao {
        width: 0;
        height: 0;
        border-top: 40px solid transparent; /*高度一半*/
        border-left: 40px solid #ebedf0; /*调整宽度*/
        border-bottom: 40px solid transparent; /*高度一半*/
        position: absolute;
        right: -38px; /*跟宽度保持一致*/
        top: 0;
        z-index: 2;
      }
      .jiaoActive {
        width: 0;
        height: 0;
        border-top: 40px solid transparent; /*高度一半*/
        border-left: 40px solid #67c23a; /*调整宽度*/
        border-bottom: 40px solid transparent; /*高度一半*/
        position: absolute;
        right: -39px; /*跟宽度保持一致*/
        top: 0;
        z-index: 2;
      }
      /*大3个px的边 26-20/2*/
      .interval {
        width: 0;
        height: 0;
        border-top: 46px solid transparent; /*高度一半*/
        border-left: 46px solid #fff; /*调整宽度*/
        border-bottom: 46px solid transparent; /*高度一半*/
        position: absolute;
        right: -46px; /*跟宽度保持一致*/
        top: -6px;
        z-index: 1;
      }

      .jiaoclikc {
        border-top: 40px solid transparent; /*高度一半*/
        border-left: 40px solid #409eff; /*调整宽度*/
        border-bottom: 40px solid transparent; /*高度一半*/
      }
    }
    ::v-deep .van-collapse {
      .van-collapse-item {
        .van-cell--clickable {
          height: 100px;
          align-items: center;
          .van-cell__title {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              line-height: 50px;
            }
          }
        }
        .van-collapse-item__content {
          > .van-cell {
            padding: 30px 30px;
          }
        }
        .van-cell__title {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .van-cell__value {
          overflow: hidden;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: left;
          font-size: 24px;
          font-family: PingFang SC;
          color: #999999;
          line-height: 30px;
        }
        .btnBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;
          padding: 0 40px;
          .van-button {
            padding: 30px;
            font-size: 26px;
            font-weight: bold;
          }
        }
      }
    }
  }
  ::v-deep .peoplePopup {
    height: 500px;
    width: 600px;
    .userInfo {
      margin-top: 100px;
      box-sizing: border-box;
      // display: flex;
      // flex-wrap: wrap;
      padding-left: 50px;
      line-height: 20px;
      &-item {
        display: flex;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .user {
        padding: 10px 0 9px 0;
        display: flex;
        //border-bottom: 1px solid #f4f4f4;

        .headImg {
          position: relative;
          .grade {
            position: absolute;
            width: 28px;
            height: 28px;
            right: 10px;
            bottom: 210px;
          }

          .vipIcon {
            @include backgroundGroup("~assets/images/user-vip.png");
          }

          .svipIcon {
            @include backgroundGroup("~assets/images/user-svip.png");
          }
        }

        .user-text {
          margin-top: 10px;
          margin-left: 10px;
          line-height: 50px;
          .user-name {
            font-size: 50px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          .user-id {
            font-size: 35px;
            font-family: PingFang SC;
            color: #666666;
            margin-top: 9px;
          }
        }
      }
    }
  }
  ::v-deep .van-popup {
    .van-icon {
      font-size: 35px;
    }
  }
  ::v-deep .mouldPopup {
    .popup-table {
      margin: 70px 20px 20px;
      thead {
        tr {
          height: 50px;
          th:first-of-type {
            width: 250px;
          }
          th:nth-of-type(2) {
            width: 350px;
          }
          th:last-of-type {
            width: 100px;
          }
        }
      }
      tfoot {
        tr {
          min-height: 50px;
          font-size: 26px;
          color: #333333;
          td:first-of-type {
            text-align: center;
            padding: 20px;
          }
          td:nth-of-type(2) {
            padding: 20px;
            word-break: break-all;
          }
          td:last-of-type {
            text-align: center;
            font-size: 20px;
            color: #4587ff;
            font-weight: 400;
          }
        }
      }
      tr {
        th {
          border: 1px solid #ebeef5;
        }
        td {
          border: 1px solid #ebeef5;
        }
      }
    }
  }
  ::v-deep .van-dialog {
    width: 500px;
    height: 300px;
    .van-dialog__header {
      font-size: 40px;
      font-weight: bold;
    }
    .van-dialog__content {
      margin-top: 30px;
      height: 150px;
      display: flex;
      align-items: center;
      .van-dialog__message {
        font-size: 30px;
      }
    }
    .van-dialog__footer {
      height: 60px;
      .van-button {
        height: 60px;
        font-size: 30px;
      }
    }
  }
  // 日历组件
  ::v-deep .choiceTime {
    height: 50%;
    .van-calendar__header {
      .van-calendar__header-subtitle {
        font-size: 30px;
        font-weight: bold;
        height: 80px;
        line-height: 80px;
      }
      .van-calendar__weekdays {
        height: 80px;
        display: flex;
        align-items: center;
        .van-calendar__weekday {
          font-size: 30px;
        }
      }
    }
    .van-calendar__body {
      .van-calendar__month {
        .van-calendar__month-title {
          font-size: 30px !important;
        }
      }
      .van-calendar__days {
        .van-calendar__day {
          font-size: 30px !important;
        }
      }
    }
    .van-calendar__footer {
      height: 100px;
      .van-calendar__confirm {
        height: 80px;
        .van-button__content {
          font-size: 35px !important;
        }
      }
    }
  }
  //  反馈
  ::v-deep .feedbackPopup {
    .timeline {
      position: relative;
      text-align: left;
      margin-top: 70px;
    }
    .timeline-block {
      margin-top: 20px;
    }
    .timeline::before {
      content: "";
      position: absolute;
      top: 0;
      left: 18px;
      height: 100%;
      width: 2px;
      border-left: 2px solid #e4e7ed;
    }
    .timeTitle {
      font-size: 26px;
      font-weight: 400;
      color: #909399;
    }
    .ctimeline-img {
      position: relative;
      left: 12px;
      top: 15px;
      width: 13px;
      height: 13px;
      background-color: #e4e7ed;
      border-radius: 50%;
    }

    .cd-date {
      font-size: 12px;
      color: #cccccc;
    }

    .item {
      float: left;
      clear: both;
      margin-bottom: 23px;
    }
    .msgBox {
      border: 2px solid #eeeeee;
      width: 100%;
      padding: 20px;
      margin-top: 20px;
      font-size: 26px;
      .msg-content {
        line-height: 40px;
      }
      .jumpA {
        margin-top: 20px;
        color: #409eff;
      }
      .lastBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin: 20px;
        margin-top: 20px;
        p {
          display: flex;
          align-items: center;
          font-size: 24px;
          span {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 26px;
            color: #4587ff;
            img {
              margin-right: 10px;
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
          }
        }
        .btnGroup {
          display: flex;
          align-items: center;
          .van-tag {
            padding: 10px;
            font-size: 24px;
          }
          button {
            margin-left: 5px;
            background: #f56c6c;
            color: #ffff;
            border: none;
            padding: 5px 10px;
          }
        }
      }
    }

    .r-info {
      position: relative;
      top: 0px;
      left: 50px;
      width: 80%;
    }
  }
  // 轻提示
  ::v-deep .van-toast {
    padding: 20px !important;
    font-size: 30px !important;
  }
  // 提交反馈
  ::v-deep .submitfeedbackPopup {
    .content {
      margin-top: 70px;
      padding: 0 20px;
      textarea {
        resize: none;
        padding: 20px 20px 0;
        width: 88vw;
        height: 200px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
      }
      .uploadBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        .uploadBtn {
          height: 100px;
          border: 1px solid #e5e7ed;
          font-size: 30px;
        }
        .fileName {
          margin-left: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 450px;
          p {
            width: 350px;
            @include ellipsis();
          }
          i {
            color: #c8c9cc;
            cursor: pointer;
          }
        }
      }
      .van-cell {
        overflow: visible;
        padding: 20px 0 0;
        font-size: 26px;
        height: 100px;
        align-items: center;
        .van-field__label {
          width: 130px;
        }
        .van-radio {
          overflow: visible;
          display: flex;
          align-items: center;
          height: 100px;
          .van-icon {
            font-size: 25px;
          }
          .van-radio__icon {
            height: 30%;
          }
        }
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      padding: 0 20px;
      button {
        width: 49%;
        border: none;
        height: 100px;
        font-size: 40px;
        color: #fff;
        cursor: pointer;
        font-weight: bold;
      }
      .suer {
        background: #409eff;
      }
      .close {
        background: #fc5531;
      }
    }
  }
  // 修改时间
  ::v-deep .modifyTime {
    > .van-cell {
      margin-top: 80px;
      padding: 20px 20px;
      height: 100px;
      display: flex;
      align-items: center;
      .van-cell__title {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }
      .van-cell__value {
        font-size: 26px;
        overflow: visible;
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      padding: 0 20px;
      button {
        width: 49%;
        border: none;
        height: 100px;
        font-size: 40px;
        color: #fff;
        cursor: pointer;
        font-weight: bold;
      }
      .sure {
        background: #409eff;
      }
      .close {
        background: #fc5531;
      }
    }
  }
  .textFont {
    font-size: 24px;
    font-weight: 500;
    margin-right: 20px;
  }
  .bgcplain {
    padding: 5px;
    background-color: #f2e6e6;
    color: #ad5a5a;
    border: 1px solid #ad5a5a;
    border-radius: 5px;
  }
  .bgcwarning {
    padding: 5px;
    background-color: #fdf6ec;
    color: #e6a23c;
    border: 1px solid #e6a23c;
    border-radius: 5px;
  }
  .bgcdanger {
    padding: 5px;
    background-color: #fef0f0;
    color: #f78989;
    border: 1px solid #f78989;
    border-radius: 5px;
  }
  .bgcprimary {
    padding: 5px;
    background-color: #ecf5ff;
    color: #409eff;
    border: 1px solid #409eff;
    border-radius: 5px;
  }
  .bgcsuccess {
    padding: 5px;
    background-color: #f0f9eb;
    color: #6ec543;
    border: 1px solid #6ec543;
    border-radius: 5px;
  }
}
</style>
