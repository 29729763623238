<template>
  <div
    class="netfile"
    @click="debounce(openFolder, delay)"
    @touchmove="watchmove"
    @touchend="endPress"
  >
    <div :class="['file-item-two', showZome ? 'netfileitem' : '']">
      <div class="file-item">
        <div class="file-item-image">
          <img
            :src="require(`@/assets/images/disk/Icons/${file.nub}.png`)"
            alt=""
          />
        </div>
        <div class="file-item-main">
          <h1 class="tit">{{ file.filename }}</h1>
          <div class="detail">
            <!-- <span class="detail-count" v-if="file.type == 1">5项</span> -->
            <!-- 时间 -->
            <span class="detail-time">{{
              file.updateTime
                .replace(
                  file.updateTime.substring(file.updateTime.lastIndexOf(":")),
                  ""
                )
                .replace("-", ".")
                .replace("-", ".")
            }}</span>
          </div>

          <div
            class="op-icon"
            v-show="file.filetype == 0 && file.isDeleted == -1"
          >
            <img
              class="op-icon-arrow"
              src="../../assets/images/disk/net-arrow-r.png"
              alt=""
            />
          </div>
          <!-- <div class="op-icon" v-on:click.stop="opFile">
          <img
            class="op-icon-more"
            src="../../assets/images/disk/net-op-big.png"
            alt=""
          />
        </div> -->
          <div
            class="op-icon"
            @click.stop="mulchoice(file)"
            v-show="file.isDeleted != -1 && !isMulc"
          >
            <img
              class="op-icon-mulc"
              src="../../assets/images/disk/net-op-mulc.png"
              alt=""
            />
          </div>
          <div
            class="op-icon"
            v-show="isMulc && file.isDeleted != -1"
            @click.stop="mulchoice(file)"
          >
            <img
              class="op-icon-mulc"
              src="../../assets/images/disk/net-op-mulc-active.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetFileItem",
  props: {
    file: Object,
  },
  data() {
    return {
      nub: "01",
      loop: null,
      //多选
      isMulc: false,
      rowItem: {},
      showZome: false,
      //选框状态
      checkMulc: false,
      //纵坐标
      sy: 0,
      //打开文件定时
      open: {},
      //防抖定时
      delay: 600,
    };
  },

  computed: {
    //获取文件后缀
    getFileType() {
      return this.file.url !== null
        ? this.file.url.substring(this.file.url.lastIndexOf("."))
        : "";
    },
  },
  methods: {
    debounce(fn, delay) {
      this.showZome = true;
      this.$emit("openFolder", this.file);

      if (this.open.timer) {
        // 进入此分支说明：当前正在一个计时周期中，并且再次触发了事件,取消当前计时，重新开始计时
        clearTimeout(this.open.timer);
      }
      // 进入此分支说明：当前没有计时，则开始新的计时
      this.open.timer = setTimeout(fn, delay);
      setTimeout(() => {
        this.showZome = false;
      }, 100);
    },
    //打开文件夹
    openFolder() {},
    //操作文件
    opFile() {
      this.$emit("opFile", this.file);
    },

    //松开
    endPress() {
      clearTimeout(this.loop);
    },
    //移动
    watchmove(e) {
      let ey = e.touches[0].clientY;
      if (this.sy != ey) {
        if (!this.isMulc) {
          clearTimeout(this.loop);
        }
      }
    },
    //改变选框状态
    mulchoice(val) {
      let arr = {
        id: val.id,
      };
      this.rowItem = arr;
      this.isMulc = !this.isMulc;
      if (!this.isMulc) {
        val.parentId = 1;
        this.$emit("mulchoice", val);
      } else {
        val.parentId = 0;
        this.$emit("mulchoice", val);
      }
    },
  },
  watch: {
    isMulc() {
      if (this.isMulc) {
        this.delay = 0;
      } else {
        this.delay = 600;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.netfile {
  // overflow-y: scroll;
  .file-item-two {
    width: 100%;
    .file-item {
      padding: 20px 30px 0;
      display: flex;
      flex-wrap: nowrap;
      margin-top: 30px;
      &-image {
        img {
          width: 90px;
          height: 90px;
        }
      }
      &-main {
        position: relative;
        flex: 1;
        margin-left: 30px;
        padding-top: 7px;
        .tit {
          width: 460px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .detail {
          margin-top: 16px;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          &-count {
            margin-right: 30px;
          }
        }
        .op-icon {
          position: absolute;
          right: 0;
          top: calc(50% - 25px);
          width: 60px;
          height: 60px;
          text-align: center;
          &-arrow {
            width: 18px;
            height: 32px;
            margin: 12px 6px 0 0;
          }
          &-more {
            width: 6px;
            height: 30px;
            margin-right: 6px;
          }
          &-mulc {
            width: 28px;
            height: 28px;
            margin: 12px 6px 0 0;
          }
        }
      }
    }
  }
}
.netfileitem {
  background-color: #f5f7fa;
}
.op-icon-mulc {
  width: 28px;
  height: 28px;
}
.op-icon {
  width: 28px !important;
  height: 28px !important;
}
</style>
