<template>
  <div class="functionality">
    <van-nav-bar
      class="van-bar"
      @click-right="onArrow"
      right-text="返回"
      title="功能板块"
    >
    </van-nav-bar>
    <div class="header">
      <van-swipe class="my-swipe" :autoplay="5000" @change="onChange">
        <van-swipe-item
          ><img src="../../assets/images/home/03.png" alt=""
        /></van-swipe-item>
        <van-swipe-item
          ><img src="../../assets/images/home/01.png" alt=""
        /></van-swipe-item>
        <van-swipe-item
          ><img src="../../assets/images/home/05.png" alt=""
        /></van-swipe-item>
        <van-swipe-item
          ><img src="../../assets/images/home/02.png" alt=""
        /></van-swipe-item>
        <van-swipe-item
          ><img src="../../assets/images/home/04.png" alt=""
        /></van-swipe-item>
      </van-swipe>
    </div>
    <div class="fooder">
      <div v-for="(item, index) in active" :key="index">
        <div v-if="index == id">
          <h2>
            <img :src="item.src" alt="" /> <span>{{ item.title }}</span>
          </h2>
          <p>{{ item.content }}</p>
          <h3>{{ item.title1 }}</h3>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: [
        {
          id: 0,
          src: require("../../assets/images/home/21.png"),
          title: "溯源",
          content:
            "溯源是对文字作品、图片、视频、文件等版权作品的追根溯源，通过匹配电子数据指纹，进而确定最早的原创作者。反抄袭数据库，单一电子数据指纹，仅保留最早的原创作者。",
          text: "百度内容知名博主王小锤，其下有多位签约作者，严格要求原创作品，却频频遭遇“剪刀作”、跨平台“洗稿作”，甚至遭遇作品下架，即便发现抄袭也因为稿费已付难以追回，如何最快速有效判断原创率是其最重要的工作。这种情况下，可以采用反抄袭的溯源功能，快速评判可供参考的原创率结果。目前，文字作品溯源价格是0.3元/千字。",
          title1: "典型场景",
        },
        {
          id: 1,
          src: require("../../assets/images/home/21.png"),
          title: "原创存证",
          content:
            "原创存证是对文字作品、图片、视频、文件等版权作品，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。",
          text: "小红书知名大V王大锤，凌晨三点半终于完稿了“论内容生产力的重塑经验”一文，热度蹭蹭蹭往上涨，随即遭遇多个平台的未授权转载、洗稿等行为，诉诸法庭则举证繁杂、时间精力消耗极大。借助时间定位到秒级的原创存证功能则，可以轻松应对版权侵权诉讼，而且在作品后晒出对应的原创存证证书也可以起到很好的威慑作用。目前，所有原创存证，均为0.98元/篇次。",
          title1: "典型场景",
        },
        {
          id: 2,
          src: require("../../assets/images/home/21.png"),
          title: "智能网盘",
          content:
            "智能网盘主要是存储原创存证、云文档、收藏文档等常用存储功能。智能方面，主要是在用户同意授权情况下，可以对图片、PDF文档、CA",
          text: "大学生王微锤，临近毕业在各大图书馆、网站搜集了一堆材料，word文档、PDF文档、PPT文档、CAJ文档等各种类型都有，临到用时想不起来相关内容在哪个文档。使用智能网盘，则可以轻松获得相应内容，并直接推荐出来（部分功能需要下载成语客户端）。",
          title1: "典型场景",
        },
        {
          id: 3,
          src: require("../../assets/images/home/21.png"),
          title: "版权收益",
          content:
            "版权收益是指原创作者可以通过作品授权，自主设定授权价格，获得现金、字符包等收益。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。",
          text: "微信公众号知名博主王中锤，耗费三月有余创作了5000字的《时间就是第一生产力》，文笔优美、金句频频，随后被各种花式洗稿、拆分得七零八落，投诉、举报乃至诉讼均难以奏效。王中锤将《时间就是第一生产力》，上传至写手智能科技公司版权作品数据库。随后，某媒体机构在审核其拟刊发的3000字的《时间生产力》一文时，向写手智能科技公司支付了3000个字符，溯源发现其中2800字符均出自于王中锤的《时间就是第一生产力》，则王中锤可以自写手智能科技公司转获得2800字符，该字符可以直接提现。目前，字符提现价格为0.25元/千字。",
          title1: "典型场景",
        },
        {
          id: 4,
          src: require("../../assets/images/home/21.png"),
          title: "版权服务",
          content:
            "版权服务主要是围绕知识产权提供版权实务、信息汇编、实名认证、收录确权、会员服务等一站式服务，由知名的知识产权领域执业律师提供标准化的流程公开、价格透明的咨询与实务服务，是原创作者、知识博主、杂志期刊与媒体机构、新媒体的重要助手。",
          text: "原创作者王巨锤的多个作品被众多平台、网站侵权，但其个人时间精力都有限，压根难以维权，但是委托律师又觉得费用高昂、难以掌控评判律师实际工作，因此只能得过且过、眼不见为净。但在反抄袭软件上的执业律师，则可以大为放心，全部按照标准化的流程公开进行处理，事项进展可以像看快递到哪了一样方便。更核心的，你可以根据侵权事项复杂程度、利益受损程度、价格承受能力，自主选择不同价位的律师，所有价格公开透明。更实在的是，你可以分阶段委托、分阶段付费，随时解聘随时续聘，历史数据与工作流程不受影响、无缝衔接，营造超级安心舒适的用户体验感。目前，知识产权方面的信息汇编是9.9元/年。",
          title1: "典型场景",
        },
      ],
      id: 0,
    };
  },
  methods: {
    onArrow() {
      this.$router.go(-1);
    },
    onChange(index) {
      this.id = index;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.functionality {
  width: 100%;
  height: 92px;
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  .header {
    width: 100%;
    height: 460px;
    padding: 0 55px;
    box-sizing: border-box;
    .my-swipe {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .fooder {
    width: 100%;
    padding: 0 32px 40px;
    box-sizing: border-box;
    h2 {
      display: inline-block;
      vertical-align: middle;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 40px;
      color: #333333;
      img {
        width: 15px;
        height: 15px;
      }
    }
    p {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 40px;
    }
    h3 {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      margin: 58px 0 27px;
    }
  }
}
::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text {
  color: #333333;
  font-size: 28px;
}

::v-deep .van-swipe__indicators .van-swipe__indicator {
  width: 10px;
  height: 10px;
  background-color: #7aa9ff;
  border-radius: 50%;
}
/deep/ .van-swipe__track {
  height: 470px !important;
  margin-top: 21px;
}
/deep/ .van-swipe-item {
  height: 400px;
  //   margin-left: 25px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
