<template>
  <div class="regardsMy">
    <van-nav-bar
      class="van-bar"
      @click-right="onArrow"
      right-text="返回"
      title="关于我们"
    >
    </van-nav-bar>
    <div class="banner-big">
      <div class="banner">
        <div class="banner-head">
          <img src="../../assets/images/home/12.png" alt="" />
        </div>
        <div class="banner-ban">
          <h2>深圳写手智能科技有限公司</h2>
          <h3>Shenzhen writer Intelligent Technology Co., Ltd</h3>
          <div>
            <p>
              深圳写手智能科技有限公司成立于2020年06月08日，是一家以大数据知识服务为定位，为内容创作生态提供高品质服务的科创公司。目前，主打产品是“反抄袭”“成语”两个软件，均将陆续推出网站版本、PC版本、APP版本。
            </p>
            <p>
              “反抄袭”致力于为原创文字作品、图片、视频、文件等提供电子数据指纹，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。同时，提供智能网盘、版权收益、版权服务等一站式知识产权服务，并有执业律师提供标准化的流程公开、价格透明的咨询与实务服务，是原创作者、知识博主、杂志期刊与媒体机构、新媒体等的尚方宝剑。
            </p>
            <p>
              “成语”致力于为用户在内容创作时推荐出合适的、高品质的内容，自动标注内容出处来源，形成高效规范的引文尾注，以更好促进尊重原创、激发灵感。同时，提供金句、模板、智能网盘、一键采集、数据分析、溯源、OCR、划词成语等丰富的内容创作配套工具，是大学生、教师、公职人员、记者、编辑、文员、笔杆子等的必备神器。
            </p>
          </div>
        </div>
      </div>
      <div class="fooder">
        <div class="div" @click="location">
          软件定位 <img src="../../assets/images/home/20.png" alt="" />
        </div>
        <div class="div" @click="functionality">
          功能模块 <img src="../../assets/images/home/20.png" alt="" />
        </div>
        <div class="div" @click="Agreement">
          用户协议 <img src="../../assets/images/home/20.png" alt="" />
        </div>
        <div @click="Privacy">
          隐私政策 <img src="../../assets/images/home/20.png" alt="" />
        </div>
      </div>
      <div class="fooder-bd">
        <div>
          <h3>联系我们</h3>
          <div class="fooder-left">
            <div class="lianxi">
              <div class="box">联系电话：</div>
              <div>E-mail:</div>
            </div>
            <div class="email">
              <div>
                <span>0755-23345494 </span>
                <span class="box1">13828584514（颜先生）</span>
              </div>
              <div class="span">
                <span>writemall@126.com</span>
              </div>
            </div>
          </div>
        </div>
        <div class="fooder-right">
          <div class="box">
            <img src="../../assets/images/home/14.png" alt="" />
            <div>公众号</div>
          </div>
          <div class="img">
            <img src="../../assets/images/home/15.png" alt="" />
            <div>企业微信</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    location() {
      this.$router.push({
        name: "location",
      });
    },
    functionality() {
      this.$router.push({
        name: "functionality",
      });
    },
    Agreement() {
      this.$router.push({
        name: "Agreement",
      });
    },
    Privacy() {
      this.$router.push({
        name: "Privacy",
      });
    },
    onArrow() {
      if (window.android) {
        const jsonStr = {
          action: "back",
        };
        window.android.postMessage(JSON.stringify(jsonStr));
      } else if (window.webkit) {
        const jsonStr = {
          action: "back",
        };
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(jsonStr)
        );
      }
      // this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.regardsMy {
  width: 100%;
  .banner-big {
    max-height: calc(100vh - 12.4vw);
    overflow-y: auto;
  }
  .van-bar {
    height: 92px;
    line-height: 92px;
  }
  .banner {
    width: 100%;
    .banner-head {
      width: 100%;
      height: 272px;
      margin: 31px 0 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner-ban {
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 0 28px 60px 30px;
      box-sizing: border-box;
      h2 {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #999999;
        opacity: 0.3;
        margin-bottom: 63px;
      }
      p {
        text-indent: 2em;
        text-align: left;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
      }
    }
  }
  .fooder {
    width: 100%;
    div {
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
      line-height: 116px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      img {
        float: right;
        width: 12px;
        height: 22px;
        margin-top: 47px;
      }
    }
    .div {
      border-bottom: 1px solid #eef0f4;
    }
  }
  .fooder-bd {
    display: flex;
    width: 100%;
    height: 287px;
    background-color: #192f68;
    padding: 50px 20px;
    box-sizing: border-box;
    .fooder-left {
      display: flex;
    }
    h3 {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .lianxi {
      margin-top: 36px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      height: 60px;
      width: 16.27vw;
      color: #ffffff;
      span {
        font-weight: 400;
        color: #fefefe;
      }
      .box {
        display: inline-block;
        width: 200px;
        height: 100%;
        // margin-bottom: 5px;
      }
      @media screen and(max-width:404px) {
        .box {
          display: inline-block;
          width: 200px;
          height: 100%;
          margin-bottom: 3px;
        }
      }
    }
    .email {
      margin-top: 38px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      .box1 {
        display: inline-block;
        margin-top: 5px;
      }
      span {
        font-weight: 400;
      }
      .span {
        margin-top: 10px;
      }
    }
    .fooder-right {
      display: flex;
      // width: 300px;
      img {
        width: 122px;
        height: 122px;
      }
      .img {
        text-align: center;
        margin-left: 40px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        div {
          margin-top: 20px;
        }
      }
      .box {
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        margin-left: 10px;
        color: #ffffff;
        div {
          margin-top: 20px;
        }
      }
    }
  }
}
::v-deep .van-nav-bar__content {
  height: 100%;
}
::v-deep .van-nav-bar__title {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
::v-deep .van-nav-bar__text {
  color: #333333;
  font-size: 28px;
}
::v-deep .van-swipe__indicators .van-swipe__indicator {
  width: 38px;
  height: 7px;
  border-radius: 3.5px;
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 1px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
