import Vue from "vue";
import vuex from "vuex";

Vue.use(vuex);

const state = {
  sidebarActive: 0,
  onlineBasePath: "https://fafs.antiplagiarize.com/",
};

const mutations = {
  ChangeSideBarId(state, payload) {
    state.sidebarActive = payload;
  },
};

const actions = {};

export default new vuex.Store({
  state,
  mutations,
  actions,
});
