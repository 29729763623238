<template>
  <div class="network">
    <van-nav-bar
      :border="false"
      v-show="showTopCheckBar1"
      class="van-bar"
      @click-left="onArrow"
      :title="headerTitle"
      :left-arrow="headerTitle != '智能网盘'"
    >
      <template #right>
        <img
          @click="onDelete"
          class="class-img"
          style="margin-right: 10px"
          src="@/assets/images/disk/nav-bar-deleit.png"
          alt=""
        />

        <van-popover
          placement="bottom-end"
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
        >
          <template #reference>
            <img
              class="class-img"
              src="@/assets/images/disk/nav-bar-reight.png"
              alt=""
            />
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <div
      :class="['filechecktop', showTopCheckBar ? 'filechecktop-active' : '']"
      v-show="showTopCheckBar"
    >
      <van-nav-bar
        :border="false"
        right-text="取消"
        id="regihtText"
        :title="opres.length ? `已选中${opres.length}个文件` : '已选中0个文件'"
        @click-right="onCheckCancel"
      />
      <!-- <van-nav-bar
        :right-text="isAll ? '取消全选' : '全选'"
        left-text="取消"
        :title="opres.length ? `已选中${opres.length}个文件` : '已选中0个文件'"
        @click-left="onCheckCancel"
        @click-right="onAllChecks"
      /> -->
    </div>
    <!-- 搜索 -->
    <div class="nav-wrap" v-show="!showTopCheckBar">
      <navigation-bar
        class="topfix"
        ref="topInput"
        @search="handleSearch"
        :placeholder="searchtip"
      >
      </navigation-bar>
    </div>
    <div class="sapnSort" v-show="!showTopCheckBar" v-if="showTopCheck">
      <span @click="srotShows">
        <span>按时间排序</span> <van-icon name="arrow-down" />
      </span>
    </div>
    <div class="sapnSort1" v-show="showTopCheckBar">
      <span
        class="sapnReight"
        v-text="isAll ? '取消全选' : '全选'"
        @click="onAllChecks"
      ></span>
    </div>
    <div class="newFile" @click="onNewBuild">
      <img src="../../assets/images/disk/icon/10.png" alt="" />
    </div>
    <!-- 内容 -->
    <van-pull-refresh
      v-model="refreshing"
      success-text="刷新成功"
      @refresh="onRefresh"
      :disabled="showTopFileBack"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <div
          class="network-panel"
          :style="{
            height: topFolder.length <= 6 ? 'calc(100vh - 214px)' : '',
          }"
        >
          <net-file-item
            ref="netfile"
            v-for="(item, index) in topFolder"
            :file="item"
            :key="index"
            @openFolder="openFolder"
            @opFile="opFile"
            @mulchoice="mulchoice"
          />
        </div>
        <!-- 
        <div v-show="upload" class="box4">加载中...</div>
        <div v-show="without" class="box4">没有更多了</div> -->
      </van-list>
    </van-pull-refresh>
    <van-popup
      class="newBuildPopup"
      v-model="NewBuildshow"
      position="bottom"
      round
      :style="{ height: '18%' }"
    >
      <div class="shangchuan">
        <span class="box" @click="newJian">
          <img class="img" src="../../assets/images/disk/icon/11.png" alt="" />
          <div>新建文件夹</div>
        </span>
        <van-uploader
          :accept="phone == true ? '*/*' : ''"
          multiple
          class="box1"
          :after-read="afterRead"
        >
          <span>
            <img
              class="img"
              src="../../assets/images/disk/icon/12.png"
              alt=""
            />
            <div>上传</div>
          </span>
        </van-uploader>
      </div>
    </van-popup>
    <!-- 排序 -->
    <van-popup
      class="newBuildPopup paixu"
      v-model="srotShow"
      position="bottom"
      round
    >
      <div class="srotTime">
        <div :class="[nub1 == 1 ? 'srotTimeShow' : '']" @click="srotTime(1)">
          按时间排序
        </div>

        <div :class="[nub1 == 2 ? 'srotTimeShow' : '']" @click="srotTime(2)">
          按大小排序
        </div>
      </div>
    </van-popup>
    <!-- 分享 -->
    <!-- <van-popup
          class="shareFile"
          v-model="shareFile"
          position="bottom"
          round
          :style="{ height: '18%' }"
        >
          <div class="shangchuan">
            <span class="box1">
              <img
                class="img"
                src="../../assets/images/disk/icon/15.png"
                alt=""
              />
              <div>复制链接</div>
            </span>
            <span class="box1">
              <img
                class="img"
                src="../../assets/images/disk/icon/16.png"
                alt=""
              />
              <div>微信</div>
            </span>
            <span class="box1">
              <img
                class="img"
                src="../../assets/images/disk/icon/17.png"
                alt=""
              />
              <div>QQ</div>
            </span>
            <span class="box1">
              <img
                class="img"
                src="../../assets/images/disk/icon/18.png"
                alt=""
              />
              <div>微博</div>
            </span>
          </div>
        </van-popup> -->
    <!-- 移动 -->
    <van-popup v-model="showMoving" round position="bottom">
      <div class="move">
        <div class="top">
          <span
            class="curfile"
            v-if="moveFolder.parentid && moveFolder.parentid != 0"
            @click="backPrevFolder"
          >
            <van-icon name="arrow-left" />
          </span>
          移动文件
          <span class="move-close" @click="hideMoveFile"
            ><img src="../../assets/images/disk/net-m-close.png"
          /></span>
        </div>
        <div class="filelist">
          <div
            v-for="(item, index) in moveFolder.children"
            :key="index"
            class="netfile"
            @click="selectFolder(item)"
          >
            <div class="file-item">
              <div class="file-item-image">
                <img
                  src="../../assets/images/disk/net-file.png"
                  alt=""
                  v-if="item.filetype == 0 && item.isDeleted != -1"
                />
                <img
                  src="../../assets/images/disk/net-file-system.png"
                  alt=""
                  v-if="item.filetype == 0 && item.isDeleted == -1"
                />
              </div>
              <div class="file-item-main">
                <h1 class="tit">{{ item.filename }}</h1>
                <div class="detail">
                  <span class="detail-time">{{ item.updateTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-move">
          <span class="btns" @click="moveToHere">移动到此</span>
        </div>
      </div>
    </van-popup>

    <!-- 新建文件夹 -->
    <van-popup
      class="newBuildPopup"
      v-model="NewBuildshow1"
      position="bottom"
      :style="{ height: '67%' }"
    >
      <van-nav-bar
        :border="false"
        :title="title"
        left-text="返回"
        right-text="完成"
        @click-left="newBuildLeft"
        @click-right="newBuildRight"
      />
      <div class="newBuild-content">
        <img src="../../assets/images/disk/Icons/03.png" alt="" />
        <div class="form">
          名称：<input
            ref="plateInput"
            type="text"
            @focus="
              title == '新建文件夹' ? (newBuildValue = '') : newBuildValue
            "
            v-model="newBuildValue"
          />
        </div>
      </div>
    </van-popup>
    <van-popup round v-model="showNotice">
      <div
        :class="[
          'over',
          setting == '回收站删除' ||
          setting == '批量删除' ||
          setting == '清空回收站' ||
          setting == '批量彻底删除' ||
          setting == '批量恢复' ||
          setting == '分享' ||
          setting == '重命名' ||
          setting == '批量下载'
            ? 'over-del'
            : '',
        ]"
      >
        <div class="content">
          <div class="tit">
            <span
              >{{ settingName
              }}<span
                v-if="
                  setting == '回收站删除' ||
                  setting == '批量删除' ||
                  setting == '清空回收站' ||
                  setting == '批量彻底删除' ||
                  setting == '批量恢复' ||
                  setting == '批量下载'
                "
                >文件</span
              >
            </span>
          </div>
          <div class="main">
            <input
              type="text"
              v-model="rename"
              :placeholder="rename"
              v-show="setting == '重命名'"
            />
            <p class="recovery" v-show="setting == '批量恢复'">确认恢复吗?</p>
            <p class="deltxt" v-show="setting == '批量删除'">
              30天内可在回收站中找回已删文件
            </p>
            <p
              class="delforever"
              v-if="setting == '回收站删除' || setting == '批量彻底删除'"
            >
              是否彻底删除文件？
            </p>
            <p class="delforever" v-if="setting == '清空回收站'">
              是否清空回收站？
            </p>
            <p class="sharetxt" v-show="setting == '分享'">确定分享文件？</p>
            <p class="downtxt" v-show="setting == '批量下载'">确定下载吗？</p>
          </div>
        </div>
        <div class="btns">
          <span class="cancel" @click="cancel">取消</span>
          <span
            class="sure"
            @click="sure"
            v-if="
              setting == '回收站删除' ||
              setting == '批量删除' ||
              setting == '批量彻底删除'
            "
            >确认删除</span
          >
          <span
            class="sure"
            @click="sure"
            v-if="
              setting != '回收站删除' &&
              setting != '批量删除' &&
              setting != '批量彻底删除'
            "
            >确定</span
          >
        </div>
      </div>
    </van-popup>
    <transition name="mulc">
      <div v-if="NewEducation" class="mul-op">
        <div
          class="mul-op-item"
          @click="downloadFileBeach"
          v-if="recycleID != 10 && viewingShow"
        >
          <img src="../../assets/images/disk/net-op-move-blue.png" alt="" />
          <p>下载</p>
        </div>
        <div class="mul-op-item" @click="delFileBeach" v-if="recterd != 1">
          <img src="../../assets/images/disk/net-op-del-blue.png" alt="" />
          <p>删除</p>
        </div>
        <div class="mul-op-item" @click="shareFileBeach" v-if="recycleID != 10">
          <img src="../../assets/images/disk/icon/13.png" alt="" />
          <p>分享</p>
        </div>
        <!-- <div class="mul-op-item" @click="collectFileBeach">
              <img
                src="../../assets/images/disk/net-op-collect-blue.png"
                alt=""
              />
              <p>收藏</p>
            </div> -->
        <div class="mul-op-item" @click="recoveryBeach" v-if="recycleID == 10">
          <img src="../../assets/images/disk/net-op-recycle-blue.png" alt="" />
          <p>恢复</p>
        </div>
        <div
          class="mul-op-item"
          @click="physicallyDelFile"
          v-if="recycleID == 10"
        >
          <img src="../../assets/images/disk/net-op-del-blue.png" />
          <p>清空回收站</p>
        </div>
        <div
          class="mul-op-item"
          @click="renameFileBeach"
          v-if="recycleID != 10 && viewingShow"
        >
          <img src="../../assets/images/disk/icon/14.png" alt="" />
          <p>重命名</p>
        </div>
        <div
          class="mul-op-item"
          @click="moveFile"
          v-if="recycleID != 10 && viewingShow"
        >
          <img src="../../assets/images/disk/net-op-move-blue.png" alt="" />
          <p>移动</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// 导航栏组件
import NavigationBar from "@/components/disk/NavigationBar.vue";
import NetFileItem from "@/components/disk/NetFileItem.vue";
import {
  getDiskList,
  sideBarType,
  addFiles,
  delFiles,
  previewFile,
  changeFilesName,
  directory,
  moveFile,
  recycleBinDel,
  recycleBinReduction,
  recycleBinEmpty,
  mergeFile,
} from "api/disk";
import { Toast } from "vant";
export default {
  components: {
    NavigationBar,
    NetFileItem,
  },
  data() {
    return {
      dataSelect: "",
      viewingShow: true,
      textCentent: 1,
      upload: false,
      without: false,
      showTopCheck: true,
      settingName: "",
      fileList: [],
      nub1: 0,
      headerTitle: "智能网盘",
      recycleID: 0,
      recterd: 0,
      ID: 0,
      //移动目录数据
      moveFolder: [],
      tabType: 0,
      // 分享
      shareFile: false,
      //是否全选
      isAll: false,
      srotShow: false,
      //操作确认提示
      showNotice: false,
      NewEducation: false,
      title: "新建文件夹",
      newBuildValue: "",
      //重命名内容
      rename: "",
      arr: [],
      showMoving: false,
      //新建弹窗
      NewBuildshow: false,
      NewBuildshow1: false,
      fileItemname: {},
      showPopover: false,
      actions: [
        {
          text: "最近",
          icon: require("../../assets/images/disk/icon/01.png"),
        },
        { text: "存证", icon: require("../../assets/images/disk/icon/02.png") },
        { text: "授权", icon: require("../../assets/images/disk/icon/03.png") },
        {
          text: "溯源报告",
          icon: require("../../assets/images/disk/icon/04.png"),
        },
        { text: "文档", icon: require("../../assets/images/disk/icon/05.png") },
        { text: "图片", icon: require("../../assets/images/disk/icon/06.png") },
        { text: "视频", icon: require("../../assets/images/disk/icon/07.png") },
        { text: "其他", icon: require("../../assets/images/disk/icon/08.png") },
        // {
        //   text: "隐私空间",
        //   icon: require("../../assets/images/disk/icon/09.png"),
        // },
      ],
      //是否刷新页面
      refreshing: false,
      searchtip: "搜索网盘文件",
      opres: [],
      disMove: [],
      //显示顶部全选栏
      showTopCheckBar: false,
      showTopCheckBar1: true,
      //显示更多操作
      showMoreChoice: false,
      idx: 1,
      idx1: 1,
      //显示顶部文件返回
      showTopFileBack: false,
      whetherChildren: false, //判断onload执行
      //是否加载
      loading: false,
      loading1: false,
      //是否加载完成
      finished: false,
      finished1: false,
      //顶层目录数据
      topFolder: [],
      //页面请求不成功
      torefresh: false,
      //某项文件操作
      setting: "",
      //没有文件
      unfile: false,
      parentId: "0", //顶级父id
      stry: 0,
      stry1: 0,
      phone: true,
    };
  },
  created() {
    let code = JSON.parse(window.localStorage.getItem("code"));
    this.without = false;
    if (code == 3) {
      this.onSelect1({
        name: "数据采集文档",
        pageIndex: 1,
        pageSize: 30,
      });
    } else {
      let id = this.$store.state.sidebarActive;
      this.$nextTick(() => {
        if (id == 0) {
          this.getDiskLists({
            id: 0,
            pageIndex: 1,
            pageSize: 30,
          });
        } else {
          this.arr = id;
          this.headerTitle = this.arr[this.arr.length - 1].filename;
          this.getDiskLists({
            id: this.arr[this.arr.length - 1].id,
            pageIndex: 1,
            pageSize: 30,
          });
          this.$store.commit("ChangeSideBarId", 0);
        }
      });
    }

    //暴露返回上级目录方法给Android调用
    window.onTopFileBack = this.onTopFileBack;
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("android") != -1) {
      this.phone = true; //true是安卓 false是ios
    } else if (ua.indexOf("iphone") != -1) {
      this.phone = false;
    }
    this.idx = 1;
    this.idx1 = 1;
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    onLoad() {
      this.loading = true;
      let timer = "";
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (this.refreshing) {
          this.refreshing = false;
        }
        if (this.textCentent == 1) {
          this.idx = this.idx + 1;
          let params = {
            id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : 0,
            pageIndex: this.idx,
            pageSize: 30,
          };

          this.getDiskList(params);
        } else {
          this.idx1 = this.idx1 + 1;
          let params = {
            name: this.textCentent,
            pageSize: 30,
            pageIndex: this.idx1,
          };
          this.onSelect1(params);
        }

        this.loading = false;
      }, 1000);
    },
    scrollHandle(event) {
      // this.upload = true;
      // this.without = false;
      let el = event.target;

      if (el.scrollTop == 0) {
        this.showTopFileBack = false;
      } else {
        this.showTopFileBack = true;
      }

      // if (el.scrollTop + el.clientHeight + 1 >= el.scrollHeight) {
      //   console.log(this.stry);
      //   if (this.stry == 10) {
      //     console.log(111);
      //     if (this.textCentent == 1) {
      //       this.idx = this.idx + 1;
      //       let params = {
      //         id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : 0,
      //         pageIndex: this.idx,
      //         pageSize: 30,
      //       };
      //       setTimeout(() => {
      //         this.getDiskList(params);
      //       }, 1000);
      //     } else {
      //       this.idx1 = this.idx1 + 1;
      //       let params = {
      //         name: this.textCentent,
      //         pageSize: 30,
      //         pageIndex: this.idx1,
      //       };
      //       setTimeout(() => {
      //         this.onSelect1(params);
      //       }, 1000);
      //     }
      //   } else {
      //     console.log(222);
      //     setTimeout(() => {
      //       this.idx = 1;
      //       this.idx1 = 1;
      //       this.upload = false;
      //       this.without = true;
      //     }, 2000);
      //   }
      // }
    },
    async onSelect1(action) {
      let data = await sideBarType(action);
      data.data.records.forEach((item) => {
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = "01";
            } else {
              item.nub = "03";
            }
            break;
          case 1:
            item.nub = "04";
            break;
          case 2:
            item.nub = "07";
            break;
          case 3:
            item.nub = "08";
            break;
          case 4:
            item.nub = "05";
            break;
          case 5:
            item.nub = "06";
            break;
          case 6:
            item.nub = "07";
            break;
          case 7:
            item.nub = "04";
            break;
          case 8:
            item.nub = "05";
            break;
          case 9:
            item.nub = "14";
            break;
          case 10:
            item.nub = "14";
            break;
          case 11:
            item.nub = "09";
            break;
          case 12:
            item.nub = "09";
            break;
          case 13:
            item.nub = "09";
            break;
          case 14:
            item.nub = "10";
            break;
          case 15:
            item.nub = "01";
            break;
          case 16:
            item.nub = "09";
            break;
          case 17:
            item.nub = "12";
            break;
          case 18:
            item.nub = "12";
            break;
          case 19:
            item.nub = "13";
            break;
          case 20:
            item.nub = "11";
            break;
          case 21:
            item.nub = "15";
            break;
          case 22:
            item.nub = "11";
        }
        this.topFolder.push(item);
        this.stry = data.data.total;
        this.stry1 = this.topFolder.length;
      });
      if (this.stry1 == this.stry) {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // setTimeout(() => {
      //   if (this.stry != 10) {
      //     this.upload = false;
      //     this.without = true;
      //   }
      // }, 1000);
    },
    //   获取列表数据
    async getDiskList(value) {
      let data = await getDiskList(value);
      data.data.records.forEach((item) => {
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = "01";
            } else {
              item.nub = "03";
            }
            break;
          case 1:
            item.nub = "04";
            break;
          case 2:
            item.nub = "07";
            break;
          case 3:
            item.nub = "08";
            break;
          case 4:
            item.nub = "05";
            break;
          case 5:
            item.nub = "06";
            break;
          case 6:
            item.nub = "07";
            break;
          case 7:
            item.nub = "04";
            break;
          case 8:
            item.nub = "05";
            break;
          case 9:
            item.nub = "14";
            break;
          case 10:
            item.nub = "14";
            break;
          case 11:
            item.nub = "09";
            break;
          case 12:
            item.nub = "09";
            break;
          case 13:
            item.nub = "09";
            break;
          case 14:
            item.nub = "10";
            break;
          case 15:
            item.nub = "01";
            break;
          case 16:
            item.nub = "09";
            break;
          case 17:
            item.nub = "12";
            break;
          case 18:
            item.nub = "12";
            break;
          case 19:
            item.nub = "13";
            break;
          case 20:
            item.nub = "11";
            break;
          case 21:
            item.nub = "15";
            break;
          case 22:
            item.nub = "11";
        }
        this.topFolder.push(item);
        this.stry1 = this.topFolder.length;
      });

      if (this.stry1 == this.stry) {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // setTimeout(() => {
      //   this.finished = false;
      //   this.loading = false;
      //   this.refreshing = false;
      // }, 1000);
    },
    srotTime(value) {
      if (value == 1) {
        this.nub1 = 1;
        this.srotShow = false;
        this.getDiskLists({
          id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
          pageIndex: 1,
          timeSort: "desc",
          pageSize: 30,
        });
      } else {
        this.nub1 = 2;
        this.srotShow = false;
        this.getDiskLists({
          id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
          pageIndex: 1,
          sizeSort: "desc",
          pageSize: 30,
        });
      }
    },
    // 清空回收站
    physicallyDelFile() {
      this.settingName = "清空";
      this.showNotice = true;
      this.setting = "清空回收站";
    },
    //返回上级移动目录
    backPrevFolder() {
      this.disMove.splice(this.disMove.length - 1, 1);
      let last = this.disMove.length - 1;
      this.moveFolder = this.disMove[last];
    },
    async afterRead(file) {
      let fileSizeLimit = 50 * 1024 * 1024;
      this.NewBuildshow = false;
      console.log(file);
      if (file.file.size > fileSizeLimit) {
        Toast({
          duration: 1000,
          message: "文件大小不能超过50M",
          forbidClick: true,
          className: "toastStyle",
        });
        return;
      }

      Toast.loading({
        duration: 8000,
        message: "上传中...",
        forbidClick: true,
        className: "toastStyle1",
      });
      const params = new FormData();
      if (file.length > 1) {
        file.forEach((item) => {
          params.append("upfile", item.file);
        });
      } else {
        params.append("upfile", file.file);
      }
      let str = {
        id: this.ID,
        columnType: 1,
      };
      // params.append("id", this.ID);
      // params.append("columnType", 1);

      let res = await mergeFile(params, str);
      if (res.code == 200) {
        Toast.clear();
        Toast.success({
          duration: 1000,
          message: "上传成功",
          forbidClick: true,
          className: "toastStyle1",
        });

        this.getDiskLists({
          id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
          pageIndex: 1,
          pageSize: 30,
        });
      }
    },
    //选中进入文件夹
    selectFolder(file) {
      if (!file.children.length) {
        this.moveFolder = {};
      }
      this.moveFolder = file;
      this.disMove.push(file);
    },
    //关闭移动
    hideMoveFile() {
      this.showMoving = false;
      this.disMove = [];
    },
    // 移动到
    moveToHere() {
      this.opres.forEach(async (item, index) => {
        if (item.id == this.moveFolder.id) {
          this.$toast({
            message: "不能移动到相同文件夹下",
            className: "toastStyle",
          });
          return;
        }
        const res = [];
        let atr = {
          id: item.id,
          moveid: this.moveFolder.id,
        };
        res.push(atr);
        let data = await moveFile(res);
        if (data.code == 200) {
          Toast({
            message: "移动成功",
            position: "bottom",
            className: "toastStyle",
          });
          this.opres = [];
          this.hideMoveFile();
          this.onCheckCancel();
          if (this.dataSelect.text) {
            let params = {
              text: this.dataSelect.text,
              pageSize: 30,
            };
            this.onSelect(params);
          } else {
            this.getDiskLists({
              id: this.arr[this.arr.length - 1].id,
              pageIndex: 1,
              pageSize: 30,
            });
          }
        } else {
          Toast({
            message: data.message,
            position: "bottom",
            className: "toastStyle",
          });
        }
      });
    },
    //全选文件/取消全选
    onAllChecks() {
      this.isAll = !this.isAll;
      this.opres = [];
      this.topFolder.forEach((item) => {
        this.$refs["netfile"].forEach((ref) => {
          if (this.isAll) {
            ref.isMulc = true;
            if (ref.file.id == item.id) {
              if (item.isDeleted != -1) {
                this.opres.push(item);
              }
            }
          } else {
            ref.isMulc = false;
            this.opres = [];
          }
        });
      });
    },
    //取消多选操作
    onCheckCancel() {
      this.isAll = false;
      this.showTopCheckBar = false;
      this.NewEducation = false;
      this.showTopCheckBar1 = true;
      this.$refs["netfile"].forEach((item) => {
        item.isMulc = false;
        item.checkMulc = false;
      });
      this.opres = [];
      this.recycleID = 0;
      this.recterd = 0;
      if (this.dataSelect.text) {
        let params = {
          text: this.dataSelect.text,
          pageSize: 30,
        };
        this.onSelect(params);
        this.headerTitle = this.dataSelect.text;
      } else {
        this.headerTitle =
          this.arr.length > 0
            ? this.arr[this.arr.length - 1].filename
            : "智能网盘";
        this.getDiskLists({
          id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
          pageIndex: 1,
          pageSize: 30,
        });
      }
    },
    //确定操作
    async sure() {
      this.showNotice = false;
      let params = {
        id: this.opres[0].id,
      };
      if (this.setting == "分享") {
        console.log(this.opres[0].url);
        if (window.android) {
          const jsonStr = {
            action: "share",
            data: {
              type: 2,
              ids: this.opres[0].id,
              filename: this.opres[0].filename,
              url: this.opres[0].url,
              src: this.opres[0].url,
            },
          };
          window.android.postMessage(JSON.stringify(jsonStr));
        } else if (window.webkit) {
          const jsonStr = {
            action: "share",
            data: {
              type: 2,
              ids: this.opres[0].id,
              filename: this.opres[0].filename,
              url: this.opres[0].url,
              src: this.opres[0].url,
            },
          };
          window.webkit.messageHandlers.sendMessageForVue.postMessage(
            JSON.stringify(jsonStr)
          );
        }
      } else if (this.setting == "批量下载") {
        // let jsonArr = [];
        // this.opres.forEach((i) => {
        //   jsonArr.push({
        //     id: i.id,
        //     filename: i.filename,
        //     url: i.url,
        //   });
        // });

        if (window.android) {
          console.log(222);
          const jsonStr = {
            action: "download",
            data: {
              id: this.opres[0].id,
              filename: this.opres[0].filename,
              url: this.opres[0].url,
              src: this.opres[0].url,
            },
          };
          window.android.postMessage(JSON.stringify(jsonStr));
        } else if (window.webkit) {
          const jsonStr = {
            action: "download",
            data: {
              id: this.opres[0].id,
              filename: this.opres[0].filename,
              url: this.opres[0].url,
              src: this.opres[0].url,
            },
          };
          window.webkit.messageHandlers.sendMessageForVue.postMessage(
            JSON.stringify(jsonStr)
          );
        }
        this.onCheckCancel();
      } else if (this.setting == "回收站删除") {
        // 回收站删除
        let arr = [];
        this.opres.forEach(async (item) => {
          // item.isMulc = !item.isMulc;

          let str = {
            id: item.id,
          };
          arr.push(str);
        });
        const res = await recycleBinDel(arr);
        if (res.code == 200) {
          Toast({
            message: "删除成功",
            position: "bottom",
            className: "toastStyle",
          });
          let arr = {
            text: "回收站",
            pageSize: 30,
          };
          this.opres = [];
          this.onSelect(arr);
        }
        this.$refs["netfile"].forEach((item) => {
          item.isMulc = false;
        });
      } else if (this.setting == "清空回收站") {
        const res = await recycleBinEmpty();
        if (res.code === 200) {
          Toast({
            message: "清空成功",
            position: "bottom",
            className: "toastStyle",
          });
          let arr = {
            text: "回收站",
            pageSize: 30,
          };
          this.opres = [];
          this.onSelect(arr);
        }
      } else if (this.setting == "批量删除") {
        this.isAll = false;
        let arr = [];
        this.opres.forEach(async (item) => {
          // item.isMulc = !item.isMulc;

          let str = {
            id: item.id,
          };
          arr.push(str);
        });
        const res = await delFiles(arr);
        if (res.code == 200) {
          Toast({
            message: "文件已删除",
            position: "bottom",
            className: "toastStyle",
          });
          this.opres = [];
          if (this.dataSelect.text) {
            let params = {
              text: this.dataSelect.text,
              pageSize: 30,
            };
            this.onSelect(params);
          } else {
            this.getDiskLists({
              id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
              pageIndex: 1,
              pageSize: 30,
            });
          }
        }
        this.$refs["netfile"].forEach((item) => {
          item.isMulc = false;
        });
      } else if (this.setting == "批量恢复") {
        this.isAll = false;
        let data = [];
        this.opres.forEach(async (item) => {
          let str = {
            id: item.id,
          };
          data.push(str);
        });
        const res = await recycleBinReduction(data);
        if (res.code == 200) {
          Toast({
            message: "恢复成功",
            position: "bottom",
            className: "toastStyle",
          });
          let arr = {
            text: "回收站",
            pageSize: 30,
          };
          this.opres = [];
          this.onSelect(arr);
        }
        this.$refs["netfile"].forEach((item) => {
          item.isMulc = false;
        });
      }
    },
    // 排序
    srotShows() {
      this.srotShow = true;
    },
    // 取消
    cancel() {
      this.showNotice = false;
    },
    downloadFileBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请选择文件",
          position: "bottom",
          className: "toastStyle",
        });
        return;
      }

      for (var i = 0; i < this.opres.length; i++) {
        if (this.opres[i].filetype == 0) {
          this.$toast({
            message: "文件夹不能下载",
            position: "bottom",
            className: "toastStyle",
          });
          return;
        }
      }
      this.settingName = "下载";
      this.showNotice = true;
      this.setting = "批量下载";
    },
    //   删除文件
    delFileBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请选择文件",
          position: "bottom",
          className: "toastStyle",
        });
        return;
      }
      if (this.recycleID != 10) {
        this.settingName = "删除";
        this.showNotice = true;
        this.setting = "批量删除";
      } else {
        this.settingName = "删除";
        this.showNotice = true;
        this.setting = "回收站删除";
      }
    },
    newJian() {
      this.title = "新建文件夹";
      this.newBuildValue = "";
      this.NewBuildshow = false;
      this.NewBuildshow1 = true;
      this.$nextTick(() => {
        this.$refs.plateInput.focus();
      });
    },
    //提交新建文件夹
    async newBuildRight() {
      if (this.title == "新建文件夹") {
        this.addFilesSure();
      } else {
        this.hangleChangeName();
      }
    },
    // 修改名称确定
    async hangleChangeName() {
      const data = {
        id: this.fileItemname.id,
        name: this.newBuildValue,
      };
      const res = await changeFilesName(data);
      if (res.code === 200) {
        this.showTopCheckBar = false;
        this.showTopCheckBar1 = true;
        this.NewBuildshow1 = false;
        this.NewEducation = false;
        Toast.success({
          message: "修改成功",
          className: "toastStyle1",
        });
        this.opres = [];
        if (this.dataSelect.text) {
          let params = {
            text: this.dataSelect.text,
            pageSize: 30,
          };
          this.onSelect(params);
        } else {
          this.getDiskLists({
            id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
            pageIndex: 1,
            pageSize: 30,
          });
        }

        this.$refs["netfile"].forEach((item) => {
          item.isMulc = false;
        });
      } else {
        Toast({
          message: res.message,
          position: "bottom",
          className: "toastStyle",
        });
      }
    },
    // 新增文件夹确定
    async addFilesSure() {
      if (this.newBuildValue == "") {
        Toast({
          message: "请输入文件名",
          position: "bottom",
          className: "toastStyle",
        });
        return;
      }
      const data = {
        filetype: 0,
        parentid: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
        filename: this.newBuildValue,
      };
      const res = await addFiles(data);
      if (res.code === 200) {
        Toast.success({
          message: "新增成功",
          className: "toastStyle1",
        });
        this.NewBuildshow1 = false;

        this.getDiskLists({
          id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
          pageIndex: 1,
          pageSize: 30,
        });
      } else {
        this.NewBuildshow1 = false;
        Toast.fail({
          message: res.message,
          className: "toastStyle1",
        });
      }
    },
    //新建文件夹
    onNewBuild() {
      this.NewBuildshow = true;
    },
    //取消新建文件夹
    newBuildLeft() {
      this.NewBuildshow1 = false;
    },
    //返回上一级
    onTopFileBack() {
      this.topFolder = [];
      this.ID = 0;
      this.recycleID = 0;
      if (this.arr.length > 1) {
        this.arr.pop();
        this.headerTitle = this.arr[this.arr.length - 1].filename;
        this.getDiskLists({
          id: this.arr[this.arr.length - 1].id,
          pageIndex: 1,
          pageSize: 30,
        });
        return true;
      } else {
        this.arr = [];
        this.parentId = 0;
        this.headerTitle = "智能网盘";
        this.getDiskLists({
          id: this.parentId,
          pageIndex: 1,
          pageSize: 30,
        });
        this.$store.commit("ChangeSideBarId", 0);
        return false;
      }
    },
    onArrow() {
      this.dataSelect = "";
      this.finished = false;
      this.showTopCheck = true;
      this.idx = 1;
      this.idx1 = 1;
      this.onTopFileBack();
    },
    async onSelect(action) {
      this.finished = false;
      this.dataSelect = action;
      this.idx = 1;
      this.idx1 = 1;
      this.textCentent = action.text;
      if (action) {
        this.showTopCheck = false;
      } else {
        this.showTopCheck = true;
      }
      this.headerTitle = action.text;
      if (action.text == "回收站") {
        this.recycleID = 10;
      } else if (action.text == "最近") {
        this.recterd = 1;
      } else {
        this.recterd = 0;
      }
      let params = {
        name: action.text,
        pageSize: 30,
        pageIndex: 1,
      };
      let data = await sideBarType(params);
      this.topFolder = [];
      data.data.records.forEach((item) => {
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = "01";
            } else {
              item.nub = "03";
            }
            break;
          case 1:
            item.nub = "04";
            break;
          case 2:
            item.nub = "07";
            break;
          case 3:
            item.nub = "08";
            break;
          case 4:
            item.nub = "05";
            break;
          case 5:
            item.nub = "06";
            break;
          case 6:
            item.nub = "07";
            break;
          case 7:
            item.nub = "04";
            break;
          case 8:
            item.nub = "05";
            break;
          case 9:
            item.nub = "14";
            break;
          case 10:
            item.nub = "14";
            break;
          case 11:
            item.nub = "09";
            break;
          case 12:
            item.nub = "09";
            break;
          case 13:
            item.nub = "09";
            break;
          case 14:
            item.nub = "10";
            break;
          case 15:
            item.nub = "01";
            break;
          case 16:
            item.nub = "09";
            break;
          case 17:
            item.nub = "12";
            break;
          case 18:
            item.nub = "12";
            break;
          case 19:
            item.nub = "13";
            break;
          case 20:
            item.nub = "11";
            break;
          case 21:
            item.nub = "15";
            break;
          case 22:
            item.nub = "11";
        }
      });
      setTimeout(() => {
        this.finished = false;
        this.loading = false;
        this.refreshing = false;
        this.whetherChildren = true;
      }, 1000);
      this.topFolder = data.data.records;
      this.stry1 = this.topFolder.length;
      this.stry = data.data.total;
    },
    //   获取列表数据
    async getDiskLists(value) {
      this.textCentent = 1;
      this.whetherChildren = false;
      let data = await getDiskList(value);
      this.nub1 = 0;
      data.data.records.forEach((item) => {
        switch (item.filetype) {
          case 0:
            if (item.isDeleted == -1) {
              item.nub = "01";
            } else {
              item.nub = "03";
            }
            break;
          case 1:
            item.nub = "04";
            break;
          case 2:
            item.nub = "07";
            break;
          case 3:
            item.nub = "08";
            break;
          case 4:
            item.nub = "05";
            break;
          case 5:
            item.nub = "06";
            break;
          case 6:
            item.nub = "07";
            break;
          case 7:
            item.nub = "04";
            break;
          case 8:
            item.nub = "05";
            break;
          case 9:
            item.nub = "14";
            break;
          case 10:
            item.nub = "14";
            break;
          case 11:
            item.nub = "09";
            break;
          case 12:
            item.nub = "09";
            break;
          case 13:
            item.nub = "09";
            break;
          case 14:
            item.nub = "10";
            break;
          case 15:
            item.nub = "01";
            break;
          case 16:
            item.nub = "09";
            break;
          case 17:
            item.nub = "12";
            break;
          case 18:
            item.nub = "12";
            break;
          case 19:
            item.nub = "13";
            break;
          case 20:
            item.nub = "11";
            break;
          case 21:
            item.nub = "15";
            break;
          case 22:
            item.nub = "11";
        }
      });
      setTimeout(() => {
        this.finished = false;
        this.loading = false;
        this.refreshing = false;
        this.whetherChildren = true;
      }, 1000);
      this.topFolder = data.data.records;
      this.stry1 = this.topFolder.length;
      this.stry = data.data.total;
    },

    //下拉刷新
    onRefresh() {
      console.log(this.dataSelect);
      this.idx = 1;
      this.idx1 = 1;
      this.finished = false;
      this.pageNum = 1;
      this.torefresh = false;
      this.isRefresh = true;
      // this.opres = [];
      this.disTop = [];
      this.allparentDom = [];
      if (this.dataSelect.text) {
        let params = {
          text: this.dataSelect.text,
          pageSize: 30,
        };
        this.onSelect(params);
        this.headerTitle = this.dataSelect.text;
      } else {
        this.getDiskLists({
          id: this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0",
          pageIndex: 1,
          pageSize: 30,
        });
        this.headerTitle =
          this.arr.length > 0
            ? this.arr[this.arr.length - 1].filename
            : "智能网盘";
      }
    },
    // 回收站
    onDelete() {
      let arr = {
        text: "回收站",
        pageSize: 30,
      };
      this.onSelect(arr);
    },

    //搜索文件
    handleSearch(val) {
      if (val) {
        this.$router.push({
          path: `/netfilesearch/${val}`,
        });
      } else {
        Toast({
          message: "请输入内容",
          className: "toastStyle",
        });
      }
    },
    //打开文件夹或文件夹
    openFolder(file) {
      console.log(111);
      this.finished = false;
      this.idx = 1;
      this.idx1 = 1;
      let flie = this.arr;
      if (flie.length > 1) {
        this.$store.commit("ChangeSideBarId", flie);
      } else {
        this.$store.commit("ChangeSideBarId", 0);
      }

      this.parentId = file.id;
      this.ID = file.id;
      if (file.filename.split(".").length > 1) {
        const data = {
          id: file.id,
        };
        previewFile(data).then((res) => {
          if (res.code == 200) {
            console.log(res);
            // 判单当前的登录设备
            this.$router.push({
              name: "redirect",
              query: {
                data: res.data,
              },
            });
            // this.$store.commit(
            //   "ChangeSideBarId",
            //   this.arr.length > 0 ? this.arr[this.arr.length - 1].id : "0"
            // );
            // var u = navigator.userAgent;

            // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            // if (isAndroid) {
            //   window.open(res.data);
            // } else if (isiOS) {
            //   // var winRef = window.open(" ", "_blank");
            //   // winRef.location = res.data;
            //   window.location.href = res.data;
            // } else {
            //   window.open(res.data);
            // }
          }
        });
      } else {
        let str = {
          id: file.id,
          filename: file.filename,
        };
        this.arr.push(str);
        this.headerTitle = file.filename;
        this.getDiskLists({
          id: file.id,
          pageIndex: 1,
          pageSize: 30,
        });
      }
    },
    //打开文件操作面板
    opFile(file) {},
    //多选操作
    mulchoice(file) {
      this.fileItemname = file;
      if (file.parentId == 0) {
        this.opres.push(file);
      } else {
        this.opres.forEach((sel, inx) => {
          if (sel.id == file.id) {
            this.opres.splice(inx, 1);
          }
        });
      }
      if (this.opres.length >= 2) {
        this.viewingShow = false;
      } else {
        this.viewingShow = true;
      }
      this.showTopCheckBar = true;
      this.NewEducation = true;
      this.showTopCheckBar1 = false;
    },

    //展示可移动文件
    async moveFile() {
      this.showMoving = true;
      const data = {
        id: 0,
      };
      const res = await directory(data);
      if (res.code == 200) {
        this.moveFolder = res.data[0];
        this.disMove.push(this.moveFolder);
      }
    },
    //重命名
    renameFileBeach() {
      this.title = "重命名";
      this.newBuildValue = this.fileItemname.filename;

      this.NewBuildshow1 = true;
      // this.$nextTick(() => {
      //   this.$refs.plateInput.focus();
      // });
    },
    //批量恢复
    recoveryBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请先选择文件",
          position: "bottom",
          className: "toastStyle",
        });
        return;
      }
      this.settingName = "恢复";
      this.showNotice = true;
      this.setting = "批量恢复";
    },
    // 分享
    shareFileBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请选择文件",
          position: "bottom",
          className: "toastStyle",
        });
        return;
      }
      this.settingName = "分享";
      this.showNotice = true;
      this.setting = "分享";
    },
    //批量收藏
    collectFileBeach() {
      // for(var i = 0;i<this.opres.length;i++){
      //   if(this.opres[i].filetype == -1 ){
      //      this.$toast("文件夹不可以收藏！")
      //      return
      //   }
      // }
      // if (!this.opres.length) {
      //   this.$toast({
      //     message: "请先选择文件",
      //     position: "bottom",
      //   });
      //   return;
      // }
      // let params = [];
      // this.opres.forEach((i) => {
      //   params.push({
      //     id: i.id,
      //     status: 1,
      //     type: i.type,
      //   });
      // });
      // batchCollect(params).then((res) => {
      //   if (res.code == 0) {
      //     this.$toast("收藏成功");
      //     this.onCheckCancel();
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.network {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: PingFang SC;
  .van-bar {
    height: 100px;
    line-height: 100px;
    .class-img {
      width: 64px;
      height: 64px;
    }
  }

  .van-list {
    overflow-y: auto;
    height: calc(100vh - 300px);
    .box4 {
      margin-top: 20px;
      width: 100%;
      height: 50px;
      font-size: 26px;
      text-align: center;
      color: #c5c5c5;
      line-height: 50px;
    }
  }
  /*滚动条bai整体*/
  ::-webkit-scrollbar {
    width: 2px; /*滚动条宽度*/
  }
  ::-webkit-scrollbar-track {
    background-color: #fff; /*滑道全部*/
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #fff; /*滑道*/
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fafafa; /*滑动条表面*/
    border: none; /*滑动条边框*/
  }
  .nav-wrap {
    height: 80px;
    .topfix {
      position: absolute;
      width: 100%;
      top: 60;
      z-index: 100;
      background-color: #fff;
    }
    ::v-deep .leftimg {
      margin-right: 30px;
    }
  }
  .network-panel {
    .torefresh,
    .unfilehere {
      height: calc(100vh - 200px);
      text-align: center;
      font-size: 28px;
      font-weight: 400;
      line-height: 600px;
      color: #999999;
    }
  }
  ::v-deep .van-nav-bar__title {
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .van-nav-bar__content {
    height: 100%;
  }
  ::v-deep .van-popover__wrapper {
    height: 64px !important;
  }
  ::v-deep .van-tabs__line {
    width: 17px;
    height: 5px;
    background-color: #3783f3;
  }
  ::v-deep .van-tab {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
  }
  ::v-deep .van-tab.van-tab--active {
    color: #333;
  }
  ::v-deep .van-nav-bar {
    line-height: 8.933333vw;
    height: 100px;
  }
  ::v-deep .van-nav-bar.van-hairline--bottom {
    height: 80px;
    line-height: 80px;
  }
  ::v-deep .van-nav-bar__left {
    .van-icon {
      color: #333333;
      margin-left: 70px;
      font-size: 30px;
    }
  }

  ::v-deep .van-nav-bar__text {
    color: #333333;
    margin-right: 14px;
  }
  ::v-deep .van-popover__action-icon {
    font-size: 32px;
  }

  .newFile {
    position: fixed;
    right: 40px;
    bottom: 200px;
    z-index: 200;
    img {
      width: 108px;
      height: 108px;
      margin-bottom: 20px;
    }
  }
  .shareFile {
    ::v-deep .van-icon {
      color: #333333;
    }
    ::v-deep .van-nav-bar__text {
      color: #333333;
    }
    ::v-deep .van-nav-bar__right {
      font-size: 28px !important;

      .van-nav-bar__text {
        color: #1989fa !important;
        margin-right: 10px;
      }
    }

    .shangchuan {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 42px 30px 40px 32px;
      box-sizing: border-box;
      .box1 {
        text-align: center;
        display: inline-block;
        width: 140px;
        height: 120px;
        font-size: 28px;
      }
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
      }
    }
  }
  .paixu {
    height: 200px;
  }
  .newBuildPopup {
    ::v-deep .van-icon {
      color: #333333;
    }
    ::v-deep .van-nav-bar__text {
      color: #333333;
    }
    ::v-deep .van-nav-bar__right {
      font-size: 28px !important;

      .van-nav-bar__text {
        color: #1989fa !important;
        margin-right: 10px;
      }
    }
    ::v-deep .van-nav-bar__left {
      font-size: 28px !important;
    }
    .newBuild-content {
      text-align: center;
      margin-top: 122px;
      .form {
        text-align: left;
        margin: 100px 60px 0;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        border-bottom: 1px solid rgba(51, 51, 51, 0.2);
        padding-bottom: 40px;
        input {
          width: 61%;
          color: #999999;
          padding-left: 40px;
        }
      }
    }
    .shangchuan {
      .box {
        text-align: center;
        display: inline-block;
        margin-top: 40px;
        margin-left: 162px;
        width: 140px;
        height: 120px;
        font-size: 28px;
      }
      .box1 {
        text-align: center;
        display: inline-block;
        float: right;
        margin-top: 40px;
        margin-right: 162px;
        width: 140px;
        height: 120px;
        font-size: 28px;
      }
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
      }
    }
  }
  .mul-op {
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 91px;
    padding: 20px 0;
    background: #f4f8ff;
    border-radius: 20px 20px 4px 4px;
    &-item {
      text-align: center;
      img {
        width: 38px;
        height: 38px;
      }
      p {
        margin-top: 16px;
        font-size: 24px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
}
//弹框
.over {
  width: 570px;
  height: 258px;
  border-radius: 20px;

  .content {
    height: 190px;
    border-bottom: 2px solid #eee;
    .tit {
      height: 60px;
      padding-left: 40px;
      background-color: #eee;
      font-size: 30px;
      font-weight: 500;
      line-height: 60px;
      color: #999;
    }
    .main {
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        width: 440px;
        height: 60px;
        padding-left: 10px;
        color: #666;
        border: 2px solid #aaa;
        border-radius: 10px;
      }
    }
    p {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #999;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    height: 68px;

    span {
      font-size: 30px;
      font-weight: 500;
      color: #999;
      flex: 1;
      text-align: center;
    }

    .sure {
      color: #4588ff;
    }
  }
}
.over-del {
  height: 370px;
  .content {
    padding-top: 60px;
    box-sizing: border-box;
    border: none;
    .tit {
      padding-left: 0;
      background-color: #fff;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
    .main {
      height: 60px;
      margin-top: 20px;
    }
  }
  .btns {
    height: 140px;
    margin-top: 40px;
  }
  .filechecktop-active {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.move {
  padding-bottom: 34px;
  .top {
    position: relative;
    padding: 30px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #333333;
    .curfile {
      position: absolute;
      left: 30px;
      width: 200px;
      text-align: left;
      font-size: 26px;
      font-weight: 400;
      color: #333;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      i {
        vertical-align: middle;
        margin-bottom: 6px;
      }
    }
    .move-close {
      position: absolute;
      right: 30px;
      margin-top: 10px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .filelist {
    height: 480px;
    padding-bottom: 40px;
    overflow-y: scroll;
    .netfile-selected {
      background-color: rgba(198, 198, 201, 0.2);
    }
    .netfile {
      padding: 20px 30px;
      .file-item {
        display: flex;
        flex-wrap: nowrap;
        // margin-top: 15px;
        &-image {
          img {
            width: 105px;
            height: 105px;
          }
        }
        &-main {
          position: relative;
          flex: 1;
          margin-left: 30px;
          padding-top: 7px;
          .tit {
            width: 460px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .detail {
            margin-top: 16px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            &-count {
              margin-right: 30px;
            }
          }
        }
      }
    }
  }
  .btn-move {
    display: flex;
    flex-direction: row-reverse;
    .btns {
      display: inline-block;
      width: 193px;
      margin-right: 40px;
      text-align: center;
      line-height: 76px;
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      background-color: #3683f2;
      border-radius: 38px;
    }
  }
}
</style>
<style lang="scss">
.van-pull-refresh__head {
  font-size: 26px;
  .van-loading--circular {
    .van-loading__text {
      font-size: 26px;
    }
  }
}
.van-pull-refresh {
  .van-pull-refresh__track {
    .van-list {
      .van-list__finished-text {
        margin-top: 20px;
        font-size: 26px;
      }
    }
  }
}
.van-list__loading {
  margin-top: 20px;
  .van-loading {
    .van-loading__text {
      font-size: 26px;
    }
  }
}

.van-popover--light {
  width: 300px;
  height: 800px;
  .van-popover__content {
    width: 100%;
    height: 100%;
    // padding-left: 41px;
    // box-sizing: border-box;

    .van-popover__action {
      margin: 50px 0;
      width: 100%;
      font-size: 28px;
      margin-left: 41px;

      .van-popover__action-text {
        margin-left: 21px;
        justify-content: left !important;
        -webkit-flex: none;
        flex: none;
        height: 0;
      }

      .van-popover__action-icon {
        font-size: 30px !important;
      }
    }
  }
}
//   .van-popover__content
//   .van-popover__action
//   .van-popover__action-icon {
//   font-size: 30px !important;
// }
.sapnSort {
  font-size: 26px;
  color: #999999;
  margin: 42px 0px 20px 30px;
  .van-icon-arrow-down {
    color: #999999 !important;
  }
}
.sapnSort1 {
  font-size: 26px;
  color: #999999;
  // margin-top: 20px;
  // margin-bottom: 20px;
  .van-icon-arrow-down {
    color: #999999 !important;
  }
  .sapnReight {
    float: right;
    margin-right: 32px;
    font-size: 28px;
    color: #333333;
  }
}
.srotTime {
  font-size: 30px;
}
.srotTime div {
  color: #333;
  margin: 44px 0px 44px 80px;
}
.srotTime div:nth-child(2) {
  color: #333;
  margin: 44px 0px 44px 80px;
}
.srotTimeShow {
  color: #4587ff !important;
}
#regihtText {
  .van-nav-bar__content {
    height: 100px;
    .van-nav-bar__right {
      .van-nav-bar__text {
        font-size: 28px !important;
      }
    }
  }
}
</style>
<style>
/* 轻提示样式 */
.toastStyle {
  font-size: 28px;
  /* width: 192px; */
  height: 72px;
}
.toastStyle1 {
  font-size: 28px;
  width: 192px;
  height: 182px;
}
::v-deep .van-pull-refresh__head {
  font-size: 30px;
}
</style>
<style>
/* 消息提示 */
.notifyStyle {
  width: 100% !important;
  font-size: 28px;
  width: 192px;
  height: 72px;
}
</style>
